
import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";

// @mui material components
//import Grid from "@mui/material/Grid";
// import { Collapse, Grid, Icon, Tooltip } from "@mui/material";
import { Box, Card, Tab, Tabs, TextField, Typography, Collapse, Grid, IconButton, Menu, MenuItem } from "@mui/material";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import InfoIcon from '@mui/icons-material/Info';
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDButton from "components/MDButton";
import { rotafechada } from 'commons/urlconf';
import api from 'commons/api';
import { formatDate } from "commons/utils";
import { ptBR } from "date-fns/locale";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import MessagesContext from "hooks/MessagesContext";
import AuthContext from 'hooks/AuthContext';
import { endOfYear, startOfYear, startOfMonth, endOfMonth } from 'date-fns'
import CancelIcon from '@mui/icons-material/Cancel';
import SettingsIcon from '@mui/icons-material/Settings';
import TabelaPadrao from "componentes/TabelaPadrao";
import { formataValor } from "commons/utils";
import DialogoValidaModulo from "componentes/ValidaModulo";
import { useNavigate } from "react-router-dom";
import DialogoDetalheCategoria from "./DetalheCategoria";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';


const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 'none',
        //backgroundColor: '#1A73E8',
        //color: 'rgb(255, 255, 255)',
        //boxShadow: theme.shadows[1],
        //fontSize: 12,
    },
}));

function DFCRealizado(props) {

    const { open } = props;

      const navigate = useNavigate();

    const messageContext = useContext(MessagesContext);
    const { MensagemErro, MostraTelaEspera, FechaTelaEspera } = messageContext;

    const authContext = useContext(AuthContext);
    const [detalheCategoriaOpen, setDetalheCategoriaOpen] = useState({ visible: false, title: '', data: '', id: 0 });


    const [parametrosDRE, setParametrosDRE] = useState({});

    //Tabela
    const [countAll, setCountAll] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('id');
    const [colunasTable, setColunasTable] = React.useState([]);
    const [linhas, setLinhas] = React.useState([]);

    // *****States relacionado do filtro de pesquisa ****
    const [dataInicial, setDataInicial] = useState(startOfYear(new Date()));
    const [dataFinal, setDataFinal] = useState(endOfYear(new Date()));
    // ****************************

    /*****************Menu Item*******************/
    const [anchorEl, setAnchorEl] = useState(null);
    const [dadosMenu, setDadosMenu] = useState({ openMenu: false });



    const handleClickDetalheCategoria = (id, data) => {
        if (id !== 0) {
            setDetalheCategoriaOpen({ ...detalheCategoriaOpen, visible: true, title: 'Lançamentos no Período', data: data, id: id });
        }

    }

    const insereTotalReceita = (data, dadostemp) => {
        //acrescentando as linhas de totais
        let linhatemp = {};
        linhatemp.colunanome =
            <>

                <MDTypography
                    style={{ paddingLeft: "10px" }}
                    variant="subtitle"
                    fontWeight="bold"

                >
                    TOTAL EM RECEITA
                </MDTypography>
            </>

        //montando o objeto para a linha, cada propriedade do objeto é o index do vetor de meses
        for (let j = 0; j < data.totalReceita.length; j++) {
            linhatemp[j] =
                <>
                    <MDTypography variant="subtitle" fontWeight={'bold'}>{formataValor(data.totalReceita[j]).replace('R$', '')}</MDTypography>
                </>
        }


        //guardo os dados sem formatacao de html para usar depois em exportacao e calculos
        linhatemp.id = ''
        linhatemp.tipo = 'TR';//total
        linhatemp.categoria_id = '';
        linhatemp.valores = [...data.totalReceita];
        linhatemp.nome = '';

        dadostemp.push(linhatemp);
    }

    const insereTotalDespesa = (data, dadostemp) => {
        //*    Linha total de DESPESA */
        let linhatemp = {};
        linhatemp.colunanome =
            <>

                <MDTypography
                    style={{ paddingLeft: "10px" }}
                    variant="subtitle"
                    fontWeight="bold"

                >
                    TOTAL EM DESPESA
                </MDTypography>
            </>

        //montando o objeto para a linha, cada propriedade do objeto é o index do vetor de meses
        for (let j = 0; j < data.totalDespesa.length; j++) {
            linhatemp[j] =
                <>
                    <MDTypography variant="subtitle" fontWeight={'bold'}>{formataValor(data.totalDespesa[j]).replace('R$', '')}</MDTypography>
                </>
        }


        //guardo os dados sem formatacao de html para usar depois em exportacao e calculos
        linhatemp.id = ''
        linhatemp.tipo = 'TD';//total
        linhatemp.categoria_id = '';
        linhatemp.valores = [...data.totalDespesa];
        linhatemp.nome = '';

        dadostemp.push(linhatemp);
    }

    const insereSaldoInicial = (data, dadostemp) => {
        //*  Linha Saldo Inicial */
        let linhatemp = {};
        linhatemp.colunanome =
            <>

                <MDTypography
                    style={{ paddingLeft: "10px" }}
                    variant="subtitle"
                    fontWeight="bold"

                >
                    SALDO INICIAL
                </MDTypography>
            </>


        //Primeiro mes saldo que veio do backend
        let saldoInicial = parseFloat(data.saldoInicial);
        linhatemp[0] =
            <>
                <MDTypography variant="subtitle" fontWeight={'bold'}>{formataValor(saldoInicial).replace('R$', '')}</MDTypography>
            </>

        linhatemp.valores = [saldoInicial];

        //comeca no segundo mes porque o primeiro mes é o saldo inicial vindo do backend
        for (let j = 1; j < data.totalDespesa.length; j++) {

            saldoInicial = saldoInicial + parseFloat(data.totalReceita[j - 1]) + parseFloat(data.totalDespesa[j - 1]);
            linhatemp[j] =
                <>
                    <MDTypography variant="subtitle" fontWeight={'bold'}>{formataValor(saldoInicial).replace('R$', '')}</MDTypography>
                </>
             linhatemp.valores .push(saldoInicial);
        }


        //guardo os dados sem formatacao de html para usar depois em exportacao e calculos
        linhatemp.id = ''
        linhatemp.tipo = 'SI';//Saldo inicial
        linhatemp.categoria_id = '';
        linhatemp.nome = '';

        //insere no inicio do vetor
        dadostemp.unshift(linhatemp);
    }

    const insereSaldoFinal = (data, dadostemp) => {
        //*  Linha Saldo Final */
        let linhatemp = {};
        linhatemp.colunanome =
            <>

                <MDTypography
                    style={{ paddingLeft: "10px" }}
                    variant="subtitle"
                    fontWeight="bold"

                >
                    SALDO FINAL
                </MDTypography>
            </>


        //Primeiro mes saldo que veio do backend
        let saldoFinal = parseFloat(data.saldoInicial);
        linhatemp.valores = [];
        for (let j = 0; j < data.totalDespesa.length; j++) {

            saldoFinal = saldoFinal + parseFloat(data.totalReceita[j]) + parseFloat(data.totalDespesa[j]);
            linhatemp[j] =
                <>
                    <MDTypography variant="subtitle" fontWeight={'bold'}>{formataValor(saldoFinal).replace('R$', '')}</MDTypography>
                </>
             linhatemp.valores.push(saldoFinal);
        }


        //guardo os dados sem formatacao de html para usar depois em exportacao e calculos
        linhatemp.id = ''
        linhatemp.tipo = 'SF';//Saldo final
        linhatemp.categoria_id = '';
        linhatemp.nome = '';

        dadostemp.push(linhatemp);
    }

    const buscaDadosDFCRealizado = async () => {
        try {

            let complementoUrl = '';
            let dataTimeZone = new Date()
            const offSet = dataTimeZone.getTimezoneOffset()

            let dataInicialTemp = startOfMonth(dataInicial)
            let dataFinalTemp = endOfMonth(dataFinal)

            MostraTelaEspera(`Buscando dados`);

            const url = `${rotafechada()}imprimir/DFC?data1=${formatDate(dataInicialTemp, 'YYYY-MM-DD 00:00:00')}&data2=${formatDate(dataFinalTemp, 'YYYY-MM-DD 23:59:59')}&timeoffset=${offSet}`;
            const retorno = await api.post(encodeURI(url), parametrosDRE);
            const { data } = retorno.data;
            let dadostemp = [];
            let colunasTabletemp = [];

            //Montando o cabecalho

            colunasTabletemp.push({
                id: 'colunanome',
                numeric: false,
                disablePadding: true,
                label: `Categoria`,
                disableOrder: true,
                fixed: true // deixa coluna fixada
            });

            for (let i = 0; i < data.meses.length; i++) {

                colunasTabletemp.push({
                    id: i,
                    numeric: true,
                    disablePadding: false,
                    label: data.meses[i],
                    disableOrder: true,
                })
            }

            // colunasTabletemp.push({
            //     id: 'id',
            //     numeric: false,
            //     disablePadding: true,
            //     label: `ID`,
            //     disableOrder: true,
            //     fixed: true // deixa coluna fixada
            // });

            // colunasTabletemp.push({
            //     id: 'categoria_id',
            //     numeric: false,
            //     disablePadding: true,
            //     label: `Categoria Pai`,
            //     disableOrder: true,
            //     fixed: true // deixa coluna fixada
            // });

            /*mapeando os dados para a "rows"*/
            let linhatemp = {};
            for (let i = 0; i < data.categorias.length; i++) {

                linhatemp = {};

                linhatemp.colunanome =
                    <>

                        <MDTypography
                            style={!data.categorias[i].categoria_id ? { paddingLeft: "10px" } : data.categorias[i].tipo === 'R' ? { borderLeft: "3px solid green", paddingLeft: "10px" } : { borderLeft: "3px solid red", paddingLeft: "10px" }}
                            variant="subtitle"
                            fontWeight={!data.categorias[i].categoria_id ? "bold" : ''}//se for categoria_pai

                        >
                            {!data.categorias[i].categoria_id ? data.categorias[i].nome : <>    {data.categorias[i].nome}</>}
                        </MDTypography>
                    </>

                //montando o objeto para a linha, cada propriedade do objeto é o index do vetor de meses
                for (let j = 0; j < data.categorias[i].valores.length; j++) {
                    linhatemp[j] =
                        <>
                            {!data.categorias[i].categoria_id ?
                                <MDTypography variant="subtitle" fontWeight={'bold'}>{formataValor(data.categorias[i].valores[j]).replace('R$', '')}</MDTypography>
                                :

                                <MDTypography
                                    onClick={() => { handleClickDetalheCategoria(data.categorias[i].id, '01/' + data.meses[j]) }}
                                    color={'info'} sx={{ cursor: "pointer" }}
                                    variant='subtitle' >
                                    {formataValor(data.categorias[i].valores[j]).replace('R$', '')}
                                </MDTypography>

                            }
                        </>
                }


                //guardo os dados sem formatacao de html para usar depois em exportacao e calculos
                linhatemp.id = data.categorias[i].id;
                linhatemp.tipo = data.categorias[i].tipo;
                linhatemp.categoria_id = data.categorias[i].categoria_id;
                linhatemp.valores = [...data.categorias[i].valores];
                linhatemp.nome = data.categorias[i].nome;

                dadostemp.push(linhatemp);
            }

            insereTotalReceita(data, dadostemp);
            insereTotalDespesa(data, dadostemp);
            insereSaldoInicial(data, dadostemp)
            insereSaldoFinal(data, dadostemp);


            setColunasTable(colunasTabletemp);
            setLinhas(dadostemp);
            setCountAll(0);
            FechaTelaEspera();
        }
        catch (error) {
            FechaTelaEspera();
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    }

    const handleClickPesquisar = () => {
        buscaDadosDFCRealizado();
    }

    const handleClickExcluirLinhasZeradas = () => {

        const vetorFiltrado = linhas.filter(item => !item.valores.every(valor => parseFloat(valor) === 0));
        setLinhas(vetorFiltrado)

    }

    const desenhaFiltroRealizado = () => {
        return (


            < Box sx={{ margin: 1 }}>
                < Grid container spacing={2} justifyContent="flex-start" >
                    <Grid item xs={12} sm={3} >{/*Filtro Data Inicial*/}
                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                            <DesktopDatePicker
                                label='Mês Inicial'
                                inputFormat="MM/yyyy"
                                value={dataInicial}
                                onChange={(e) => setDataInicial(e)}
                                renderInput={(params) => <TextField {...params} required
                                    helperText={dataInicial > 0 ? false :
                                        <>
                                            <CancelIcon color={'error'} />
                                            <Typography pl={0.5} fontWeight="small" variant="caption" color={'error'}>Informe uma data válida</Typography>
                                        </>
                                    }
                                />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={3}   >{/*Filtro Data Final*/}
                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                            <DesktopDatePicker
                                label='Mês Final'
                                inputFormat="MM/yyyy"
                                value={dataFinal}
                                onChange={(e) => setDataFinal(e)}
                                renderInput={(params) => <TextField {...params} required
                                    helperText={dataFinal > 0 ? false :
                                        <>
                                            <CancelIcon color={'error'} />
                                            <Typography pl={0.5} fontWeight="small" variant="caption" color={'error'}>Informe uma data válida</Typography>
                                        </>
                                    }
                                />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={3} >{/*Botão do Filtro*/}
                        <MDButton
                            disabled={!(dataInicial > 0) || !(dataFinal > 0) ? true : false}
                            onClick={handleClickPesquisar}
                            color='info'
                            variant="gradient"
                        >
                            Atualizar demonstrativo
                        </MDButton>

                        <CustomWidthTooltip
                            title={<>
                                <p align='left'>
                                    O saldo inicial é dado a partir de "Bancos" e "Caixas", não considerando "Cartão de Crédito"<br />
                                    Portanto caso algum pagamento ou recebimento tenha sido efetuado direto no "Cartão de Crédito" <br />
                                    O lançamento será mostrado aqui no DFC e o saldo final de cada mês poderá divergir de relatórios <br />
                                    de saldos bancos e caixas
                                </p>
                            </>}
                            placement="top"
                            disableInteractive
                        >
                            <InfoIcon
                                color='info'
                            />

                        </CustomWidthTooltip>

                    </Grid>
                    <Grid item xs={12} sm={2} >{/*Botão do Opções*/}
                        <MDButton
                            variant="outlined"
                            color="info"
                            size="small"
                            borderRadius="sm"
                            onClick={(e) => {
                                setDadosMenu({ ...dadosMenu, openMenu: true });
                                setAnchorEl(e.currentTarget);
                            }}
                            sx={{ borderRadius: '4px', height: '25px', minHeight: '12px' }}
                            disableElevation
                            endIcon={<KeyboardArrowDownIcon />}
                        >
                            ações
                        </MDButton>
                    </Grid>

                </Grid>
            </Box >

        )
    }

    useEffect(() => {
        if (open) {
            buscaDadosDFCRealizado();
        }

    }, [ open])

    return (
        <>
            <Menu

                anchorEl={anchorEl}
                open={dadosMenu.openMenu || false}
                onClose={() => {
                    setAnchorEl(null);
                    setDadosMenu({ ...dadosMenu, openMenu: false })
                }}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >

                <MenuItem
                    dense={true}
                    onClick={() => {
                        setAnchorEl(null);
                        setDadosMenu({ ...dadosMenu, openMenu: false })
                        handleClickExcluirLinhasZeradas();
                    }}>
                    Excluir linhas sem Valor
                </MenuItem>

            </Menu>

            {detalheCategoriaOpen.visible === true &&
                <DialogoDetalheCategoria // Ver detalhes das contas do Plano de conta 
                    open={detalheCategoriaOpen.visible}
                    title={detalheCategoriaOpen.title}
                    data_periodo={detalheCategoriaOpen.data}
                    categoria_id={detalheCategoriaOpen.id}
                    handleCancel={() => {
                        setDetalheCategoriaOpen({ ...detalheCategoriaOpen, visible: false, nome: '' })
                    }}
                />
            }


            {desenhaFiltroRealizado()}
            <MDBox pt={2} pb={2} lineHeight={1}>
                <MDTypography variant="h5" fontWeight="medium">
                    Demonstrativo de Fluxo de Caixa - Realizado
                </MDTypography>
            </MDBox>

            <TabelaPadrao
                colunas={colunasTable}
                linhas={linhas}
                countAll={countAll}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                order={order}
                setOrder={setOrder}
                orderBy={orderBy}
                setOrderBy={setOrderBy}
                disablePagination={true}
            />



        </>
    );
}

export default DFCRealizado;
