
import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";

// @mui material components
//import Grid from "@mui/material/Grid";
// import { Collapse, Grid, Icon, Tooltip } from "@mui/material";
import { Box, Card, Tab, Tabs, TextField, Tooltip, Typography, Collapse, Grid, IconButton } from "@mui/material";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDButton from "components/MDButton";
import { rotafechada } from 'commons/urlconf';
import api from 'commons/api';
import { formatDate } from "commons/utils";
import { ptBR } from "date-fns/locale";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import MessagesContext from "hooks/MessagesContext";
import AuthContext from 'hooks/AuthContext';
import { endOfYear, startOfYear, startOfMonth, endOfMonth } from 'date-fns'
import format from 'date-fns/format'
import CancelIcon from '@mui/icons-material/Cancel';
import SettingsIcon from '@mui/icons-material/Settings';
import { capitalizeInitials } from "commons/utils";
import TabelaPadrao from "componentes/TabelaPadrao";
import { formataValor } from "commons/utils";
import DialogoValidaModulo from "componentes/ValidaModulo";
import { useNavigate } from "react-router-dom";
import packageJson from '../../../../package.json';
import DialogoDetalhePlanoConta from "./DetalhePlanoConta";
import MenuConfiguracaoModulos from "componentes/MenuConfiguracaoModulos";
import DensityLargeIcon from '@mui/icons-material/DensityLarge';
import DragHandleIcon from '@mui/icons-material/DragHandle';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.any,
    index: PropTypes.number,
    value: PropTypes.number,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function Dre() {

    const navigate = useNavigate();

    const messageContext = useContext(MessagesContext);
    const { MensagemErro, MostraTelaEspera, FechaTelaEspera } = messageContext;

    const [aba, setAba] = React.useState(0);
    const authContext = useContext(AuthContext);
    const { getSessao } = authContext;
    const sessaolocal = getSessao();
    const [openMensagemModuloAdicional, setOpenMensagemModuloAdicional] = useState(false);
    const [moduloValidado, setModuloValidado] = useState(false);
    const [detalhePlanoContaOpen, setDetalhePlanoContaOpen] = useState({ visible: false, title: '', data: '', id: 0 });

    const [openMenuConfiguracao, setOpenMenuConfiguracao] = useState(false);
    const [currentTargetBotaoMenu, setCurrentTargetBotaoMenu] = useState(null);

    const [parametrosDRE, setParametrosDRE] = useState({});

    //Tabela
    const [countAll, setCountAll] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('id');
    const [colunasTable, setColunasTable] = React.useState([]);
    const [linhas, setLinhas] = React.useState([]);

    // *****States relacionado do filtro de pesquisa ****
    const [dataInicial, setDataInicial] = useState(startOfYear(new Date()));
    const [dataFinal, setDataFinal] = useState(endOfYear(new Date()));
    // ****************************

    const handleChangeAba = (event, novaAba) => {
        setAba(novaAba);
    };

    const handleClickDetalhePlanoConta = (id, title, data) => {
        if (id !== 0) {
            setDetalhePlanoContaOpen({ ...detalhePlanoContaOpen, visible: true, title: title, data: data, id: id });
        }

    }

    const buscaDados = async () => {
        try {

            let complementoUrl = '';
            let dataTimeZone = new Date()
            const offSet = dataTimeZone.getTimezoneOffset()

            let dataInicialTemp = startOfMonth(dataInicial)
            let dataFinalTemp = endOfMonth(dataFinal)

            MostraTelaEspera(`Buscando dados`);

            const url = `${rotafechada()}imprimir/DRE?order=mascara&direction=asc&data1=${formatDate(dataInicialTemp, 'YYYY-MM-DD 00:00:00')}&data2=${formatDate(dataFinalTemp, 'YYYY-MM-DD 23:59:59')}&timeoffset=${offSet}`;
            //console.log("url:", url);
            const retorno = await api.post(encodeURI(url), parametrosDRE);
            const { data, cabecalho, countAll: countAllretorno } = retorno.data;
            let dadostemp = [];

            let colunasTabletemp = []
            /*Montando cabeçalho com período dinâmico */
            for (let i = 0; i < cabecalho.length; i++) {
                if (cabecalho[i] === 'Nome') {
                    colunasTabletemp.push({
                        id: i,
                        numeric: false,
                        disablePadding: true,
                        label: cabecalho[i],
                        disableOrder: true,
                        fixed: true // deixa coluna fixada
                    },)
                } else {
                    if (cabecalho[i] === 'Ordem') {

                    } else {
                        if (cabecalho[i] === 'T/A/S') {
                            // colunasTabletemp.push({
                            //     id: i,
                            //     numeric: true,
                            //     disablePadding: false,
                            //     label: cabecalho[i],
                            //     disableOrder: true,
                            // },)
                        } else {
                            if (cabecalho[i] === 'R/D') {

                            } else {
                                if (cabecalho[i] === 'Id') {
                                    // colunasTabletemp.push({
                                    //     id: i,
                                    //     numeric: true,
                                    //     disablePadding: false,
                                    //     label: cabecalho[i],
                                    //     disableOrder: true,
                                    // },)
                                } else {
                                    colunasTabletemp.push({
                                        id: i,
                                        numeric: true,
                                        disablePadding: false,
                                        label: capitalizeInitials(format(new Date(cabecalho[i] + '/01'), "MMMM'/'yyyy", { locale: ptBR })),
                                        disableOrder: true,
                                    },)
                                }
                            }
                        }
                    }


                }
            }

            /*mapeando os dados para a "rows"*/
            for (let i = 1; i < data.length; i++) {
                dadostemp.push({
                    0: data[i][0] // Id
                    , 1: data[i][1] // Ordem
                    // , 2: data[i][2] //Nome
                    , 2: <>
                        <MDTypography
                            style={data[i][4] === 'T' || data[i][4] === 'S' ? { paddingLeft: "10px" } : data[i][3] === 'R' ? { borderLeft: "3px solid green", paddingLeft: "10px" } : { borderLeft: "3px solid red", paddingLeft: "10px" }}
                            variant="subtitle"
                            fontWeight={data[i][4] === 'T' || data[i][4] === 'S' ? "bold" : ''}
                        //color={data[i][3] === 'R' && data[i][4] === 'A' ? 'success' : data[i][3] == 'D' && data[i][4] === 'A' ? 'error' : ''}
                        >
                            {data[i][4] === 'T' ? data[i][2] : data[i][4] === 'A' ? `    ${data[i][2]}` : data[i][4] === 'S' ? <><DragHandleIcon/>  {data[i][2]}</> : ''}
                        </MDTypography>
                    </>
                    , 3: data[i][3] //R/D
                    , 4: data[i][4] //T/A/S
                    // , 5: <>
                    //     {data[i][4] === 'T' || data[i][4] === 'S' ?
                    //         <MDTypography variant="subtitle" fontWeight={'bold'}>{formataValor(data[i][5]).replace("R$", "")}</MDTypography>
                    //         :
                    //         data[i][5] !== 0 ?
                    //             <MDTypography
                    //                 onClick={() => { handleClickDetalhePlanoConta(data[i][0], data[i][2], data[0][5]) }}
                    //                 color={'info'} sx={{ cursor: "pointer" }}
                    //                 variant='subtitle' >
                    //                 {formataValor(data[i][5]).replace("R$", "")}
                    //             </MDTypography>
                    //             :
                    //             formataValor(data[i][5]).replace("R$", "")
                    //     }
                    // </>
                })
            }
            /*mapeando os dados para a "rows" e montando o período dinamico*/
            dadostemp = dadostemp.map((item, index) => {
                for (let i = 5; i < cabecalho.length; i++) {
                    //item[`${i}`] = formataValor(data[index+1][i]).replace('R$','');
                    item[`${i}`] =
                        <>
                            {data[index + 1][4] === 'T' || data[index + 1][4] === 'S' ?
                                <MDTypography variant="subtitle" fontWeight={'bold'}>{formataValor(data[index + 1][i]).replace("R$", "")}</MDTypography>
                                :
                                data[index + 1][i] !== 0 ?
                                    <MDTypography
                                        onClick={() => { handleClickDetalhePlanoConta(data[index + 1][0], data[index + 1][2], data[0][i]) }}
                                        color={'info'} sx={{ cursor: "pointer" }}
                                        variant='subtitle' >
                                        {formataValor(data[index + 1][i]).replace("R$", "")}
                                    </MDTypography>
                                    :
                                    formataValor(data[index + 1][i]).replace("R$", "")
                            }
                        </>
                }
                return item
            })

            // console.log('dadosTemp:', dadostemp)

            setColunasTable([...colunasTabletemp]);
            setLinhas(dadostemp);
            setCountAll(countAllretorno);
            FechaTelaEspera();
        }
        catch (error) {
            FechaTelaEspera();
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    }

    const buscaParametros = async () => {


        try {
            let url = `${rotafechada()}vendas/parametros`;
            const retorno = await api.get(encodeURI(url));
            const data = retorno.data;
            // console.log('parametrosDRE', data)
            setParametrosDRE(data);
        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    }

    const handleClickPesquisar = () => {
        buscaDados();
    }

    const desenhaFiltro = () => {
        return (
            <Collapse in={true} timeout="auto" unmountOnExit>
                {/* <Collapse in={openFiltrosAvancados} timeout="auto" unmountOnExit> */}
                <Box sx={{ margin: 1 }}>
                    < Grid container spacing={2} justifyContent="flex-start" >
                        <Grid item xs={12} sm={3} >{/*Filtro Data Inicial*/}
                            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                                <DesktopDatePicker
                                    label='Mês Inicial'
                                    inputFormat="MM/yyyy"
                                    value={dataInicial}
                                    onChange={(e) => setDataInicial(e)}
                                    renderInput={(params) => <TextField {...params} required
                                        helperText={dataInicial > 0 ? false :
                                            <>
                                                <CancelIcon color={'error'} />
                                                <Typography pl={0.5} fontWeight="small" variant="caption" color={'error'}>Informe uma data válida</Typography>
                                            </>
                                        }
                                    />}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} sm={3}   >{/*Filtro Data Final*/}
                            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                                <DesktopDatePicker
                                    label='Mês Final'
                                    inputFormat="MM/yyyy"
                                    value={dataFinal}
                                    onChange={(e) => setDataFinal(e)}
                                    renderInput={(params) => <TextField {...params} required
                                        helperText={dataFinal > 0 ? false :
                                            <>
                                                <CancelIcon color={'error'} />
                                                <Typography pl={0.5} fontWeight="small" variant="caption" color={'error'}>Informe uma data válida</Typography>
                                            </>
                                        }
                                    />}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} sm={2} >{/*Botão do Filtro*/}
                            <MDButton
                                disabled={!(dataInicial > 0) || !(dataFinal > 0) ? true : false}
                                onClick={handleClickPesquisar}
                                color='info'
                                variant="gradient"
                            >
                                Atualizar demonstrativo</MDButton>
                        </Grid>
                    </Grid >
                </Box>
            </Collapse>
        )
    }

    const validaModulo = async () => {

        MostraTelaEspera('Validando acesso ao módulo');
        try {
            let url = `${rotafechada()}assinatura_modulos/validar?nome=${'FINANCEIRO'}`;
            await api.get(url);
            setModuloValidado(true)

        }
        catch (error) {
            setOpenMensagemModuloAdicional(true)
        }
        FechaTelaEspera();
    }

    useEffect(() => {
        if (moduloValidado) {
            buscaDados();
        }
    }, [page, rowsPerPage, order, orderBy, moduloValidado])

    useEffect(async () => {
        await buscaParametros();
        await validaModulo()
    }, [])

    return (
        <>
            <DialogoValidaModulo
                open={openMensagemModuloAdicional}
                title={'Módulo adicional'}
                message={'Módulo Financeiro não contratado, para mais informações entre em contato com nossa equipe de suporte!'}
                handleCancel={() => {
                    setOpenMensagemModuloAdicional(false);
                    navigate('/desktop');
                }}
                handleConfirm={() => {
                    setOpenMensagemModuloAdicional(false);
                    navigate('/desktop');
                    window.open(packageJson?.urlsuporte.replace('text', 'text=Tenho interesse em contratar o módulo Financeiro para o VidrosWeb📌 '))
                }
                }
            />

            <MenuConfiguracaoModulos
                open={openMenuConfiguracao}
                setOpen={setOpenMenuConfiguracao}
                nomeModulo='FINANCEIRO'
                currentTarget={currentTargetBotaoMenu}
            />

            {detalhePlanoContaOpen.visible === true &&

                <DialogoDetalhePlanoConta // Ver detalhes das contas do Plano de conta 
                    open={detalhePlanoContaOpen.visible}
                    title={detalhePlanoContaOpen.title}
                    data_periodo={detalhePlanoContaOpen.data}
                    plano_conta_id={detalhePlanoContaOpen.id}
                    parametrosDRE={parametrosDRE}
                    handleCancel={() => {
                        setDetalhePlanoContaOpen({ ...detalhePlanoContaOpen, visible: false, nome: '' })
                    }}
                />
            }

            <DashboardLayout>
                <DashboardNavbar />
                <MDBox mt={5} mb={9}>
                    <Grid container justifyContent="center">
                        <Grid item xs={12} lg={12}>
                            <MDBox mt={0} mb={0} textAlign="center">
                                <MDBox mb={1}>
                                    <MDTypography variant="h3" fontWeight="bold">
                                        {`DRE`}
                                    </MDTypography>
                                </MDBox>
                                {/* Botao de Configuracoes (Parametros e Permissoes) */}
                                <MDBox width="100%" display="flex" justifyContent="flex-end">
                                    <Tooltip title="Configurações e Permissões" placement="top">
                                        <IconButton
                                            onClick={(event) => {
                                                setCurrentTargetBotaoMenu(event.currentTarget);
                                                setOpenMenuConfiguracao(true);
                                            }
                                            }
                                        >
                                            <SettingsIcon />
                                        </IconButton>
                                    </Tooltip>
                                </MDBox>
                            </MDBox>
                            <Card>
                                <MDBox mt={1} mb={0} mx={2}>
                                    <Tabs value={aba} onChange={handleChangeAba} aria-label={`Aba de DRE`}>
                                        <Tab label="DRE" {...a11yProps(0)} />
                                        {/* <Tab label="DFC" {...a11yProps(1)} /> */}
                                    </Tabs>

                                </MDBox>

                                <TabPanel value={aba} index={0}>{/* DRE */}
                                    {desenhaFiltro()}
                                    <MDBox pt={2} pb={2} lineHeight={1}>
                                        <MDTypography variant="h5" fontWeight="medium">
                                            Demonstrativo de Resultado do Exercício Gerencial
                                        </MDTypography>
                                    </MDBox>

                                    <TabelaPadrao
                                        colunas={colunasTable}
                                        linhas={linhas}
                                        countAll={countAll}
                                        page={page}
                                        setPage={setPage}
                                        rowsPerPage={rowsPerPage}
                                        setRowsPerPage={setRowsPerPage}
                                        order={order}
                                        setOrder={setOrder}
                                        orderBy={orderBy}
                                        setOrderBy={setOrderBy}
                                        disablePagination={true}
                                    />
                                </TabPanel>

                                <TabPanel value={aba} index={1}> {/* DFC */}

                                </TabPanel>
                            </Card>
                        </Grid>
                    </Grid>
                </MDBox>
                <Footer />
            </DashboardLayout>
        </>
    );
}

export default Dre;
