import React, { useContext, useEffect, useRef, useState } from 'react';
import { Box } from '@mui/system';
import { Autocomplete, Dialog, Icon, IconButton, TextField, Tooltip, Typography } from '@mui/material';
import { copiaProfundaObjetos } from 'commons/utils';
import CamadaDesenho from './camadadesenho/camadadesesenho';
import { PropriedadesRetangulo } from './camadadesenho/objetos/retangulo';
import MDButton from 'components/MDButton';
import { PropriedadesCirculo } from './camadadesenho/objetos/circulo';
import { PropriedadesLinhaHorizontal } from './camadadesenho/objetos/linhahorizontal';
import { PropriedadesLinhaVertical } from './camadadesenho/objetos/linhavertical';
import { PropriedadesVariavelLabel } from './camadadesenho/objetos/variavel';
import MessagesContext from 'hooks/MessagesContext';
import { rotafechada } from 'commons/urlconf';
import api from 'commons/api';
import { PropriedadesTexto } from './camadadesenho/objetos/texto';
import DialogoConfirma from 'componentes/DialogoConfirma';
import SaveIcon from '@mui/icons-material/Save';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FlipToFrontIcon from '@mui/icons-material/FlipToFront';
import FlipToBackIcon from '@mui/icons-material/FlipToBack';
import ArticleIcon from '@mui/icons-material/Article';
import SettingsIcon from '@mui/icons-material/Settings';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import PrintIcon from '@mui/icons-material/Print';
import ImageIcon from '@mui/icons-material/Image';
import FiltroImportarProjeto from 'pages/projetos/projetos/Cadastro/cabecalho/filtroimportarprojeto';
import { PropriedadesDBTexto } from './camadadesenho/objetos/dbtexto';
import { PropriedadesDBCalc } from './camadadesenho/objetos/dbcalc';
import { PropriedadesImagem } from './camadadesenho/objetos/imagem';
import { PropriedadesDbImagem } from './camadadesenho/objetos/dbimagem';
import AuthContext from 'hooks/AuthContext';
import ArvorePropriedades from './camadadesenho/objetos/ArvorePropriedades';
import VerticalAlignTopIcon from '@mui/icons-material/VerticalAlignTop';
import AlignHorizontalLeftIcon from '@mui/icons-material/AlignHorizontalLeft';
import AlignHorizontalRightIcon from '@mui/icons-material/AlignHorizontalRight';
import { PropriedadesMemo } from './camadadesenho/objetos/memo';
import { PropriedadesDBMemo } from './camadadesenho/objetos/dbmemo';
import { PropriedadesGrupo } from './camadadesenho/objetos/grupo';
import { PropriedadesGerais } from './camadadesenho/objetos/propriedadesgerais';
import { PropriedadesBanda } from './camadadesenho/objetos/banda';
import { PropriedadesBandaCabecalhoRodape } from './camadadesenho/objetos/bandacabecalhorodape';
import { PropriedadesFormatMemo } from './camadadesenho/objetos/formatmemo';


//const (confRelatorio.FATORCONVERSAOPIXELMM||3) = 3;
//const LARGURA = (confRelatorio.FATORCONVERSAOPIXELMM||3) * 210;//1050
//const ALTURA = (confRelatorio.FATORCONVERSAOPIXELMM||3) * 297;//1485

//largura do A4 = 210 mm
//altura do A4 = 297mm

const opcoesTipos = [
    {
        label: 'Banda-Cabeçalho',
        icon: <Icon fontSize='large'>rectangle</Icon>,
        tipo: 'banda-cabecalho'
    },
    {
        label: 'Banda-Título',
        icon: <Icon fontSize='large'>rectangle</Icon>,
        tipo: 'banda-titulo'
    },
    {
        label: 'Banda-Detalhe(repetição)',
        icon: <Icon fontSize='large'>rectangle</Icon>,
        tipo: 'banda-detalhe'
    },
    {
        label: 'Banda-Sumário',
        icon: <Icon fontSize='large'>rectangle</Icon>,
        tipo: 'banda-sumario'
    },
    {
        label: 'Banda-Rodapé',
        icon: <Icon fontSize='large'>rectangle</Icon>,
        tipo: 'banda-rodape'
    },
    {
        label: 'Grupo',
        icon: <Icon fontSize='large'>rectangle</Icon>,
        tipo: 'banda-grupo'
    },
    {
        label: 'Retângulo',
        icon: <Icon fontSize='large'>rectangle</Icon>,
        tipo: 'rect'
    },
    {
        label: 'Texto',
        icon: <Icon fontSize='large'>text_format</Icon>,
        tipo: 'text'
    },
    {
        label: 'DBTexto',
        icon: <Icon fontSize='large'>text_format</Icon>,
        tipo: 'dbtext'
    },
    {
        label: 'Memo',
        icon: <Icon fontSize='large'>text_format</Icon>,
        tipo: 'memo'
    },
    {
        label: 'DBMemo',
        icon: <Icon fontSize='large'>text_format</Icon>,
        tipo: 'dbmemo'
    },
    {
        label: 'DBCalc',
        icon: <Icon fontSize='large'>text_format</Icon>,
        tipo: 'dbcalc'
    },
    {
        label: 'Variável',
        icon: <Icon fontSize='large'>text_format</Icon>,
        tipo: 'variavellabel'
    },
    {
        label: 'Linha Horizontal',
        icon: <Icon fontSize='large'>remove</Icon>,
        tipo: 'linhahorizontal'
    },
    {
        label: '|  Linha Vertical',
        icon: null,
        tipo: 'linhavertical'
    },
    {
        label: 'Imagem',
        icon: <ImageIcon />,
        tipo: 'imagem'
    },
    {
        label: 'DBImagem',
        icon: <ImageIcon />,
        tipo: 'dbimagem'
    },
    {
        label: 'Memo Formatado',
        icon: <Icon fontSize='small'>text_format</Icon>,
        tipo: 'formatmemo'
    },
]

const opcoesFormatacao = [
    {
        value: '{format.moeda}',
        label: 'Moeda R$'
    },
    {
        value: '{format.decimal_2}',
        label: 'Decimal (2 digitos)'
    },
    {
        value: '{format.decimal_4}',
        label: 'Decimal (4 digitos)'
    },
    {
        value: '{format.integer}',
        label: 'Inteiro'
    },
    {
        value: '{format.date_dd_mm_yy}',
        label: 'Data (dd/mm/yy)'
    },
    {
        value: '{format.date_dd_mm_yyyy}',
        label: 'Data (dd/mm/yyyy)'
    },
    {
        value: '{format.date_dd_mm_yy_hh:mm}',
        label: 'Data/Hora (dd/mm/yy hh:mm)'
    },
    {
        value: '{format.date_dd_mm_yyyy_hh:mm}',
        label: 'Data/Hora (dd/mm/yyyy hh:mm)'
    },
    {
        value: '{format.numero_extenso}',
        label: 'Número por extenso'
    },
]

function PropriedadesFigura(props) {

    //Ao clicar no botao de propriedades esse componente é chamado e abre em formato de dialog
    //a propriedade do componente selecionado

    const { itensTela, setItensTela, selectedId } = props;

    if (!(parseInt(selectedId, 10) >= 0)) {
        return false;
    }

    if (!(parseInt(selectedId, 10) <= (itensTela.length - 1))) {
        return false;
    }

    console.log(itensTela[selectedId].tipo);
    switch (itensTela[selectedId].tipo) {

        case 'rect':
            return (
                <PropriedadesRetangulo
                    {...props}
                />
            )
        case 'circle':
            return (
                <PropriedadesCirculo
                    {...props}
                />
            )
        case 'linhahorizontal':
            return (
                <PropriedadesLinhaHorizontal
                    {...props}
                />
            )
        case 'linhavertical':
            return (
                <PropriedadesLinhaVertical
                    {...props}
                />
            )
        case 'text':
            return (
                <PropriedadesTexto
                    {...props}
                />
            )
        case 'dbtext':
            return (
                <PropriedadesDBTexto
                    {...props}
                />
            )
        case 'dbmemo':
            return (
                <PropriedadesDBMemo
                    {...props}
                />
            )
        case 'memo':
            return (
                <PropriedadesMemo
                    {...props}
                />
            )

        case 'formatmemo':
            return (
                <PropriedadesFormatMemo
                    {...props}
                />
            )
        case 'dbcalc':
            return (
                <PropriedadesDBCalc
                    {...props}
                />
            )
        case 'variavellabel':
            return (
                <PropriedadesVariavelLabel
                    {...props}
                />
            )
        case 'imagem':
            return (
                <PropriedadesImagem
                    {...props}
                />
            )
        case 'dbimagem':
            return (
                <PropriedadesDbImagem
                    {...props}
                />
            )
        case 'banda-grupo-cabecalho':
        case 'banda-grupo-rodape':
            return (
                <PropriedadesGrupo
                    {...props}
                />
            )
        case 'banda-titulo':
        case 'banda-sumario':
        case 'banda-detalhe':
            return (
                <PropriedadesBanda
                    {...props}
                />
            )
        case 'banda-cabecalho':
        case 'banda-rodape':

            return (
                <PropriedadesBandaCabecalhoRodape
                    {...props}
                />
            )
        default:
            break;
    }
    return false;
}

export function BarraBotoes(props) {

    //Barra superior de botoes principais (novo, recarregar, salvar...)

    const { nomeRelatorio, handleClickNovoDesenho, handleClickGravarRelatorio,
        handleClickRecarregarDesenho,
        handleClickImprimeRelatorio,
        handleClickConfiguracaoGeralRelatorio
    } = props;

    return (
        <Box display={'flex'}>

            <Tooltip title='Novo Desenho' >
                <IconButton
                    sx={{ color: 'blue' }}
                    onClick={handleClickNovoDesenho}
                >
                    <ArticleIcon fontSize='large' />
                </IconButton>
            </Tooltip>


            <Tooltip title='Gravar Alterações (Ctrl+S'>
                <IconButton
                    sx={{ color: 'blue' }}
                    onClick={handleClickGravarRelatorio}
                >
                    <SaveIcon fontSize='large' />
                </IconButton>
            </Tooltip>



            <Tooltip title='Recarregar Desenho (Ctrl+O)'>
                <IconButton
                    sx={{ color: 'blue' }}
                    onClick={handleClickRecarregarDesenho}
                >
                    <FolderOpenIcon fontSize='large' />
                </IconButton>
            </Tooltip>


            <Tooltip title='Imprimir'>
                <IconButton
                    sx={{ color: 'blue' }}
                    onClick={handleClickImprimeRelatorio}
                >
                    <PrintIcon fontSize='large' />

                </IconButton>
            </Tooltip>

            <Tooltip title='Configurações'>
                <IconButton
                    sx={{ color: 'blue' }}
                    onClick={handleClickConfiguracaoGeralRelatorio}
                >
                    <SettingsIcon fontSize='large' />

                </IconButton>
            </Tooltip>

            <Box>
                <Box display='flex' mt={2}>
                    <Typography variant='h6'>{nomeRelatorio}</Typography>
                </Box>
            </Box>

        </Box>
    )

}

export function BarraObjetosePropriedades(props) {

    const { setTipoSelecionado, selectedId, tipoSelecionado, selectShape, setMultiplosSelecionados,
        handleClickDeletaItem, setOpenPropriedades, handleClickNovoObjeto, handleClickDuplicaItem,
        handleClickEnviarParaTras, handleClickEnviarParaFrente,
        handleClickMovimentaEsquerda, handleClickMovimentaDireita,
        handleClickMovimentaCima, handleClickMovimentaBaixo } = props;

    return (
        <Box sx={{ mb: 2, mt: 2 }} >

            <Box display={'flex'} justifyContent='flex-start'>
                <Autocomplete
                    id="ACtipos"
                    sx={{ width: 300 }}
                    options={opcoesTipos}
                    autoHighlight
                    getOptionLabel={(option) => option.label}
                    renderOption={(props, option) => (
                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                            {option.icon}
                            {option.label}
                        </Box>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Escolha um Tipo"
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'tipo', // disable autocomplete and autofill
                            }}
                        />
                    )}
                    value={tipoSelecionado}
                    onChange={(event, newValue) => {
                        setTipoSelecionado(newValue);
                    }}
                />
                <MDButton color='info' sx={{ ml: 2 }} onClick={handleClickNovoObjeto}>
                    <Icon fontSize='large' >add</Icon>
                </MDButton>
            </Box>
            {
                parseInt(selectedId, 10) >= 0 &&
                <Box display={'flex'} justifyContent='flex-start'>

                    <Tooltip title='Cancelar seleção'>
                        <MDButton onClick={() => {
                            setMultiplosSelecionados([]);
                            selectShape(null)
                        }
                        }>
                            <Icon fontSize='large'>deselect</Icon>
                        </MDButton>
                    </Tooltip>
                    <Tooltip title='Apaga Item'>
                        <IconButton
                            sx={{ color: 'red' }}
                            onClick={handleClickDeletaItem}
                        >
                            <Icon >delete</Icon>
                        </IconButton>
                    </Tooltip>

                    <Tooltip title='Propriedades do objeto'>
                        <IconButton
                            sx={{ color: 'blue' }}
                            onClick={() => setOpenPropriedades(true)}
                        >
                            <SettingsIcon />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title='Duplicar (Ctrl+D)'>
                        <IconButton
                            sx={{ color: 'blue' }}
                            onClick={handleClickDuplicaItem}
                        >
                            <ContentCopyIcon />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title='Enviar para Frente'>
                        <IconButton
                            sx={{ color: 'blue' }}
                            onClick={handleClickEnviarParaFrente}
                        >
                            <FlipToFrontIcon />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title='Enviar para Trás'>
                        <IconButton
                            sx={{ color: 'blue' }}
                            onClick={handleClickEnviarParaTras}
                        >
                            <FlipToBackIcon />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title='Movimenta para esquerda'>
                        <IconButton
                            sx={{ color: 'blue' }}
                            onClick={() => handleClickMovimentaEsquerda(1)}
                        >
                            <ArrowBackIcon />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title='Movimenta para direita'>

                        <IconButton
                            sx={{ color: 'blue' }}
                            onClick={() => handleClickMovimentaDireita(1)}
                        >
                            <ArrowForwardIcon />
                        </IconButton>
                    </Tooltip>


                    <Tooltip title='Movimenta para cima'>

                        <IconButton
                            sx={{ color: 'blue' }}
                            onClick={() => handleClickMovimentaCima(1)}
                        >
                            <ArrowUpwardIcon />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title='Movimenta para baixo'>

                        <IconButton
                            sx={{ color: 'blue' }}
                            onClick={() => handleClickMovimentaBaixo(1)}
                        >
                            <ArrowDownwardIcon />
                        </IconButton>
                    </Tooltip>



                </Box>
            }
        </Box>
    )
}

export function BarraMultiplosSelecionados(props) {

    const { selectedId, selectShape, setMultiplosSelecionados,
        handleClickDeletaItem, handleClickAlinhaparaCima,
        handleClickMovimentaEsquerda, handleClickMovimentaDireita,
        handleClickMovimentaCima, handleClickMovimentaBaixo,
        handleClickAlinhaparaEsquerda, handleClickAlinhaparaDireita
    } = props;

    return (
        <Box sx={{ mb: 2, mt: 2 }} >

            <Box display={'flex'} justifyContent='flex-start'>

                <Tooltip title='Cancelar seleção'>
                    <MDButton onClick={() => {
                        setMultiplosSelecionados([]);
                        selectShape(null)
                    }
                    }>
                        <Icon fontSize='large'>deselect</Icon>
                    </MDButton>
                </Tooltip>
                <Tooltip title='Apaga Item'>
                    <IconButton
                        sx={{ color: 'red' }}
                        onClick={handleClickDeletaItem}
                    >
                        <Icon >delete</Icon>
                    </IconButton>
                </Tooltip>


                <Tooltip title='Alinha para cima'>
                    <IconButton
                        sx={{ color: 'blue' }}
                        onClick={() => handleClickAlinhaparaCima()}
                    >

                        <VerticalAlignTopIcon />

                    </IconButton>
                </Tooltip>
                <Tooltip title='Alinha para Esquerda'>
                    <IconButton
                        sx={{ color: 'blue' }}
                        onClick={() => handleClickAlinhaparaEsquerda()}
                    >
                        <AlignHorizontalLeftIcon />
                    </IconButton>
                </Tooltip>

                <Tooltip title='Alinha para Direita'>
                    <IconButton
                        sx={{ color: 'blue' }}
                        onClick={() => handleClickAlinhaparaDireita()}
                    >
                        <AlignHorizontalRightIcon />
                    </IconButton>
                </Tooltip>

                <Tooltip title='Movimenta para esquerda'>
                    <IconButton
                        sx={{ color: 'blue' }}
                        onClick={() => handleClickMovimentaEsquerda(1)}
                    >
                        <ArrowBackIcon />
                    </IconButton>
                </Tooltip>

                <Tooltip title='Movimenta para direita'>

                    <IconButton
                        sx={{ color: 'blue' }}
                        onClick={() => handleClickMovimentaDireita(1)}
                    >
                        <ArrowForwardIcon />
                    </IconButton>
                </Tooltip>


                <Tooltip title='Movimenta para cima'>

                    <IconButton
                        sx={{ color: 'blue' }}
                        onClick={() => handleClickMovimentaCima(1)}
                    >
                        <ArrowUpwardIcon />
                    </IconButton>
                </Tooltip>

                <Tooltip title='Movimenta para baixo'>

                    <IconButton
                        sx={{ color: 'blue' }}
                        onClick={() => handleClickMovimentaBaixo(1)}
                    >
                        <ArrowDownwardIcon />
                    </IconButton>
                </Tooltip>
            </Box>

        </Box>
    )
}

export default function DesenhaRelatorio(props) {

    const { relatorio_id, datasets, filtros, desabilitaBarras } = props;
    const itemsRef = useRef(null);

    const messageContext = useContext(MessagesContext);
    const { MensagemErro, MensagemAviso, MostraTelaEspera, FechaTelaEspera } = messageContext;

    const authContext = useContext(AuthContext);
    const { getSessao } = authContext;
    const sessaolocal = getSessao();

    //controlar o ID dos objetos que serao adicionados
    const [idGlobal, setIdGlobal] = useState(0);

    const [itensTela, setItensTela] = useState([]);
    const [itensTelaModelo, setItensTelaModelo] = useState([]);
    const [itensTelaBackup, setItensTelaBackup] = useState([]);
    const [selectedId, selectShape] = useState(null);
    const [confRelatorio, setConfRelatorio] = useState({});

    const refDesenhoModelo = React.useRef(null);


    const [tipoSelecionado, setTipoSelecionado] = useState(opcoesTipos[0]);
    const [openPropriedades, setOpenPropriedades] = useState(false);

    const [nomeRelatorio, setNomeRelatorio] = useState('');

    const [fieldsDbLabel, setFieldsDbLabel] = useState([]);

    const [openModelo, setOpenModelo] = useState(false);
    const [openConfirmacao, setOpenConfirmacao] = useState({ open: false })
    const [openFiltroImportarProjeto, setOpenFiltroImportarProjeto] = useState(false);

    const [shiftPressionado, setShiftPressionado] = useState(false);
    const [multiplosSelecionados, setMultiplosSelecionados] = useState([]);

    const [openConfiguracoesGerais, setOpenConfiguracoesGerais] = useState(false);

    const handleClickNovoRetangulo = () => {

        let bandaPai = encontraBandaporPosicao({ x: 50, y: 50 });

        if (!bandaPai) {
            MensagemErro('Nenhuma banda foi encontrada');
            return;
        }

        const itensTelaTemp = itensTela.slice();

        const retangulotemp = {
            tipo: 'rect',

            width: 50,
            x: 50,
            y: 50,
            width: 100,
            height: 100,
            fill: null, //preenchimento
            stroke: 'black', //borda
            strokeWidth: 1,
            y_banda: 50,
            banda: bandaPai.id,
            id: (idGlobal + 1),

        }

        setIdGlobal(idGlobal + 1);
        itensTelaTemp.push(retangulotemp);
        setItensTela(itensTelaTemp);
    }

    const handleClickNovaLinhaHorizontal = () => {


        let bandaPai = encontraBandaporPosicao({ x: 50, y: 50 });

        if (!bandaPai) {
            MensagemErro('Nenhuma banda foi encontrada');
            return;
        }

        const itensTelaTemp = itensTela.slice();


        const linhahorizontaltemp = {

            tipo: 'linhahorizontal',
            width: 50,
            x: 50,
            y: 50,
            points: [0, 0, 100, 0],
            fill: 'black',
            stroke: 'black',
            strokeWidth: 5,
            y_banda: 50,
            banda: bandaPai.id,
            id: (idGlobal + 1),
        }

        setIdGlobal(idGlobal + 1);
        itensTelaTemp.push(linhahorizontaltemp);
        setItensTela(itensTelaTemp);
    }

    const handleClickNovaLinhaVertical = () => {
        let bandaPai = encontraBandaporPosicao({ x: 50, y: 50 });

        if (!bandaPai) {
            MensagemErro('Nenhuma banda foi encontrada');
            return;
        }

        const itensTelaTemp = itensTela.slice();

        const linhaverticaltemp = {

            tipo: 'linhavertical',
            width: 50,
            x: 50,
            y: 50,
            points: [0, 0, 0, 100],
            fill: 'black',
            stroke: 'black',
            strokeWidth: 5,
            //dash:[10,5],
            dash: null, y_banda: 50,
            banda: bandaPai.id,
            id: (idGlobal + 1),
        }

        setIdGlobal(idGlobal + 1);
        itensTelaTemp.push(linhaverticaltemp);
        setItensTela(itensTelaTemp);
    }

    const handleClickNovoCirculo = () => {

        //const itensTelaTemp = copiaProfundaObjetos(itensTela);

        const itensTelaTemp = itensTela.slice();

        const circletemp = {
            tipo: 'circle',

            width: 50,
            x: 50,
            y: 50,
            fill: null, //preenchimento
            stroke: 'black', //borda
            radius: 50

        }

        itensTelaTemp.push(circletemp);
        setItensTela(itensTelaTemp);
    }

    const handleClickNovaImagem = () => {

        //const itensTelaTemp = copiaProfundaObjetos(itensTela);

        let bandaPai = encontraBandaporPosicao({ x: 50, y: 50 });

        if (!bandaPai) {
            MensagemErro('Nenhuma banda foi encontrada');
            return;
        }

        const itensTelaTemp = itensTela.slice();

        const imagemtemp = {
            tipo: 'imagem',

            width: 50,
            x: 50,
            y: 50,
            width: 50,
            height: 50,
            fill: null, //preenchimento
            stroke: 'black', //borda
            radius: 50,
            nome_imagem: '',
            imagembase64: '',
            banda: bandaPai.id,
            id: (idGlobal + 1),
        }

        setIdGlobal(idGlobal + 1);
        itensTelaTemp.push(imagemtemp);
        setItensTela(itensTelaTemp);
    }
    const handleClickNovaDbImagem = () => {

        //const itensTelaTemp = copiaProfundaObjetos(itensTela);

        let bandaPai = encontraBandaporPosicao({ x: 50, y: 50 });

        if (!bandaPai) {
            MensagemErro('Nenhuma banda foi encontrada');
            return;
        }

        const itensTelaTemp = itensTela.slice();

        const imagemtemp = {
            tipo: 'dbimagem',

            width: 50,
            x: 50,
            y: 50,
            width: 50,
            height: 50,
            fill: null, //preenchimento
            stroke: 'black', //borda
            radius: 50,
            dataset: '',
            field: '',
            imagemexterna: false,
            banda: bandaPai.id,
            id: (idGlobal + 1),
        }

        setIdGlobal(idGlobal + 1);
        itensTelaTemp.push(imagemtemp);
        setItensTela(itensTelaTemp);
    }

    const handleClickNovoBandaCabecalho = () => {

        const posicaobanda = itensTela.filter((item) => item.tipo === 'banda-cabecalho');
        if (posicaobanda.length > 0) {
            MensagemErro('Já existe uma banda cabeçalho')
            return;
        }

        const itensTelaTemp = itensTela.slice();

        const bandacabecalhotempo = {
            tipo: 'banda-cabecalho',
            x: 2,
            y: 2,
            width: (confRelatorio.largurafolha * (confRelatorio.FATORCONVERSAOPIXELMM || 3)) - 2,
            height: 100,
            fill: null, //preenchimento
            stroke: 'black', //borda
            strokeWidth: 1,
            id: (idGlobal + 1),
            banda: -1,
        }

        itensTelaTemp.unshift(bandacabecalhotempo);//insere no inicio
        let vetor = ordenaBandasVetor(itensTelaTemp);
        vetor = reposicionaBandas(vetor);
        setItensTela(vetor);
        setIdGlobal(idGlobal + 1);
    }

    const handleClickNovoBandaTitulo = () => {

        //const itensTelaTemp = copiaProfundaObjetos(itensTela);

        const posicaobanda = itensTela.filter((item) => item.tipo === 'banda-titulo');
        if (posicaobanda.length > 0) {
            MensagemErro('Já existe uma banda título')
            return;
        }

        const itensTelaTemp = itensTela.slice();

        const bandatitulotemp = {
            tipo: 'banda-titulo',
            x: 2,
            y: 2,
            width: (confRelatorio.largurafolha * (confRelatorio.FATORCONVERSAOPIXELMM || 3)) - 2,
            height: 100,
            fill: null, //preenchimento
            stroke: 'green', //borda
            strokeWidth: 2,
            id: (idGlobal + 1),
            banda: -1,
        }
        itensTelaTemp.unshift(bandatitulotemp);//insere no inicio
        let vetor = ordenaBandasVetor(itensTelaTemp);
        vetor = reposicionaBandas(vetor);

        setItensTela(vetor);
        setIdGlobal(idGlobal + 1);
    }

    const handleClickNovoBandaSumario = () => {

        //const itensTelaTemp = copiaProfundaObjetos(itensTela);

        const posicaobanda = itensTela.filter((item) => item.tipo === 'banda-sumario');
        if (posicaobanda.length > 0) {
            MensagemErro('Já existe uma banda Sumário')
            return;
        }

        const itensTelaTemp = itensTela.slice();

        const bandasumariotemp = {
            tipo: 'banda-sumario',
            x: 2,
            y: 2,
            width: (confRelatorio.largurafolha * (confRelatorio.FATORCONVERSAOPIXELMM || 3)) - 2,
            height: 100,
            fill: null, //preenchimento
            stroke: 'blue', //borda
            strokeWidth: 1,
            id: (idGlobal + 1),
            banda: -1,
        }
        itensTelaTemp.unshift(bandasumariotemp);//insere no inicio

        let vetor = ordenaBandasVetor(itensTelaTemp);
        vetor = reposicionaBandas(vetor);

        setItensTela(vetor);
        setIdGlobal(idGlobal + 1);
    }

    const handleClickNovoBandaRodape = () => {

        //const itensTelaTemp = copiaProfundaObjetos(itensTela);

        const posicaobanda = itensTela.filter((item) => item.tipo === 'banda-rodape');
        if (posicaobanda.length > 0) {
            MensagemErro('Já existe uma banda Rodapé')
            return;
        }

        const itensTelaTemp = itensTela.slice();

        const bandarodapetemp = {
            tipo: 'banda-rodape',
            x: 2,
            y: 2,
            width: (confRelatorio.largurafolha * (confRelatorio.FATORCONVERSAOPIXELMM || 3)) - 2,
            height: 100,
            fill: null, //preenchimento
            stroke: 'brown', //borda
            strokeWidth: 1,
            id: (idGlobal + 1),
            banda: -1,
        }
        itensTelaTemp.unshift(bandarodapetemp);//insere no inicio

        let vetor = ordenaBandasVetor(itensTelaTemp);
        vetor = reposicionaBandas(vetor);

        setItensTela(vetor);
        setIdGlobal(idGlobal + 1);
    }

    const handleClickNovoBandaGrupo = () => {

        //const itensTelaTemp = copiaProfundaObjetos(itensTela);

        const posicaobanda = itensTela.filter((item) => item.tipo === 'banda-detalhe');
        if (posicaobanda.length === 0) {
            MensagemErro('Não é possível adicionar uma banda grupo sem ter uma banda detalhe (repetição)')
            return;
        }

        debugger;

        const itensTelaTemp = itensTela.slice();
        const confRelatorioTemp = copiaProfundaObjetos(confRelatorio);
        let idGlobalTemp = idGlobal;


        idGlobalTemp++;
        const idGrupo = idGlobalTemp;

        if (!(confRelatorioTemp.grupos)) {
            confRelatorioTemp.grupos = [];
        }

        //insiro sempre no inicio porque pela logica quem foi inserido por ultimo sera totalizado primeiro no relatorio
        confRelatorioTemp.grupos.unshift({ idGrupo });

        //as bandas de grupo são inseridas em ordem correta no vetor
        //as de grupo-cabecalho sao inseridas ou antes de um detalhe ou antes de outra grupo-cabecalho caso ja existe
        //isso me dara o controle de quem vem primeiro


        let posicaoinsercao = 0;

        for (let i = 0; i < itensTelaTemp.length; i++) {

            if ((itensTelaTemp[i].tipo === 'banda-grupo-cabecalho') || (itensTelaTemp[i].tipo === 'banda-detalhe')) {
                //insiro antes dela e saio do laço
                posicaoinsercao = i;
                break;
            }
        }

        idGlobalTemp++;
        let bandagrupotemp = {
            tipo: 'banda-grupo-cabecalho',
            x: 2,
            y: 2,
            width: (confRelatorio.largurafolha * (confRelatorio.FATORCONVERSAOPIXELMM || 3)) - 2,
            height: 100,
            fill: null, //preenchimento
            stroke: 'brown', //borda
            strokeWidth: 1,
            id: idGlobalTemp,
            idGrupo,
            banda: -1,
        }
        itensTelaTemp.splice(posicaoinsercao, 0, bandagrupotemp);//insere na posicao escolhida

        posicaoinsercao = 0;

        //percorro o vetor de baixo pra cima
        for (let i = itensTelaTemp.length - 1; i >= 0; i--) {

            if ((itensTelaTemp[i].tipo === 'banda-detalhe') || (itensTelaTemp[i].tipo === 'banda-grupo-rodape')) {
                //insiro depois dela e saio do laço
                posicaoinsercao = i + 1;
                break;
            }
        }

        idGlobalTemp++;
        bandagrupotemp = {
            tipo: 'banda-grupo-rodape',
            x: 2,
            y: 2,
            width: (confRelatorio.largurafolha * (confRelatorio.FATORCONVERSAOPIXELMM || 3)) - 2,
            height: 100,
            fill: null, //preenchimento
            stroke: 'brown', //borda
            strokeWidth: 1,
            id: idGlobalTemp,
            idGrupo,
            banda: -1,
        }
        itensTelaTemp.splice(posicaoinsercao, 0, bandagrupotemp);//insere na posicao escolhida

        let vetor = ordenaBandasVetor(itensTelaTemp);
        vetor = reposicionaBandas(vetor);

        setItensTela(vetor);
        setIdGlobal(idGlobalTemp);
        setConfRelatorio(confRelatorioTemp);
    }

    const handleClickNovoBandaDetalhe = () => {



        let bandaAnterior = itensTela.filter((item) => item.tipo === 'banda-detalhe');

        if (bandaAnterior.length > 0) {
            MensagemErro('Já existe uma banda detalhe no relatório')
            return;
        }


        const itensTelaTemp = itensTela.slice();

        const bandadetalhetemp = {
            tipo: 'banda-detalhe',
            x: 2,
            y: 2,
            width: (confRelatorio.largurafolha * (confRelatorio.FATORCONVERSAOPIXELMM || 3)) - 2,
            height: 50,
            fill: null, //preenchimento
            stroke: 'red', //borda
            strokeWidth: 1,
            id: (idGlobal + 1),
            banda: -1,
        }

        itensTelaTemp.unshift(bandadetalhetemp);//insere no inicio

        let vetor = ordenaBandasVetor(itensTelaTemp);
        vetor = reposicionaBandas(vetor);

        setItensTela(vetor);
        setIdGlobal(idGlobal + 1);
    }

    const encontraBandaporPosicao = (posicaoObjeto = { x: 0, y: 0 }) => {

        var bandaEncontrada = null;
        itensTela.forEach((item) => {
            if ((item.tipo === 'banda-cabecalho') ||
                (item.tipo === 'banda-titulo') ||
                (item.tipo === 'banda-detalhe') ||
                (item.tipo === 'banda-sumario') ||
                (item.tipo === 'banda-rodape') ||
                (item.tipo === 'banda-grupo-cabecalho') ||
                (item.tipo === 'banda-grupo-rodape')

            ) {
                if ((posicaoObjeto.y >= item.y) && (posicaoObjeto.y <= (item.y + item.height))) {
                    bandaEncontrada = item;
                    return;
                }
            }

        })
        return bandaEncontrada;
    }

    const retornaBanda = (vetor, tipo) => {

        const resultado = vetor.filter((item) => item.tipo === tipo);

        if (resultado.length > 0) {
            return resultado[0];
        }

        return null;
    }

    const reposicionaYItens = (vetor, banda) => {

        for (let i = 0; i < vetor?.length; i++) {
            if (vetor[i].banda === banda?.id) {
                vetor[i].y = banda.y + vetor[i].y_banda;
            }
        }
    }

    const ordenaBandasVetor = (vetor) => {
        /*sempre que uma banda vai ser inserids o vetor é reordenado
        para ficar na sequencia correta das bandas
        Pela logica a sequencia completa seria

        cabecalho
        titulo
        banda-grupo-cabecalho 2
            banda-grupo-cabecalho 1
                detalhe
            banda-grupo-rodape 1
        banda-grupo-rodape 2
        sumario
        rodape*/

        let vetortemp = []
        const bandaCabecalho = retornaBanda(vetor, 'banda-cabecalho');
        if (bandaCabecalho) {
            vetortemp.push(bandaCabecalho);
        }

        const bandaTitulo = retornaBanda(vetor, 'banda-titulo');
        if (bandaTitulo) {
            vetortemp.push(bandaTitulo);
        }

        //cabecalhos de grupos
        //eles foram inseridos na sequencia correta no vetor
        const vetorgrupocabecalho = vetor.filter((item) => item.tipo === 'banda-grupo-cabecalho');
        if (vetorgrupocabecalho.length > 0) {
            vetortemp = [...vetortemp, ...vetorgrupocabecalho];
        }

        const bandaDetalhe = retornaBanda(vetor, 'banda-detalhe');
        if (bandaDetalhe) {
            vetortemp.push(bandaDetalhe);
        }

        //rodapes de grupos
        const vetorgruporodape = vetor.filter((item) => item.tipo === 'banda-grupo-rodape');
        if (vetorgruporodape.length > 0) {
            vetortemp = [...vetortemp, ...vetorgruporodape];
        }

        const bandaSumario = retornaBanda(vetor, 'banda-sumario');
        if (bandaSumario) {
            vetortemp.push(bandaSumario);
        }

        const bandaRodape = retornaBanda(vetor, 'banda-rodape');
        if (bandaRodape) {
            vetortemp.push(bandaRodape);
        }

        //copiando os demais itens que nao sao bandas na mesma ordem
        //que ele estiverem (por conta da posicao de camadas  na tela 'cima e baixo")
        const vetorsembandas = vetor.filter((item) => !(item.tipo.indexOf('banda-') >= 0));

        vetor = [...vetortemp, ...vetorsembandas];

        return vetor;
    }

    const reposicionaBandas = (vetor) => {

        //as bandas estao sempre nas primeiras posicoes do vetor e na sequencia correta de tela*/


        let ultimo_y = 0;

        for (let i = 0; i < vetor.length; i++) {

            if (vetor[i].tipo.indexOf('banda-') === 0) {
                vetor[i].y = ultimo_y + 2;
                ultimo_y = vetor[i].y + vetor[i].height;
                reposicionaYItens(vetor, vetor[i]);
            }
        }

        return vetor;
    }

    const handleClickNovoTexto = () => {

        //const itensTelaTemp = copiaProfundaObjetos(itensTela);

        const itensTelaTemp = itensTela.slice();

        //por padrao sempre adiciona o texto na posicao 50,50 nessa posicao pode ter:
        //banda cabecalho, titulo ou detalhe...
        //entao preciso encontrar a banda que esta no momento para poder adicionar
        //o texto vinculado a uma banda

        let bandaPai = encontraBandaporPosicao({ x: 50, y: 50 });

        if (!bandaPai) {
            MensagemErro('Nenhuma banda foi encontrada');
            return;
        }

        const texttemp = {
            tipo: 'text',

            texto: {
                tipo: { value: 'textosimples', label: 'Texto Simples' },
                text: `Texto${idGlobal + 1}`,
                fontSize: 12,
                autosize: true
            },

            width: 50,
            x: 50,
            y: 50,
            y_banda: 50,
            fill: 'black',
            banda: bandaPai.id,
            id: (idGlobal + 1),
        }

        setIdGlobal(idGlobal + 1);
        debugger;
        itensTelaTemp.push(texttemp);
        setItensTela(itensTelaTemp);
    }
    const handleClickNovoMemo = () => {

        //const itensTelaTemp = copiaProfundaObjetos(itensTela);

        const itensTelaTemp = itensTela.slice();

        //por padrao sempre adiciona o texto na posicao 50,50 nessa posicao pode ter:
        //banda cabecalho, titulo ou detalhe...
        //entao preciso encontrar a banda que esta no momento para poder adicionar
        //o texto vinculado a uma banda

        let bandaPai = encontraBandaporPosicao({ x: 50, y: 50 });

        if (!bandaPai) {
            MensagemErro('Nenhuma banda foi encontrada');
            return;
        }

        const memotemp = {
            tipo: 'memo',

            texto: {
                tipo: { value: 'textosimples', label: 'Texto Simples' },
                text: `Memo${idGlobal + 1}`,
                fontSize: 12,
                autosize: true
            },

            width: 50,
            x: 50,
            y: 50,
            y_banda: 50,
            fill: 'black',
            banda: bandaPai.id,
            id: (idGlobal + 1),
        }

        setIdGlobal(idGlobal + 1);
        debugger;
        itensTelaTemp.push(memotemp);
        setItensTela(itensTelaTemp);
    }
    const handleClickNovoFormatMemo = () => {

        //const itensTelaTemp = copiaProfundaObjetos(itensTela);

        const itensTelaTemp = itensTela.slice();

        //por padrao sempre adiciona o texto na posicao 50,50 nessa posicao pode ter:
        //banda cabecalho, titulo ou detalhe...
        //entao preciso encontrar a banda que esta no momento para poder adicionar
        //o texto vinculado a uma banda

        let bandaPai = encontraBandaporPosicao({ x: 50, y: 50 });

        if (!bandaPai) {
            MensagemErro('Nenhuma banda foi encontrada');
            return;
        }

        const memotemp = {
            tipo: 'formatmemo',

            texto: {
                tipo: { value: 'textosimples', label: 'Texto Simples' },
                text: `Memo${idGlobal + 1}`,
                fontSize: 12,
                autosize: true
            },

            width: 50,
            x: 50,
            y: 50,
            y_banda: 50,
            fill: 'black',
            banda: bandaPai.id,
            id: (idGlobal + 1),
        }

        setIdGlobal(idGlobal + 1);
        debugger;
        itensTelaTemp.push(memotemp);
        setItensTela(itensTelaTemp);
    }

    const handleClickNovoDBTexto = () => {

        //const itensTelaTemp = copiaProfundaObjetos(itensTela);

        const itensTelaTemp = itensTela.slice();

        //por padrao sempre adiciona o texto na posicao 50,50 nessa posicao pode ter:
        //banda cabecalho, titulo ou detalhe...
        //entao preciso encontrar a banda que esta no momento para poder adicionar
        //o texto vinculado a uma banda

        let bandaPai = encontraBandaporPosicao({ x: 50, y: 50 });

        if (!bandaPai) {
            MensagemErro('Nenhuma banda foi encontrada');
            return;
        }

        const dbtexttemp = {
            tipo: 'dbtext',

            texto: {
                text: `DBTexto${idGlobal + 1}`,
                dataset: '',
                field: '',
                fontSize: 12,
                autosize: true
            },

            width: 50,
            x: 50,
            y: 50,
            y_banda: 50,
            fill: 'black',
            banda: bandaPai.id,
            id: (idGlobal + 1),
        }

        setIdGlobal(idGlobal + 1);
        itensTelaTemp.push(dbtexttemp);
        setItensTela(itensTelaTemp);
    }

    const handleClickNovoDBMemo = () => {

        //const itensTelaTemp = copiaProfundaObjetos(itensTela);

        const itensTelaTemp = itensTela.slice();

        //por padrao sempre adiciona o texto na posicao 50,50 nessa posicao pode ter:
        //banda cabecalho, titulo ou detalhe...
        //entao preciso encontrar a banda que esta no momento para poder adicionar
        //o texto vinculado a uma banda

        let bandaPai = encontraBandaporPosicao({ x: 50, y: 50 });

        if (!bandaPai) {
            MensagemErro('Nenhuma banda foi encontrada');
            return;
        }

        const dbmemotemp = {
            tipo: 'dbmemo',

            texto: {
                text: `DBMemo${idGlobal + 1}`,
                dataset: '',
                field: '',
                fontSize: 12,
                autosize: true
            },

            width: 50,
            x: 50,
            y: 50,
            y_banda: 50,
            fill: 'black',
            banda: bandaPai.id,
            id: (idGlobal + 1),
        }

        setIdGlobal(idGlobal + 1);
        itensTelaTemp.push(dbmemotemp);
        setItensTela(itensTelaTemp);
    }

    const handleClickNovoDBCalc = () => {

        //const itensTelaTemp = copiaProfundaObjetos(itensTela);

        const itensTelaTemp = itensTela.slice();

        //por padrao sempre adiciona o texto na posicao 50,50 nessa posicao pode ter:
        //banda cabecalho, titulo ou detalhe...
        //entao preciso encontrar a banda que esta no momento para poder adicionar
        //o texto vinculado a uma banda

        let bandaPai = encontraBandaporPosicao({ x: 50, y: 50 });

        if (!bandaPai) {
            MensagemErro('Nenhuma banda foi encontrada');
            return;
        }

        const dbcalctemp = {
            tipo: 'dbcalc',

            texto: {
                text: `DBCalc${idGlobal + 1}`,
                dataset: '',
                field: '',
                fontSize: 12,
                autosize: true
            },

            width: 50,
            x: 50,
            y: 50,
            y_banda: 50,
            fill: 'black',
            banda: bandaPai.id,
            id: (idGlobal + 1),
        }

        setIdGlobal(idGlobal + 1);
        itensTelaTemp.push(dbcalctemp);
        setItensTela(itensTelaTemp);
    }

    const handleClickNovoVariavelLabel = () => {

        //const itensTelaTemp = copiaProfundaObjetos(itensTela);

        const itensTelaTemp = itensTela.slice();

        //por padrao sempre adiciona o texto na posicao 50,50 nessa posicao pode ter:
        //banda cabecalho, titulo ou detalhe...
        //entao preciso encontrar a banda que esta no momento para poder adicionar
        //o texto vinculado a uma banda

        let bandaPai = encontraBandaporPosicao({ x: 50, y: 50 });

        if (!bandaPai) {
            MensagemErro('Nenhuma banda foi encontrada');
            return;
        }

        const variaveltemp = {
            tipo: 'variavellabel',

            texto: {
                text: `Variavel${idGlobal + 1}`,
                variavel: '',
                fontSize: 12,
                autosize: true
            },

            width: 50,
            x: 50,
            y: 50,
            y_banda: 50,
            fill: 'black',
            banda: bandaPai.id,
            id: (idGlobal + 1),
        }

        setIdGlobal(idGlobal + 1);
        itensTelaTemp.push(variaveltemp);
        setItensTela(itensTelaTemp);
    }

    const handleClickNovoDesenho = () => {

        setOpenConfirmacao({
            open: true,
            message: 'Você perderá todos os dados. Certeza que deseja iniciar um novo desenho?',
            handleCancel: () => setOpenConfirmacao({ open: false }),
            handleConfirm: () => {
                setOpenConfirmacao({ open: false });
                novoDesenho();
            }
        });

    }

    const handleClickRecarregarDesenho = () => {
        setOpenConfirmacao({
            open: true,
            message: 'Você perderá todas as alterações não salvas. Certeza que deseja recarregar o último desenho salvo?',
            handleCancel: () => setOpenConfirmacao({ open: false }),
            handleConfirm: () => {
                setOpenConfirmacao({ open: false });
                buscaDesenho();
            }
        });
    }

    const novoDesenho = () => {
        const temp = itensTela.slice();
        setItensTelaBackup(temp);
        setItensTela([]);
        setIdGlobal(0);
        setConfRelatorio({
            FATORCONVERSAOPIXELMM: 3,
            alturafolha: 297,
            largurafolha: 210,
            format: 'a4',
            orientation: 'portrait',
            margemsuperior: 10,
            margeminferior: 10,
            nomedatasetrepeticao: 'dataset1'
        });
    }

    const handleRestauraBackup = () => {
        const temp = itensTelaBackup.slice();
        setItensTela(temp);
    }


    const handleClickDeletaItem = () => {

        let mostramensagem = false;
        let mostramensagembandadetalhe = false;

        if ((selectedId === null) && (multiplosSelecionados?.length === 0))
            return;

        const vetorExclusao = copiaProfundaObjetos(multiplosSelecionados);
        if (parseInt(selectedId, 10) >= 0)
            vetorExclusao.push(parseInt(selectedId, 10));

        vetorExclusao.forEach((indice) => {

            if (itensTela[indice].tipo.indexOf('banda') === 0) {
                mostramensagem = true;

                if (itensTela[indice].tipo.indexOf('banda-detalhe') === 0) {
                    mostramensagembandadetalhe = true;
                }

            }
        });

        if (mostramensagem) {

            let mensagemtemp = 'Ao excluir uma banda todos os itens vinculados a ela serão excluídos. Certeza que deseja continuar ?';

            if (mostramensagembandadetalhe) {
                mensagemtemp = 'Ao excluir uma banda DETALHE todos os itens vinculados a ela incluindo bandas de GRUPO serão excluídas. Certeza que deseja continuar ?';
            }

            setOpenConfirmacao({
                open: true,
                message: mensagemtemp,
                handleCancel: () => {
                    setOpenConfirmacao({ open: false });
                    setOpenModelo(false);
                },
                handleConfirm: deletaItem
            })
        }
        else deletaItem();
    }

    const deletaItem = () => {

        setOpenConfirmacao({ open: false });

        if ((selectedId === null) && (multiplosSelecionados?.length === 0))
            return;

        const vetorExclusao = copiaProfundaObjetos(multiplosSelecionados);
        if (parseInt(selectedId, 10) >= 0)
            vetorExclusao.push(parseInt(selectedId, 10));

        //a selecao é feita pelo indice no vetor, entao preciso pegar os IDS
        //para depois encontrar os "filhos" pelo ID e tmbm excluir

        const vetorIdsApagar = [];

        let temp = itensTela.slice();

        vetorExclusao.forEach((indice) => {
            if (indice >= 0) {
                vetorIdsApagar.push(temp[indice].id);
                if (temp[indice].tipo === 'banda-detalhe') {
                    //procurando se tem grupos, se tiver marca para excluir todos
                    let grupos = temp.filter((item) => ((item.tipo === 'banda-grupo-cabecalho') || (item.tipo === 'banda-grupo-rodape')))
                    grupos.forEach((itemgrupo) => {
                        vetorIdsApagar.push(itemgrupo.id);
                    })
                }
                //se for um cabecalho ou rodape do grupo preciso excluir o outro
                if (temp[indice].tipo.indexOf('banda-grupo') === 0) {
                    const idGrupo = temp[indice].idGrupo;
                    let grupos = temp.filter((item) => item.idGrupo === idGrupo);
                    grupos.forEach((itemgrupo) => {
                        vetorIdsApagar.push(itemgrupo.id);
                    })
                    //apagando os grupos da confRelatorio
                    if (confRelatorio.grupos) {
                        let grupos = confRelatorio.grupos.filter((item) => item.idGrupo !== idGrupo);
                        setConfRelatorio({ ...confRelatorio, grupos });
                    }
                }
            }
        });


        vetorIdsApagar.forEach((idexcluir) => {

            temp.forEach((itemaexcluir, index) => {
                if (itemaexcluir.id === idexcluir) {
                    temp.splice(index, 1);
                }
            })

        })

        vetorIdsApagar.forEach((idexcluir) => {
            //encontrado todos os "filhos" e apago
            temp = temp.filter(objeto => (objeto.banda !== idexcluir));
        });

        let novoVetor = reposicionaBandas(temp);

        selectShape(null);
        setMultiplosSelecionados([]);
        setItensTela(novoVetor);
    }

    const handleClickDuplicaItem = () => {

        if (!(parseInt(selectedId, 10) >= 0)) return;

        if (
            (itensTela[selectedId].tipo === 'banda-cabecalho') ||
            (itensTela[selectedId].tipo === 'banda-titulo') ||
            (itensTela[selectedId].tipo === 'banda-detalhe') ||
            (itensTela[selectedId].tipo === 'banda-sumario') ||
            (itensTela[selectedId].tipo === 'banda-rodape')
        ) {
            MensagemErro('Não é possível duplicar bandas');
            return;
        }

        const objCopia = copiaProfundaObjetos(itensTela);
        const itemCopia = copiaProfundaObjetos(itensTela[selectedId]);
        itemCopia.x = parseInt(itemCopia.x, 10) + 20;
        itemCopia.y = parseInt(itemCopia.y, 10) + 20;
        itemCopia.id = (idGlobal + 1);

        objCopia.push(itemCopia);
        setItensTela(objCopia);
        selectShape(objCopia.length - 1);
        setIdGlobal(idGlobal + 1);
    }

    const handleClickEnviarParaTras = () => {

        if (!(parseInt(selectedId, 10) >= 0)) return;

        const objCopia = copiaProfundaObjetos(itensTela);
        const itemCopia = copiaProfundaObjetos(itensTela[selectedId]);

        let indice = parseInt(selectedId, 10);
        objCopia.splice(indice, 1);

        objCopia.unshift(itemCopia)

        selectShape(0);
        setItensTela(objCopia);

    }

    const handleClickEnviarParaFrente = () => {

        if (!(parseInt(selectedId, 10) >= 0)) return;

        const objCopia = copiaProfundaObjetos(itensTela);
        const itemCopia = copiaProfundaObjetos(itensTela[selectedId]);

        let indice = parseInt(selectedId, 10);
        objCopia.splice(indice, 1);

        objCopia.push(itemCopia);
        selectShape(objCopia.length - 1);
        setItensTela(objCopia);

    }

    const handleClickMovimentaEsquerda = (deslocamento = 1) => {


        if ((selectedId === null) && (multiplosSelecionados.length === 0))
            return;

        let vetorSelecionados = copiaProfundaObjetos(multiplosSelecionados);

        if (parseInt(selectedId, 10) >= 0) {
            vetorSelecionados.push(selectedId);

            if (itensTela[selectedId].tipo.indexOf('banda-') >= 0)
                return;
        }

        const objCopia = copiaProfundaObjetos(itensTela);

        vetorSelecionados.forEach((indice) => {
            objCopia[indice].x = (objCopia[indice].x - deslocamento) > 0 ? objCopia[indice].x - deslocamento : objCopia[indice].x;
        });

        setItensTela(objCopia);
    }

    const handleClickMovimentaDireita = (deslocamento = 1) => {

        if ((selectedId === null) && (multiplosSelecionados.length === 0))
            return;

        let vetorSelecionados = copiaProfundaObjetos(multiplosSelecionados);

        if (parseInt(selectedId, 10) >= 0) {
            vetorSelecionados.push(selectedId);

            if (itensTela[selectedId].tipo.indexOf('banda-') >= 0)
                return;
        }

        const objCopia = copiaProfundaObjetos(itensTela);

        vetorSelecionados.forEach((indice) => {
            objCopia[indice].x = (objCopia[indice].x + deslocamento) < (confRelatorio.largurafolha * (confRelatorio.FATORCONVERSAOPIXELMM || 3)) ? (objCopia[indice].x + deslocamento) : objCopia[indice].x;
        });

        setItensTela(objCopia);
    }

    const reposicionaObjetoBanda = (objetoAtual) => {

        if (objetoAtual.tipo.indexOf('banda-') >= 0)
            return;

        let bandaPai = encontraBandaporPosicao({ x: objetoAtual.x, y: objetoAtual.y });

        if (!bandaPai) {
            MensagemErro('Nenhuma banda foi encontrada');
        }
        else {
            objetoAtual.banda = bandaPai.id;
            objetoAtual.y_banda = objetoAtual.y - bandaPai.y;
        }
    }

    const handleClickMovimentaCima = (deslocamento = 1) => {

        if ((selectedId === null) && (multiplosSelecionados.length === 0))
            return;

        let vetorSelecionados = copiaProfundaObjetos(multiplosSelecionados);

        if (parseInt(selectedId, 10) >= 0) {
            vetorSelecionados.push(selectedId);

            if (itensTela[selectedId].tipo.indexOf('banda-') >= 0)
                return;
        }


        const objCopia = copiaProfundaObjetos(itensTela);

        vetorSelecionados.forEach((indice, index) => {
            objCopia[indice].y = (objCopia[indice].y - deslocamento) > 0 ? objCopia[indice].y - deslocamento : objCopia[indice].y;
            reposicionaObjetoBanda(objCopia[indice]);
        });

        setItensTela(objCopia);
    }

    const handleClickMovimentaBaixo = (deslocamento = 1) => {

        if ((selectedId === null) && (multiplosSelecionados.length === 0))
            return;

        let vetorSelecionados = copiaProfundaObjetos(multiplosSelecionados);

        if (parseInt(selectedId, 10) >= 0) {
            vetorSelecionados.push(selectedId);

            if (itensTela[selectedId].tipo.indexOf('banda-') >= 0)
                return;
        }

        const objCopia = copiaProfundaObjetos(itensTela);

        vetorSelecionados.forEach((indice) => {
            objCopia[indice].y = (objCopia[indice].y + deslocamento) < (confRelatorio.alturafolha * (confRelatorio.FATORCONVERSAOPIXELMM || 3)) ? objCopia[indice].y + deslocamento : objCopia[indice].y;
            reposicionaObjetoBanda(objCopia[indice]);
        });
        setItensTela(objCopia);
    }

    const handleClickAlinhaparaCima = () => {

        if (multiplosSelecionados.length === 0)
            return;

        let vetorSelecionados = copiaProfundaObjetos(multiplosSelecionados);
        const objCopia = copiaProfundaObjetos(itensTela);


        vetorSelecionados.forEach((indice, index) => {
            if (index > 0) {
                objCopia[indice].y = objCopia[vetorSelecionados[0]].y;
                objCopia[indice].y_banda = objCopia[vetorSelecionados[0]].y_banda;
                reposicionaObjetoBanda(objCopia[indice]);
            }
        });
        setItensTela(objCopia);
    }

    const handleClickAlinhaparaEsquerda = () => {

        if (multiplosSelecionados.length === 0)
            return;

        let vetorSelecionados = copiaProfundaObjetos(multiplosSelecionados);
        const objCopia = copiaProfundaObjetos(itensTela);


        vetorSelecionados.forEach((indice, index) => {
            if (index > 0) {
                objCopia[indice].x = objCopia[vetorSelecionados[0]].x;

            }
        });
        setItensTela(objCopia);
    }

    const handleClickAlinhaparaDireita = () => {

        debugger;
        if (multiplosSelecionados.length === 0)
            return;

        let vetorSelecionados = copiaProfundaObjetos(multiplosSelecionados);
        const objCopia = copiaProfundaObjetos(itensTela);

        /*
        Preciso alinhar o "xfim"
        x0+width0 = xfim
        novox+width=xfim
        novox=xfim-width
        */

        vetorSelecionados.forEach((indice, index) => {
            if (index > 0) {
                const xfim = objCopia[vetorSelecionados[0]].x + objCopia[vetorSelecionados[0]].width;
                objCopia[indice].x = xfim - objCopia[vetorSelecionados[index]].width;
            }
        });
        setItensTela(objCopia);
    }

    const handleKeyDown = (e) => {

        e.preventDefault();

        if ((e.key === 'Shift') && (!shiftPressionado)) {
            setShiftPressionado(true);
        }


        if ((!(parseInt(selectedId, 10) >= 0)) && (multiplosSelecionados.length == 0))
            return;

        const teclasMapeadas = ['ArrowRight', 'ArrowLeft', 'ArrowUp', 'ArrowDown', 'Delete', 'Tab', 'Escape', 'd', 's', 'o'];
        if (teclasMapeadas.filter((item) => item === e.key).length === 0) {
            return;
        }

        const temp = itensTela.slice();

        let indice = parseInt(selectedId, 10);

        const deslocamento = e.ctrlKey ? 10 : 1;

        switch (e.key) {
            case 'ArrowRight':

                if ((indice >= 0) &&
                    (
                        (temp[indice].tipo === 'banda-cabecalho') ||
                        (temp[indice].tipo === 'banda-titulo') ||
                        (temp[indice].tipo === 'banda-detalhe') ||
                        (temp[indice].tipo === 'banda-sumario') ||
                        (temp[indice].tipo === 'banda-rodape')
                    )) {
                    return;
                }

                handleClickMovimentaDireita(deslocamento);
                return;

            case 'ArrowLeft':

                if ((indice >= 0) &&
                    (
                        (temp[indice].tipo === 'banda-cabecalho') ||
                        (temp[indice].tipo === 'banda-titulo') ||
                        (temp[indice].tipo === 'banda-detalhe') ||
                        (temp[indice].tipo === 'banda-sumario') ||
                        (temp[indice].tipo === 'banda-rodape')
                    )) {
                    return;
                }


                handleClickMovimentaEsquerda(deslocamento);
                return;

            case 'ArrowUp':

                if ((indice >= 0) &&
                    (
                        (temp[indice].tipo === 'banda-cabecalho') ||
                        (temp[indice].tipo === 'banda-titulo') ||
                        (temp[indice].tipo === 'banda-detalhe') ||
                        (temp[indice].tipo === 'banda-sumario') ||
                        (temp[indice].tipo === 'banda-rodape')
                    )) {
                    return;
                }

                if (e.shiftKey) {
                    //fazer o "aumentar" ou diminuir de tamanho de acordo com o tipo

                }

                handleClickMovimentaCima(deslocamento);
                return;

            case 'ArrowDown':

                if ((indice >= 0) &&
                    (
                        (temp[indice].tipo === 'banda-cabecalho') ||
                        (temp[indice].tipo === 'banda-titulo') ||
                        (temp[indice].tipo === 'banda-detalhe') ||
                        (temp[indice].tipo === 'banda-sumario') ||
                        (temp[indice].tipo === 'banda-rodape')
                    )) {
                    return;
                }

                handleClickMovimentaBaixo(deslocamento);
                return;

            case 'Delete':

                handleClickDeletaItem();//por conta das bandas que precisa apagar os elementos dela
                return;

            case 'Escape':
                selectShape(null);
                return;

            case 'Tab':

                if (e.shiftKey) {
                    if (indice > 0) {
                        indice--;
                    }
                    else indice = itensTela.length - 1;
                }
                else {
                    if (indice < (itensTela.length - 1)) {
                        indice++;
                    }
                    else indice = 0;
                }

                selectShape(indice);

                return;

            case 'd':

                if (e.ctrlKey) {
                    handleClickDuplicaItem();
                    return;
                }

                return;

            case 's':

                if (e.ctrlKey) {
                    handleClickGravarRelatorio();
                    return;
                }

                return;

            case 'o':

                if (e.ctrlKey) {
                    handleClickRecarregarDesenho();
                    return;
                }


                return;

            default:
                break;
        }
    }

    const handleKeyUp = (e) => {
        e.preventDefault();
        if (e.key === 'Shift') {
            setShiftPressionado(false);
        }
    }

    const handleClickNovoObjeto = () => {
        switch (tipoSelecionado?.tipo) {
            case 'banda-cabecalho':
                handleClickNovoBandaCabecalho();
                break;
            case 'banda-titulo':
                handleClickNovoBandaTitulo();
                break;
            case 'banda-detalhe':
                handleClickNovoBandaDetalhe();
                break;
            case 'banda-sumario':
                handleClickNovoBandaSumario();
                break;
            case 'banda-rodape':
                handleClickNovoBandaRodape();
                break;
            case 'banda-grupo':
                handleClickNovoBandaGrupo();
                break;
            case 'rect':
                handleClickNovoRetangulo();
                break;
            case 'circle':
                handleClickNovoCirculo();
                break;
            case 'text':
                handleClickNovoTexto();
                break;
            case 'dbtext':
                handleClickNovoDBTexto();
                break;
            case 'dbmemo':
                handleClickNovoDBMemo();
                break;
            case 'memo':
                handleClickNovoMemo();
                break;
            case 'formatmemo':
                handleClickNovoFormatMemo();
                break;
            case 'dbcalc':
                handleClickNovoDBCalc();
                break;
            case 'variavellabel':
                handleClickNovoVariavelLabel();
                break;
            case 'linhahorizontal':
                handleClickNovaLinhaHorizontal();
                break;
            case 'linhavertical':
                handleClickNovaLinhaVertical();
                break;
            case 'imagem':
                handleClickNovaImagem();
                break;
            case 'dbimagem':
                handleClickNovaDbImagem();
                break;
            default:
                break;
        }
    }

    function encontrarMaiorId(objeto) {
        let maiorId = -1; // Inicializa com um valor que garante que qualquer "id" seja maior

        function percorrerElementos(elemento) {
            if (elemento && typeof elemento === 'object') {
                if ('id' in elemento) {
                    maiorId = Math.max(maiorId, elemento.id);
                }

                for (let chave in elemento) {
                    percorrerElementos(elemento[chave]);
                }

                if (Array.isArray(elemento)) {
                    elemento.forEach(percorrerElementos);
                }
            }
        }

        percorrerElementos(objeto);

        return maiorId;
    }

    const buscaDesenhoRelatorio = async (somenteModelo = false) => {

        if (!(parseInt(relatorio_id, 10) >= 0)) {
            return;
        }

        try {
            const url = `${rotafechada()}relatorios_personalizados/carregarelatorio/${relatorio_id}`;
            const retorno = await api.get(encodeURI(url));
            const { data } = retorno.data;

            let itensTelaTemp = [];

            if ((data.length > 0) && (data[0].arquivo_json?.itensTela)) {
                itensTelaTemp = copiaProfundaObjetos(data[0].arquivo_json.itensTela);
            }
            if (data[0].arquivo_json?.conf) {
                console.log(data[0].arquivo_json.conf);
                //nos primeiros relatorios esse configurador nao tinha algumas propriedades entao preencho com o default que é o A4

                if (!('alturafolha' in data[0].arquivo_json.conf) || !(parseInt(data[0].arquivo_json?.conf, 10) > 0)) {
                    data[0].arquivo_json.conf.alturafolha = 297;
                }
                if (!('largurafolha' in data[0].arquivo_json.conf)) {
                    data[0].arquivo_json.conf.largurafolha = 210;
                }
                if (!('format' in data[0].arquivo_json.conf)) {
                    data[0].arquivo_json.conf.format = 'a4';
                }

                if (!('orientation' in data[0].arquivo_json.conf)) {
                    data[0].arquivo_json.conf.orientation = 'portrait';
                }

                if (!('margemsuperior' in data[0].arquivo_json.conf) || !(parseInt(data[0].arquivo_json?.margemsuperior, 10) > 0)) {
                    data[0].arquivo_json.conf.margemsuperior = '10';
                }

                if (!('margeminferior' in data[0].arquivo_json.conf) || !(parseInt(data[0].arquivo_json?.margeminferior, 10) > 0)) {
                    data[0].arquivo_json.conf.margeminferior = '10';
                }

                if (!('(confRelatorio.FATORCONVERSAOPIXELMM||3)' in data[0].arquivo_json.conf) || !(parseInt(data[0].arquivo_json?.(confRelatorio.FATORCONVERSAOPIXELMM || 3), 10) > 0)) {
                    data[0].arquivo_json.conf.FATORCONVERSAOPIXELMM = 3;
                }

                setConfRelatorio(data[0].arquivo_json.conf);
            }

            setItensTelaModelo(itensTelaTemp);
            setItensTela(itensTelaTemp);
            setNomeRelatorio(data[0].nome);
            //ajustando o ID para nao dar conflito de IDS

            let maiorId = encontrarMaiorId(itensTelaTemp);

            if (parseInt(maiorId, 10) > 0) {
                setIdGlobal(maiorId + 1);
            }

            if ((data.length > 0) && (!data[0].arquivo_json)) {
                novoDesenho();
            }
        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    }

    const handleClickGravarRelatorio = async () => {

        if (!(parseInt(relatorio_id, 10) >= 0)) {
            MensagemErro('Não é possível gravar sem um relatório vinculado')
            return;
        }

        //para nao sobrecarregar o BD é retirado a imagembase64 do vetor que sera guardado na tabela
        //a imagem foi enviada ao servidor e sempre impressa pelo nome
        const temp = copiaProfundaObjetos(itensTela);

        temp.forEach((item) => {
            if (item.tipo === 'imagem') {
                item.imagembase64 = '';
            }
        })

        const dados = {
            empresa_id: sessaolocal.empresa_id || null,
            nome: nomeRelatorio,
            arquivo_json: {
                conf: confRelatorio,
                itensTela: temp
            }

        };

        try {
            MostraTelaEspera('Aguarde a gravação');
            let url;
            if (parseInt(relatorio_id, 10) > 0) {
                url = `${rotafechada()}relatorios_personalizados/${relatorio_id}`;
                const retorno = await api.put(url, dados);
            }

            FechaTelaEspera();
            MensagemAviso('Gravado com Sucesso!');
        }
        catch (error) {
            FechaTelaEspera();
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    }

    const buscaDesenho = async () => {
        if (parseInt(relatorio_id, 10) > 0) {
            buscaDesenhoRelatorio();
        }
    }

    const configuraDatasets = () => {


        if (!datasets) {
            setFieldsDbLabel([]);
            return
        };


        const fieldsDbLabelsTemp = [];

        if (datasets && typeof datasets === 'object') {
            //dentro do datasets pode ter multiplos dataset
            //que sao objetos com um campo "data"
            let indexdatasets = -1;
            for (let chave in datasets) {
                indexdatasets++;

                if (datasets[chave] && typeof datasets[chave] === 'object') {
                    if ((datasets[chave].data) && (Array.isArray(datasets[chave].data))) {
                        if (datasets[chave].data.length > 0) {
                            //pego apenas os campos da posicao "0"
                            // console.log('campos do dataset ' + chave);
                            for (let campo in datasets[chave].data[0]) {
                                fieldsDbLabelsTemp.push({
                                    repeticao: indexdatasets === 0 ? 'S' : 'N',//o primeiro dataset é sempre o repeticao
                                    dataset: chave,
                                    value: campo,
                                    label: campo
                                })
                            }

                        }
                    }
                }
            }

            setFieldsDbLabel(fieldsDbLabelsTemp);

        }

    }

    useEffect(() => {
        buscaDesenho();
        configuraDatasets();
    }, [relatorio_id])


    function getMapRef() {

        //retorna um map (lista) a partir de um
        //useRef

        if (!itemsRef.current) {
            // Initialize the Map on first usage.
            itemsRef.current = new Map();
        }
        return itemsRef.current;
    }

    const handleClickRestauraModelo = async () => {
        setOpenConfirmacao({
            open: true,
            message: 'Todas as alterações serão perdidas! Certeza que deseja restaurar o projeto Original?',
            handleCancel: () => {
                setOpenConfirmacao({ open: false });
                setOpenModelo(false);
            },
            handleConfirm: restauraModelo
        })
    }

    const handleClickImportarDesenho = async () => {
        setOpenConfirmacao({
            open: true,
            message: 'Todas as alterações serão perdidas! Certeza que deseja importar o desenho de outro projeto?',
            handleCancel: () => {
                setOpenConfirmacao({ open: false });
            },
            handleConfirm: () => {
                setOpenConfirmacao({ open: false });
                setOpenFiltroImportarProjeto({ open: true });
            }
        })
    }

    const handleClickImprimeRelatorio = async () => {

        try {

            const temp = copiaProfundaObjetos(itensTela);

            temp.forEach((item) => {
                if (item.tipo === 'imagem') {
                    item.imagembase64 = '';
                }
            })
            let data = new Date()
            const offSet = data.getTimezoneOffset()
            const resultado = await api.post(`${rotafechada()}relatorios_personalizados/imprimir/${relatorio_id}`, {
                data: {
                    conf: confRelatorio,
                    itensRelatorio: temp,
                    relatorio_id,
                    filtros,
                    timeoffset: offSet
                },
                responseType: 'arraybuffer'
            });

            const file = new Blob([resultado.data], { type: 'application/pdf' });

            // if (downloadArquivo) {
            //   var link = document.createElement('a');
            //   link.href = window.URL.createObjectURL(file);
            //   link.download = `proposta_${vendaAtual}.pdf`;
            //   link.click();
            //   link.remove();
            // } else {
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);

            // }
        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`${msg}`);
        }

    }

    const importarProjeto = async (projeto_id_copiar) => {

        // setOpenFiltroImportarProjeto({ open: false });

        // if (!(parseInt(projeto_id_copiar, 10) >= 0)) {
        //     return;
        // }

        // try {
        //     const url = `${rotafechada()}projetos?id=${projeto_id_copiar}&retornadesenhotempera=S`;
        //     const retorno = await api.get(encodeURI(url));
        //     const { data } = retorno.data;

        //     let itensTelaTemp = [];
        //     if ((data.length > 0) && (data[0].desenho_tempera)) {
        //         itensTelaTemp = copiaProfundaObjetos(data[0].desenho_tempera);
        //     }

        //     setItensTela(itensTelaTemp);
        //     MensagemAviso('Desenho Importado, grave as alterações!')

        // }
        // catch (error) {
        //     const msg = error.response?.data?.msg || error;
        //     MensagemErro(`Erro: ${msg}`);
        // }
    }

    const restauraModelo = async () => {
        const temp = copiaProfundaObjetos(itensTelaModelo);
        setItensTela(temp);
        setOpenModelo(false);
        setOpenConfirmacao({ open: false });
        MensagemAviso('Grave as alterações')
    }

    const handleClickConfiguracaoGeralRelatorio = async () => {
        setOpenConfiguracoesGerais(true);
    }

    return (
        <Box>
            <FiltroImportarProjeto
                open={openFiltroImportarProjeto.open || false}
                setOpen={setOpenFiltroImportarProjeto}
                handleOk={importarProjeto}
            />

            <DialogoConfirma
                open={openConfirmacao?.open || false}
                message={openConfirmacao?.message || ''}
                title={openConfirmacao?.title || 'Confirmação'}
                handleCancel={openConfirmacao.handleCancel}
                handleConfirm={openConfirmacao.handleConfirm}
            />

            <Dialog
                open={openModelo}
                onClose={() => setOpenModelo(false)}
            >
                <Box m={2}>
                    <MDButton onClick={handleClickRestauraModelo} color='info'>Restaurar projeto original</MDButton>
                </Box>
                <CamadaDesenho
                    selectedId={-1}
                    itensTela={itensTelaModelo}
                    setItensTela={() => { }}
                    selectShape={() => { }}
                    LARGURA={(confRelatorio.largurafolha * (confRelatorio.FATORCONVERSAOPIXELMM || 3))}
                    ALTURA={(confRelatorio.alturafolha * (confRelatorio.FATORCONVERSAOPIXELMM || 3))}
                    stageRef={refDesenhoModelo}
                />


            </Dialog>

            <PropriedadesGerais
                open={openConfiguracoesGerais}
                setOpen={setOpenConfiguracoesGerais}
                confRelatorio={confRelatorio}

                setConfRelatorio={setConfRelatorio}
                itensTela={itensTela}
                setItensTela={setItensTela}
                FATORCONVERSAOPIXELMM={(confRelatorio.FATORCONVERSAOPIXELMM || 3)}

            />

            {
                (!desabilitaBarras) &&
                <>
                    <BarraBotoes

                        handleClickNovoDesenho={handleClickNovoDesenho}
                        handleClickGravarRelatorio={handleClickGravarRelatorio}

                        handleClickRecarregarDesenho={handleClickRecarregarDesenho}
                        nomeRelatorio={nomeRelatorio}
                        relatorio_id={relatorio_id}
                        handleClickImportarDesenho={handleClickImportarDesenho}
                        handleClickImprimeRelatorio={handleClickImprimeRelatorio}
                        handleClickConfiguracaoGeralRelatorio={handleClickConfiguracaoGeralRelatorio}

                    />

                    <BarraObjetosePropriedades
                        setTipoSelecionado={setTipoSelecionado}
                        selectedId={selectedId}
                        tipoSelecionado={tipoSelecionado}
                        selectShape={selectShape}
                        handleClickDeletaItem={handleClickDeletaItem}
                        setOpenPropriedades={setOpenPropriedades}
                        handleClickNovoObjeto={handleClickNovoObjeto}
                        handleClickDuplicaItem={handleClickDuplicaItem}
                        handleClickEnviarParaTras={handleClickEnviarParaTras}
                        handleClickEnviarParaFrente={handleClickEnviarParaFrente}

                        handleClickMovimentaEsquerda={handleClickMovimentaEsquerda}
                        handleClickMovimentaDireita={handleClickMovimentaDireita}
                        handleClickMovimentaCima={handleClickMovimentaCima}
                        handleClickMovimentaBaixo={handleClickMovimentaBaixo}
                        setMultiplosSelecionados={setMultiplosSelecionados}
                    />
                    {
                        multiplosSelecionados.length > 0 ?
                            <BarraMultiplosSelecionados
                                selectedId={selectedId}
                                selectShape={selectShape}
                                setMultiplosSelecionados={setMultiplosSelecionados}

                                handleClickDeletaItem={handleClickDeletaItem}
                                handleClickMovimentaEsquerda={handleClickMovimentaEsquerda}
                                handleClickMovimentaDireita={handleClickMovimentaDireita}
                                handleClickMovimentaCima={handleClickMovimentaCima}
                                handleClickMovimentaBaixo={handleClickMovimentaBaixo}

                                handleClickAlinhaparaCima={handleClickAlinhaparaCima}
                                handleClickAlinhaparaEsquerda={handleClickAlinhaparaEsquerda}
                                handleClickAlinhaparaDireita={handleClickAlinhaparaDireita}

                            />
                            : false
                    }

                </>
            }

            <Box display={'flex'}>
                <Box
                    backgroundColor={'#ffff'}
                    border={1}
                    borderColor={'#999'}
                    maxWidth={(confRelatorio.largurafolha * (confRelatorio.FATORCONVERSAOPIXELMM || 3))}
                    maxHeight={(confRelatorio.alturafolha * (confRelatorio.FATORCONVERSAOPIXELMM || 3))}
                > {/* Box do Desenho*/}
                    <div
                        tabIndex={1}
                        onKeyDown={handleKeyDown}
                        onKeyUp={handleKeyUp}
                        style={{ border: 'none' }}
                    >
                        <CamadaDesenho
                            encontraBandaporPosicao={encontraBandaporPosicao}
                            reposicionaBandas={reposicionaBandas}
                            selectedId={selectedId}
                            itensTela={itensTela}
                            setItensTela={setItensTela}
                            selectShape={selectShape}
                            LARGURA={(confRelatorio.largurafolha * (confRelatorio.FATORCONVERSAOPIXELMM || 3))}
                            ALTURA={(confRelatorio.alturafolha * (confRelatorio.FATORCONVERSAOPIXELMM || 3))}
                            opcoesFormatacao={opcoesFormatacao}
                            //stageRef={stageRef}

                            //o Ref é adicionado em uma lista
                            //uso o pedido_tempera_venda_projeto_id como indice
                            //caso seja um componente isolado no projeto
                            //esse pedido_tempera_venda_projeto_id=-1 entao tmbm funcionara
                            stageRef={(node) => {
                                const map = getMapRef();
                                if (node) {
                                    map.set(relatorio_id, node);
                                } else {
                                    map.delete(relatorio_id);
                                }
                            }}
                            shiftPressionado={shiftPressionado}
                            setShiftPressionado={setShiftPressionado}
                            multiplosSelecionados={multiplosSelecionados}
                            setMultiplosSelecionados={setMultiplosSelecionados}
                        />
                    </div>

                    <Box >
                        {
                            parseInt(selectedId, 10) >= 0 &&

                            <PropriedadesFigura
                                confRelatorio={confRelatorio}
                                setConfRelatorio={setConfRelatorio}
                                itensTela={itensTela}
                                setItensTela={setItensTela}
                                selectedId={selectedId}
                                open={openPropriedades}
                                setOpen={setOpenPropriedades}
                                fieldsDbLabel={fieldsDbLabel}
                                opcoesFormatacao={opcoesFormatacao}

                            />
                        }

                    </Box >
                </Box>
                <Box marginLeft={5} maxWidth={500} > {/* Mostra as propriedades do objeto selecionado */}
                    <PropriedadesObjetoSelecionado
                        itensTela={itensTela}
                        selectedId={selectedId}
                        setSelectedId={selectShape}
                        multiplosSelecionados={multiplosSelecionados}
                        setMultiplosSelecionados={setMultiplosSelecionados}
                        confRelatorio={confRelatorio}
                    />

                </Box>
            </Box>
        </Box>
    )
}

const PropriedadesObjetoSelecionado = (props) => {

    const { itensTela, selectedId, setSelectedId, multiplosSelecionados, setMultiplosSelecionados, confRelatorio } = props;
    const [objetos, setObjetos] = useState([]);


    useEffect(() => {
        const temp = [];
        itensTela.map((item, index) => {
            temp.push({
                value: index,
                label: `${item.tipo}-${item.id || ''}-${item.texto?.text || ''}`
            });
        })
        setObjetos(temp);
    }, [itensTela])


    return (
        <Box>
            <Box width={500}>
                {
                    multiplosSelecionados?.length === 0 ?
                        <Autocomplete
                            fullWidth
                            options={objetos}
                            renderInput={(params) => <TextField {...params} label="Escolha o objeto" />}
                            value={
                                ((selectedId >= 0) && (objetos.length > 0) && (selectedId < objetos.length)) ? objetos[selectedId]?.label || '' : ''
                            }
                            onChange={(event, newValue) => {
                                setSelectedId(newValue.value);
                                setMultiplosSelecionados([]);
                            }}
                        />
                        :
                        <>
                            <Typography>
                                Selecionados:
                                {
                                    multiplosSelecionados.map((item) => `${item}-`)
                                }
                            </Typography>
                        </>
                }
                {
                    selectedId
                }
            </Box>

            <ArvorePropriedades data={parseInt(selectedId, 10) >= 0 ? itensTela[selectedId] : confRelatorio} confRelatorio={confRelatorio} />
        </Box>
    )

}