/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 PRO React layouts
//import Analytics from "layouts/dashboards/analytics";
// import Sales from "layouts/dashboards/sales";
// import ProfileOverview from "layouts/pages/profile/profile-overview";
// import AllProjects from "layouts/pages/profile/all-projects";
// import NewUser from "layouts/pages/users/new-user";
// import Settings from "layouts/pages/account/settings";
// import Billing from "layouts/pages/account/billing";
// import Invoice from "layouts/pages/account/invoice";
// import Timeline from "layouts/pages/projects/timeline";
// import PricingPage from "layouts/pages/pricing-page";
// import Widgets from "layouts/pages/widgets";
// import RTL from "layouts/pages/rtl";
// import Charts from "layouts/pages/charts";
// import Notifications from "layouts/pages/notifications";
// import Kanban from "layouts/applications/kanban";
// import Wizard from "layouts/applications/wizard";
// import DataTables from "layouts/applications/data-tables";
// import Calendar from "layouts/applications/calendar";
// import NewProduct from "layouts/ecommerce/products/new-product";
// import EditProduct from "layouts/ecommerce/products/edit-product";
// import ProductPage from "layouts/ecommerce/products/product-page";
// import OrderList from "layouts/ecommerce/orders/order-list";
// import OrderDetails from "layouts/ecommerce/orders/order-details";
// import SignInBasic from "layouts/authentication/sign-in/basic";
// import SignInCover from "layouts/authentication/sign-in/cover";
// import SignInIllustration from "layouts/authentication/sign-in/illustration";
// import SignUpCover from "layouts/authentication/sign-up/cover";
// import ResetCover from "layouts/authentication/reset-password/cover";

import MDAvatar from "components/MDAvatar";
import Icon from "@mui/material/Icon";
import profilePicture from "assets/images/invictos.jpg";
import { useContext } from "react";
import AuthContext from "hooks/AuthContext";
import GruposProdutos from "pages/produtos/gruposprodutos";
import CentroCusto from "pages/financeiro/centrocusto";
import TiposProdutos from "pages/produtos/tiposprodutos";
import Servico from "pages/servicos/servico"
import GruposServicos from "pages/servicos/gruposservicos";
import Cores from "pages/produtos/cores";
import TabelaPrecos from "pages/produtos/tabelasprecos";
import UnidadesMedidas from "pages/produtos/unidadesmedidas";
import Produtos from "pages/produtos/produtos";
import Pessoas from "pages/pessoas/pessoas";
import MedidasProjetos from "pages/projetos/medidasprojetos";
import GruposProjetos from "pages/projetos/gruposprojetos";
import ComponentesProjetos from "pages/projetos/componentesprojetos";
import Projetos from "pages/projetos/projetos";
import Vendas from "pages/vendas/vendas";
import PossibilidadesProjetos from "pages/projetos/possibilidades";
import Usuarios from "pages/usuarios";
import PerfisAcessos from "pages/perfisacessos";
import RelatorioClausulas from "pages/relatorioclausulas";
import Parametros from "pages/parametros";
import Permissoes from "pages/permissoes";
import Empresa from "pages/Empresa";
import Admin from "pages/admin";
import AreadeTrabalho from "pages/areadetrabalho";
import Categoria from "pages/financeiro/categoria";
import Banco from "pages/financeiro/banco";
import Transferencia from "pages/financeiro/transferencia";
import ContasPagar from "pages/financeiro/contas/ContasPagar";
import ContasReceber from "pages/financeiro/contas/ContasReceber";
import Relatorios from "pages/relatorios/personalizados";
import DRE from "pages/relatorios/dre";
import GenRel from "pages/genrel";
import GenRelDesenho from "pages/genrel";
import RelatoriosPersonalizados from "pages/relatoriosPersonalizados";
import Retalhos from "pages/otimizacao/retalho";
import Descartes from "pages/otimizacao/descarte";
import PlanoCorte from "pages/otimizacao/planocorte";
import Otimizar from "pages/otimizacao/otimizar"
import DFC from "pages/relatorios/dfc";

const Logout = (props) => {

  const authContext = useContext(AuthContext);

  if (authContext.logout) {
    authContext.logout();
  }

  return <></>
}


const routes = [
  {
    type: "collapse",
    name: "",
    key: "profile",
    icon: <MDAvatar src={profilePicture} alt="" size="sm" />,
    collapse: [
      {
        name: "Meu Perfil",
        key: "empresa",
        route: "/empresa/cadastro",
        component: <Empresa />,
      },
      // {
      //   name: "My Profile",
      //   key: "my-profile",
      //   route: "/pages/profile/profile-overview",
      //   component: <ProfileOverview />,
      // },
      // {
      //   name: "Settings",
      //   key: "profile-settings",
      //   route: "/pages/account/settings",
      //   component: <Settings />,
      // },
      {
        name: "Sair",
        key: "sair",
        route: "/authentication/sign-in/basic",
        component: <Logout />,
      },
    ],
  },
  { type: "divider", key: "divider-0" },
  {
    type: "collapse",
    name: "Dashboards",
    key: "dashboards",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    collapse: [
      {
        name: "Desktop",
        key: "desktop",
        route: "/desktop",
        component: <AreadeTrabalho />
      },
    ]
  },
  // {
  //   type: "collapse",
  //   name: "Dashboards",
  //   key: "dashboards",
  //   icon: <Icon fontSize="medium">dashboard</Icon>,
  //   collapse: [
  //     // {
  //     //   name: "Analytics",
  //     //   key: "analytics",
  //     //   route: "/dashboards/analytics",
  //     //   component: <Analytics />,
  //     // },
  //     // {
  //     //   name: "Vendas",
  //     //   key: "vendas",
  //     //   route: "/dashboards/vendas",
  //     //   component: <Sales />,
  //     // },
  //   ],
  // },
  { type: "divider", key: "divider-1" },
  { type: "title", title: "Módulos", key: "title-modulos" },
  //ORÇAMENTOS/VENDAS
  {
    type: "collapse",
    name: "Orçamento/Venda",
    key: "vendas",
    icon: <Icon fontSize="medium">shopping_basket</Icon>,
    collapse: [
      {
        name: " Orçamento/Venda",
        key: "menu-vendas",
        route: "/vendas/vendas/:id",
        component: <Vendas />,
      }
    ]
  },
  //FINANCEIRO
  {
    type: "collapse",
    name: "Financeiro",
    key: "financeiro",
    icon: <Icon fontSize="medium">monetization_on_icon</Icon>,
    collapse: [
      {
        name: " • Contas a pagar",
        key: "apagar",
        route: "/financeiro/contas-pagar",
        component: <ContasPagar />,
      },
      {
        name: " • Contas a receber",
        key: "areceber",
        route: "/financeiro/contas-receber",
        component: <ContasReceber />,
      },
      {
        type: "collapse",
        name: "Bancos/Caixas",
        key: "bancos",
        collapse: [
          {
            name: " • Bancos e caixas",
            key: "banco",
            route: "/financeiro/banco",
            component: <Banco />,
          },
          {
            name: " • Transferência",
            key: "transferencia",
            route: "/financeiro/transferencia",
            component: <Transferencia />
          }

        ]
      },
      {
        type: "collapse",
        name: "Configurações",
        key: "configuracoes",
        collapse: [
          {
            name: " • Centro de custo",
            key: "centrocusto",
            route: "/financeiro/centro-custo",
            component: <CentroCusto />,
          },
          {
            name: " • Categorias",
            key: "categoria",
            route: "/financeiro/categoria",
            component: <Categoria />,
          },
        ]
      },
    ]
  },
  //OTIMIZAÇÃO DE BARRAS
  {
    type: "collapse",
    name: "Otimização",
    key: "otimizacao",
    icon: <Icon fontSize="medium">manage_history_icon</Icon>,
    collapse: [
      {
        name: " • Otimizar Barras",
        key: "otimizacao",
        route: "/otimizacao/otimizar-barras",
        component: <Otimizar />,
      },
      {
        type: "collapse",
        name: "Cadastros",
        key: "bancos",
        collapse: [
          {
            name: " • Plano de corte",
            key: "plano_corte",
            route: "/financeiro/plano_corte",
            component: <PlanoCorte />,
          },
          {
            name: " • Retalhos",
            key: "retalhos",
            route: "/otimizacao/retalhos",
            component: <Retalhos />,
          },
          {
            name: " • Descarte",
            key: "descarte",
            route: "/otimizacao/descarte",
            component: <Descartes />,
          },

        ]
      },
    ]
  },
  //RELATORIOS
  {
    type: "collapse",
    name: "Relatórios",
    key: "relatorios",
    icon: <Icon fontSize="medium">leaderboard</Icon>,
    collapse: [
      {
        name: " • Relatórios",
        key: "relatorios",
        route: "/relatorios",
        component: <Relatorios />,
      },
      {
        name: " • DRE",
        key: "dre",
        route: "/DRE",
        component: <DRE/>,
      },
      {
        name: " • DFC",
        key: "dfc",
        route: "/DFC",
        component: <DFC/>,
      },
    ]
  },
  { type: "divider", key: "divider-venda-cadastro" },
  { type: "title", title: "Cadastros", key: "title-cadastros" },
  //PESSOAS • 
  {
    type: "collapse",
    name: "Pessoas",
    key: "pessoas",
    icon: <Icon fontSize="medium">people</Icon>,
    collapse: [
      {
        name: " • Pessoas",
        key: "pessoas_cadastros",
        route: "/pessoas/pessoa/:id",
        component: <Pessoas />,
      }
    ]
  },
  //PROJETOS
  {
    type: "collapse",
    name: "Projetos",
    key: "projetos",
    icon: <Icon fontSize="medium">architecture</Icon>,
    collapse: [
      {
        name: " • Projetos",
        key: "projetos",
        route: "/projetos/projeto/:id",
        component: <Projetos />,

      },
      {
        name: "Cadastros",
        key: "projeto_cadastros",
        collapse: [
          {
            name: " • Grupos",
            key: "gruposprojetos",
            route: "/projetos/gruposprojetos",
            component: <GruposProjetos />,

          },
          {
            name: " • Medidas",
            key: "medidasprojetos",
            route: "/projetos/medidasprojetos",
            component: <MedidasProjetos />,

          },
          {
            name: " • Componentes",
            key: "componentesprojetos",
            route: "/projetos/componentesprojetos",
            component: <ComponentesProjetos />,

          },
          {
            name: " • Possibilidades",
            key: "possibilidadesprojetos",
            route: "/projetos/possibilidadesprojetos",
            component: <PossibilidadesProjetos />,

          },
        ]
      }
    ]
  },
  //PRODUTOS
  {
    type: "collapse",
    name: "Produtos",
    key: "produtos",
    icon: <Icon fontSize="medium">shopping_basket</Icon>,
    collapse: [
      {
        name: " • Produtos",
        key: "produtos",
        route: "/produtos/produto/:id",
        component: <Produtos />,
      },
      {
        name: "Cadastros",
        key: "produto_cadastros",
        collapse: [
          {
            name: " • Grupos",
            key: "gruposprodutos",
            route: "/produtos/grupos-produtos",
            component: <GruposProdutos />,

          },
          {
            name: " • Tipos",
            key: "tiposprodutos",
            route: "/produtos/tipos-produtos",
            component: <TiposProdutos />,

          }
          ,
          {
            name: " • Cores",
            key: "cores",
            route: "/produtos/cores",
            component: <Cores />,

          }
          ,
          // {
          //   name: "Tabelas de Preço",
          //   key: "tabelaprecos",
          //   route: "/produtos/tabela-precos",
          //   component: <TabelaPrecos />,

          // }
          ,
          {
            name: " •Un. de Medidas",
            key: "unidadesmedidas",
            route: "/produtos/unidades-medidas",
            component: <UnidadesMedidas />,

          }
          ,

        ]
      }

    ]
  },
  //SERVIÇOS
  {
    type: "collapse",
    name: "Serviços",
    key: "servicos",
    icon: <Icon fontSize="medium">storage</Icon>,
    collapse: [
      {
        name: " • Serviço",
        key: "servico",
        route: "/servicos/servico/:id",
        component: <Servico />,

      },
      {
        name: "Cadastros",
        key: "servico_cadastros",
        collapse: [
          {
            name: " • Grupos",
            key: "gruposservicos",
            route: "/servicos/grupos-servicos",
            component: <GruposServicos />,

          }
        ]
      }
    ]
  },
  //CONFIGURAÇÕES
  {
    type: "collapse",
    name: "Configurações",
    key: "configuracoes",
    icon: <Icon fontSize="medium">settings</Icon>,
    collapse: [

      {
        name: " • Usuários",
        key: "usuarios",
        route: "/usuarios",
        component: <Usuarios />,
      },
      {
        name: " • Perfis de Usuários",
        key: "perfis_acessos_cadastros",
        route: "/perfisacessos",
        component: <PerfisAcessos />,
      },
      {
        name: " • Cláusulas Contrato",
        key: "relatorio_clausulas",
        route: "/relatorio_clausulas",
        component: <RelatorioClausulas />,
      },
      {
        name: " • Parâmetros",
        key: "parametros",
        route: "/parametros/:id",
        component: <Parametros />,
      },
      {
        name: " • Permissões",
        key: "permissoes",
        route: "/permissoes/:id",
        component: <Permissoes />,
      },
      {
        name: " • Admin",
        key: "admin",
        route: "/admin",
        component: <Admin />,
      },
      // {
      //   name: " • Gerador de Relatórios",
      //   key: "genrel",
      //   route: "/genrel",
      //   component: <GenRelTabela />,
      // },
      {
        name: " • Config. Relatórios Personalizados",
        key: "relatoriosPersonalizados",
        route: "/relatorios-personalizados",
        component: <RelatoriosPersonalizados />,
      },
    ]
  },


  /*{ type: "title", title: "Pages", key: "title-pages" },
  {
    type: "collapse",
    name: "Pages",
    key: "pages",
    icon: <Icon fontSize="medium">image</Icon>,
    collapse: [
      {
        name: "Profile",
        key: "profile",
        collapse: [
          {
            name: "Profile Overview",
            key: "profile-overview",
            route: "/pages/profile/profile-overview",
            component: <ProfileOverview />,
          },
          {
            name: "All Projects",
            key: "all-projects",
            route: "/pages/profile/all-projects",
            component: <AllProjects />,
          },
        ],
      },
      {
        name: "Users",
        key: "users",
        collapse: [
          {
            name: "New User",
            key: "new-user",
            route: "/pages/users/new-user",
            component: <NewUser />,
          },
        ],
      },
      {
        name: "Account",
        key: "account",
        collapse: [
          {
            name: "Settings",
            key: "settings",
            route: "/pages/account/settings",
            component: <Settings />,
          },
          {
            name: "Billing",
            key: "billing",
            route: "/pages/account/billing",
            component: <Billing />,
          },
          {
            name: "Invoice",
            key: "invoice",
            route: "/pages/account/invoice",
            component: <Invoice />,
          },
        ],
      },
      {
        name: "Projects",
        key: "projects",
        collapse: [
          {
            name: "Timeline",
            key: "timeline",
            route: "/pages/projects/timeline",
            component: <Timeline />,
          },
        ],
      },
      {
        name: "Pricing Page",
        key: "pricing-page",
        route: "/pages/pricing-page",
        component: <PricingPage />,
      },
      { name: "RTL", key: "rtl", route: "/pages/rtl", component: <RTL /> },
      { name: "Widgets", key: "widgets", route: "/pages/widgets", component: <Widgets /> },
      { name: "Charts", key: "charts", route: "/pages/charts", component: <Charts /> },
      {
        name: "Notfications",
        key: "notifications",
        route: "/pages/notifications",
        component: <Notifications />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Applications",
    key: "applications",
    icon: <Icon fontSize="medium">apps</Icon>,
    collapse: [
      {
        name: "Kanban",
        key: "kanban",
        route: "/applications/kanban",
        component: <Kanban />,
      },
      {
        name: "Wizard",
        key: "wizard",
        route: "/applications/wizard",
        component: <Wizard />,
      },
      {
        name: "Data Tables",
        key: "data-tables",
        route: "/applications/data-tables",
        component: <DataTables />,
      },
      {
        name: "Calendar",
        key: "calendar",
        route: "/applications/calendar",
        component: <Calendar />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Ecommerce",
    key: "ecommerce",
    icon: <Icon fontSize="medium">shopping_basket</Icon>,
    collapse: [
      {
        name: "Products",
        key: "products",
        collapse: [
          {
            name: "New Product",
            key: "new-product",
            route: "/ecommerce/products/new-product",
            component: <NewProduct />,
          },
          {
            name: "Edit Product",
            key: "edit-product",
            route: "/ecommerce/products/edit-product",
            component: <EditProduct />,
          },
          {
            name: "Product Page",
            key: "product-page",
            route: "/ecommerce/products/product-page",
            component: <ProductPage />,
          },
        ],
      },
      {
        name: "Orders",
        key: "orders",
        collapse: [
          {
            name: "Order List",
            key: "order-list",
            route: "/ecommerce/orders/order-list",
            component: <OrderList />,
          },
          {
            name: "Order Details",
            key: "order-details",
            route: "/ecommerce/orders/order-details",
            component: <OrderDetails />,
          },
        ],
      },
    ],
  },
  {
    type: "collapse",
    name: "Authentication",
    key: "authentication",
    icon: <Icon fontSize="medium">content_paste</Icon>,
    collapse: [
      {
        name: "Sign In",
        key: "sign-in",
        collapse: [
          {
            name: "Basic",
            key: "basic",
            route: "/authentication/sign-in/basic",
            component: <SignInBasic />,
          },
          {
            name: "Cover",
            key: "cover",
            route: "/authentication/sign-in/cover",
            component: <SignInCover />,
          },
          {
            name: "Illustration",
            key: "illustration",
            route: "/authentication/sign-in/illustration",
            component: <SignInIllustration />,
          },
        ],
      },
      {
        name: "Sign Up",
        key: "sign-up",
        collapse: [
          {
            name: "Cover",
            key: "cover",
            route: "/authentication/sign-up/cover",
            component: <SignUpCover />,
          },
        ],
      },
      {
        name: "Reset Password",
        key: "reset-password",
        collapse: [
          {
            name: "Cover",
            key: "cover",
            route: "/authentication/reset-password/cover",
            component: <ResetCover />,
          },
        ],
      },
    ],
  },
  { type: "divider", key: "divider-2" },
  { type: "title", title: "Docs", key: "title-docs" },
  {
    type: "collapse",
    name: "Basic",
    key: "basic",
    icon: <Icon fontSize="medium">upcoming</Icon>,
    collapse: [
      {
        name: "Getting Started",
        key: "getting-started",
        collapse: [
          {
            name: "Overview",
            key: "overview",
            href: "https://www.creative-tim.com/learning-lab/react/overview/material-dashboard/",
          },
          {
            name: "License",
            key: "license",
            href: "https://www.creative-tim.com/learning-lab/react/license/material-dashboard/",
          },
          {
            name: "Quick Start",
            key: "quick-start",
            href: "https://www.creative-tim.com/learning-lab/react/quick-start/material-dashboard/",
          },
          {
            name: "Build Tools",
            key: "build-tools",
            href: "https://www.creative-tim.com/learning-lab/react/build-tools/material-dashboard/",
          },
        ],
      },
      {
        name: "Foundation",
        key: "foundation",
        collapse: [
          {
            name: "Colors",
            key: "colors",
            href: "https://www.creative-tim.com/learning-lab/react/colors/material-dashboard/",
          },
          {
            name: "Grid",
            key: "grid",
            href: "https://www.creative-tim.com/learning-lab/react/grid/material-dashboard/",
          },
          {
            name: "Typography",
            key: "base-typography",
            href: "https://www.creative-tim.com/learning-lab/react/base-typography/material-dashboard/",
          },
          {
            name: "Borders",
            key: "borders",
            href: "https://www.creative-tim.com/learning-lab/react/borders/material-dashboard/",
          },
          {
            name: "Box Shadows",
            key: "box-shadows",
            href: "https://www.creative-tim.com/learning-lab/react/box-shadows/material-dashboard/",
          },
          {
            name: "Functions",
            key: "functions",
            href: "https://www.creative-tim.com/learning-lab/react/functions/material-dashboard/",
          },
          {
            name: "Routing System",
            key: "routing-system",
            href: "https://www.creative-tim.com/learning-lab/react/routing-system/material-dashboard/",
          },
        ],
      },
    ],
  },
  {
    type: "collapse",
    name: "Components",
    key: "components",
    icon: <Icon fontSize="medium">view_in_ar</Icon>,
    collapse: [
      {
        name: "Alerts",
        key: "alerts",
        href: "https://www.creative-tim.com/learning-lab/react/alerts/material-dashboard/",
      },
      {
        name: "Avatar",
        key: "avatar",
        href: "https://www.creative-tim.com/learning-lab/react/avatar/material-dashboard/",
      },
      {
        name: "Badge",
        key: "badge",
        href: "https://www.creative-tim.com/learning-lab/react/badge/material-dashboard/",
      },
      {
        name: "Badge Dot",
        key: "badge-dot",
        href: "https://www.creative-tim.com/learning-lab/react/badge-dot/material-dashboard/",
      },
      {
        name: "Box",
        key: "box",
        href: "https://www.creative-tim.com/learning-lab/react/box/material-dashboard/",
      },
      {
        name: "Buttons",
        key: "buttons",
        href: "https://www.creative-tim.com/learning-lab/react/buttons/material-dashboard/",
      },
      {
        name: "Date Picker",
        key: "date-picker",
        href: "https://www.creative-tim.com/learning-lab/react/datepicker/material-dashboard/",
      },
      {
        name: "Dropzone",
        key: "dropzone",
        href: "https://www.creative-tim.com/learning-lab/react/dropzone/material-dashboard/",
      },
      {
        name: "Editor",
        key: "editor",
        href: "https://www.creative-tim.com/learning-lab/react/quill/material-dashboard/",
      },
      {
        name: "Input",
        key: "input",
        href: "https://www.creative-tim.com/learning-lab/react/input/material-dashboard/",
      },
      {
        name: "Pagination",
        key: "pagination",
        href: "https://www.creative-tim.com/learning-lab/react/pagination/material-dashboard/",
      },
      {
        name: "Progress",
        key: "progress",
        href: "https://www.creative-tim.com/learning-lab/react/progress/material-dashboard/",
      },
      {
        name: "Snackbar",
        key: "snackbar",
        href: "https://www.creative-tim.com/learning-lab/react/snackbar/material-dashboard/",
      },
      {
        name: "Social Button",
        key: "social-button",
        href: "https://www.creative-tim.com/learning-lab/react/social-buttons/material-dashboard/",
      },
      {
        name: "Typography",
        key: "typography",
        href: "https://www.creative-tim.com/learning-lab/react/typography/material-dashboard/",
      },
    ],
  },
  {
    type: "collapse",
    name: "Change Log",
    key: "changelog",
    href: "https://github.com/creativetimofficial/ct-material-dashboard-pro-react/blob/main/CHANGELOG.md",
    icon: <Icon fontSize="medium">receipt_long</Icon>,
    noCollapse: true,
  },*/
];

export default routes;
