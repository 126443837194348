import { Box, Dialog, DialogTitle } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { Text, Transformer } from 'react-konva';
import { GlobalStyles } from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Quill from 'quill';



// Configura o attributor de tamanho para aceitar valores numéricos com "px"
const SizeStyle = Quill.import('attributors/style/size');
SizeStyle.whitelist = ['10px', '12px', '14px', '16px', '18px', '20px', '22px', '24px', '26px', '28px', '30px'];
Quill.register(SizeStyle, true);

// Configuração dos módulos (toolbar) do React Quill.
const modules = {
    toolbar: [
        ['bold', 'italic'],
        [{ size: SizeStyle.whitelist }],
        [{ color: [] }],
        [{ align: [] }] // Adiciona o dropdown de alinhamento
    ]
};

const formats = ['bold', 'italic', 'size', 'color', 'align'];

export const PropriedadesFormatMemo = (props) => {
    const { itensTela, setItensTela, selectedId, open, setOpen } = props;

    const handleChangeTextoSimples = (content, delta, source, editor) => {
        const temp = [...itensTela];
        temp[selectedId].texto.text = content; // content é uma string HTML
        setItensTela(temp);
    };

    return (
        <>
            <GlobalStyles
                styles={{
                    // Sobrescreve o seletor de tamanho do Quill para exibir o valor (usando ::before) - DIRETO NO CSS
                    '.ql-snow .ql-picker.ql-size .ql-picker-label::before, .ql-snow .ql-picker.ql-size .ql-picker-item::before': {
                        content: 'attr(data-value)',
                    },
                }}
            />
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                maxWidth="xl"
                fullWidth
            >
                <DialogTitle>{'Memo Formatado'}</DialogTitle>
                <Box m={2}>
                    {itensTela[selectedId]?.texto?.tipo?.value === 'textosimples' && (
                        <Box mt={2} width="100%">
                            <ReactQuill
                                theme="snow"
                                value={itensTela[selectedId].texto.text}
                                onChange={handleChangeTextoSimples}
                                modules={modules}
                                formats={formats}
                            />
                        </Box>
                    )}
                </Box>
            </Dialog>
        </>
    );
};

export const FormatMemo = ({ shapeProps, isSelected, onSelect, onChange, encontraBandaporPosicao }) => {
    const shapeRef = React.useRef();
    const trRef = React.useRef();

    React.useEffect(() => {
        if (isSelected) {
            trRef.current.nodes([shapeRef.current]);
            trRef.current.getLayer().batchDraw();
        }
    }, [isSelected]);

    return (
        <React.Fragment>
            <Text
                onClick={onSelect}
                onTap={onSelect}
                ref={shapeRef}
                {...shapeProps}
                {...shapeProps?.texto}
                fontFamily={shapeProps?.texto?.font || 'Arial'}
                wrap='char'
                draggable
                onDragEnd={(e) => {
                    onChange({
                        ...shapeProps,
                        x: parseInt(e.target.x(), 10),
                        y: parseInt(e.target.y(), 10),
                    });
                }}
                onTransformEnd={(e) => {
                    // transformer is changing scale of the node
                    // and NOT its width or height
                    // but in the store we have only width and height
                    // to match the data better we will reset scale on transform end
                    const node = shapeRef.current;
                    const scaleX = node.scaleX();
                    const scaleY = node.scaleY();
                    const escala = scaleX > scaleY ? scaleX : scaleY;

                    // we will reset it back
                    node.scaleX(1);
                    node.scaleY(1);

                    // onChange({
                    //     ...shapeProps,
                    //     x: parseInt(node.x(), 10),
                    //     y: parseInt(node.y(), 10),


                    //     texto: {
                    //         ...shapeProps?.texto,
                    //         fontSize: parseInt(node.attrs.fontSize * escala, 10),
                    //         rotation: node.rotation()
                    //     }

                    // });
                    //teste com aumento igual ao retanglo sem redimensionar a fonte
                    onChange({
                        ...shapeProps,
                        x: parseInt(node.x(), 10),
                        //y: parseInt(node.y(), 10),
                        width: parseInt(Math.max(5, node.width() * scaleX), 10),
                        height: parseInt(Math.max(node.height() * scaleY), 10),
                        rotation: node.rotation()
                    });
                }}
            />
            {isSelected && (
                <Transformer
                    ref={trRef}
                    boundBoxFunc={(oldBox, newBox) => {
                        // limit resize
                        if (newBox.width < 5 || newBox.height < 5) {
                            return oldBox;
                        }
                        return newBox;
                    }}
                />
            )}
        </React.Fragment>
    );
}
