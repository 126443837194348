import React, { useState, useEffect, useContext } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import MDBox from 'components/MDBox';
import api from "commons/api";
import { rotafechada } from "commons/urlconf";
import MessagesContext from "hooks/MessagesContext";
import { Grid, Icon, InputAdornment, Tooltip, Divider, FormControlLabel, Radio, Typography } from "@mui/material";
import FormField from "componentes/FormField";
import { AutoCompleteStyled } from "componentes/AutoCompleteStyled";
import AuthContext from 'hooks/AuthContext';
import { formataFloat, formatDate, formataValor } from "commons/utils";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Link } from "react-router-dom";
import HelpIcon from '@mui/icons-material/Help';
import RadioGroup from '@mui/material/RadioGroup';
import CancelIcon from '@mui/icons-material/Cancel';
import { ptBR } from "date-fns/locale";
import CurrencyInput from "componentes/MascaraMonetaria";
import DialogoConfirma from "componentes/DialogoConfirma";

export default function LancamentoEmLote(props) {

    const { open, handleCancel, title, tipo, recarregaTabela, setRecarregaTabela, listaParcelas } = props;

    const messageContext = useContext(MessagesContext);
    const { MensagemAviso, MensagemErro, MostraTelaEspera, FechaTelaEspera, MensagemAlerta } = messageContext;

    const authContext = useContext(AuthContext);
    const { getSessao } = authContext;
    const sessaolocal = getSessao();

    const [forma_pagamento_id, setForma_Pagamento_id] = useState({ value: -1, label: '<Escolha forma de pagamento>' });
    const [optionFormaPagamento, setOptionFormaPagamento] = useState([]);
    const [banco_id, setBanco_id] = useState({ value: -1, label: '<Escolha banco/caixa>' });
    const [optionBanco, setOptionBanco] = useState([
        { value: -1, label: "<Escolha uma conta de pagamento>" },
    ]);

    const [dialogoOpen, setDialogoOpen] = useState({ visible: false, message: `` })

    const [opcLancamento, setOpcLanacamento] = useState('quitacao');

    //Conta
    const [pessoa, setPessoa] = useState('');
    const [pessoa_id, setPessoa_id] = useState('');
    const [codigoReferencia, setCodigoReferencia] = useState('');
    const [dataCompetencia, setDataCompetencia] = useState('');
    const [categoria, setCategoria] = useState('');
    const [centro_custo, setCentro_custo] = useState('');
    const [descricaoConta, setDescricaoConta] = useState('');

    //Parcela
    const [saldo, setSaldo] = useState(0);
    const [vencimento, setVencimento] = useState('');
    const [parcela, setParcela] = useState(0);
    const [qtdParcela, setQtdParcela] = useState(0);
    const [descricaoParcela, setDescricaoParcela] = useState('');
    const [valorParcela, setValorParcela] = useState(0)
    const [observacaoParcela, setObservacaoParcela] = useState('');
    const [observacaoPagamento, setObservacaoPagamento] = useState('');
    const [situacao, setSituacao] = useState('');

    //Lancamento_parcelas
    const [dataPagamento, setDataPagamento] = useState(new Date());
    const [valorPago, setValorPago] = useState(0);
    const [juros, setJuros] = useState(0);
    const [multa, setMulta] = useState(0);
    const [desconto, setDesconto] = useState(0);
    const [tarifa, setTarifa] = useState(0);
    const [nsu, setNsu] = useState('');
    const [totalQuitado, setTotalQuitado] = useState(0);
    const [pago, setPago] = useState(false);



    const buscaFormaPagamento = async () => {
        try {
            const url = `${rotafechada()}forma_pagamento?order=nome&limit=-1`;
            //console.log("***URL forma de pagamento:", url);
            let retorno = await api.get(encodeURI(url));
            const { data } = retorno.data;
            let opcoesFormaPagamentoTemp = [{}];

            data.map((item) => {
                opcoesFormaPagamentoTemp.push({ value: item.id, label: item.nome });
            });
            setOptionFormaPagamento(opcoesFormaPagamentoTemp);

            return opcoesFormaPagamentoTemp;
        } catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    };

    const buscaBanco = async () => {
        try {
            const url = `${rotafechada()}banco?order=nome&limit=-1&ativo=S`;
            //console.log("***URL forma de pagamento:", url);
            let retorno = await api.get(encodeURI(url));
            const { data } = retorno.data;
            let opcoesBancoTemp = [{}];

            data.map((item) => {
                opcoesBancoTemp.push({ value: item.id, label: item.nome });
            });
            setOptionBanco(opcoesBancoTemp);

            return opcoesBancoTemp;
        } catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    };

    const handleClickGravarQuitacao = async (e) => {

        let msg = '';

        if (forma_pagamento_id.value < 0)
            msg = `${msg}| Escolha uma forma de pagamento `;

        if (banco_id.value < 0)
            msg = `${msg}| Escolha uma conta `;

        if (msg) {
            MensagemErro(msg);
            return;
        }


        try {
            MostraTelaEspera('Quitação em Lote...');

            const items = [];
            for (let i = 0; i < listaParcelas.length; i++) {
                items.push({
                    parcela_id: listaParcelas[i].id,
                    valor: listaParcelas[i].valor_lancamento,
                })
            }


            const dados = {
                empresa_id: sessaolocal.empresa_id || null,
                banco_id: banco_id.value || '', //nubank do gen
                forma_pagamento_id: forma_pagamento_id.value || '', //deposito/transferencia
                descricao: `[Quitação em Lote]-${observacaoPagamento}`,
                valor_total: somaLote,
                data: dataPagamento,
                items
            }

            console.log(dados);

            //Validação para não enviar parcelas pagas/recebidas com saldo 0 para o back e quebrar lá
            for (const item of dados.items) {
                if (item.valor == 0) {
                    MensagemAlerta('Existem parcelas com valor 0 informado!');
                    FechaTelaEspera();
                    return;
                }
            }

            await api.post(`${rotafechada()}parcelas_conta/quitarlote`, dados);

            MensagemAviso('Quitado com sucesso!');
            FechaTelaEspera();
            setRecarregaTabela(!recarregaTabela);
            handleCancel()
            limpaImputs()
        }
        catch (error) {
            FechaTelaEspera();
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }


    }

    const handleClickAvulso = async (e) => {
        let msg = '';

        if (msg) {
            MensagemErro(msg);
            return;
        }

        try {
            MostraTelaEspera('Desconto em Lote...');

            const items = [];
            for (let i = 0; i < listaParcelas.length; i++) {
                items.push({
                    parcela_id: listaParcelas[i].id,
                    valor: listaParcelas[i].valor_lancamento,
                })
            }


            const dados = {
                empresa_id: sessaolocal.empresa_id || null,
                descricao: `[Desconto em Lote]-${observacaoPagamento}`,
                valor_total: somaLote,
                data: dataPagamento,
                items
            }
            
            console.log(dados);

            //Validação para não enviar parcelas pagas/recebidas com saldo 0 para o back e quebrar lá
            for (const item of dados.items) {
                if (item.valor == 0) {
                    MensagemAlerta('Existem parcelas com valor 0 informado!');
                    FechaTelaEspera();
                    return;
                }
            }

            await api.post(`${rotafechada()}parcelas_conta/descontolote`, dados);

            MensagemAviso('Desconto aplicado com sucesso!');
            FechaTelaEspera();
            setRecarregaTabela(!recarregaTabela);
            handleCancel()
            limpaImputs()
        }
        catch (error) {
            FechaTelaEspera();
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    }

    const excluirEmLote = async (e) => {

        setDialogoOpen({ ...dialogoOpen, visible: false });


        let msg = '';

        if (msg) {
            MensagemErro(msg);
            return;
        }

        try {
            MostraTelaEspera('Exclusão em Lote...');

            const items = [];
            for (let i = 0; i < listaParcelas.length; i++) {
                items.push({
                    parcela_id: listaParcelas[i].id,
                    valor: listaParcelas[i].valor_lancamento,
                })
            }


            const dados = {
                empresa_id: sessaolocal.empresa_id || null,
                items
            }
            console.log(dados);

            await api.post(`${rotafechada()}parcelas_conta/excluirlote`, dados);

            MensagemAviso('Exclusão concluída com sucesso!');
            FechaTelaEspera();
            setRecarregaTabela(!recarregaTabela);
            handleCancel()
            limpaImputs()
        }
        catch (error) {
            FechaTelaEspera();
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    }

    const handleClickExclusaoLote = async () => {


        const message = 'Atenção: Você escolheu a opção de exclusão em LOTE. Certeza que deseja excluir todas as parcelas? Essa operação é irreversível!!';

        setDialogoOpen({ ...dialogoOpen, visible: true, message });
    }

    const handleChange = (event) => {
        setOpcLanacamento((event.target).value);
    };

    const limpaImputs = () => {

        //setCodigoAbrir('');

        setJuros(0);
        setMulta(0);
        setDesconto(0);

    }


    useEffect(() => {
        if (open === true) {
            buscaFormaPagamento();
            buscaBanco();

        }

    }, [open])

    // Soma os valores das linhas selecionadas
    const somaLote = listaParcelas.reduce((acc, linha) => acc + linha.valor_lancamento || 0, 0);

    return (
        <>

            <DialogoConfirma
                open={dialogoOpen.visible}
                title={'Confirma Exclusão'}
                color={'error'}
                message={dialogoOpen.message ? dialogoOpen.message : 'Certeza que deseja excluir?'}
                handleCancel={() => setDialogoOpen({ ...dialogoOpen, visible: false })}
                handleConfirm={excluirEmLote}
            />


            <Dialog
                open={open}
                onClose={() => {
                    handleCancel()
                    limpaImputs()
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullScreen
            >
                <DialogTitle id="alert-dialog-title" sx={{ padding: '5px' }}>
                    <MDBox width="100%">
                        <MDBox display="flex" justifyContent="space-between">
                            <MDTypography fontWeight='bold' ml={5} mt={1} variant='h4'>{title}</MDTypography>

                            <Tooltip title="Fechar (Esc)">
                                <MDButton size="large" sx={{ padding: 0 }} onClick={() => {
                                    handleCancel()
                                    limpaImputs()
                                }}>
                                    <Icon>close</Icon>
                                </MDButton>

                            </Tooltip>
                        </MDBox>
                    </MDBox>
                </DialogTitle>


                <DialogContent dividers sx={{ backgroundColor: "#f1f4f9", borderColor: '#7bb5e4' }}>
                    <DialogContentText id="alert-dialog-description">
                        {/* {message} */}

                        <MDBox ml={2} mr={2} pb={2} bgColor={'#fff'} sx={{ borderRadius: '3px' }}> {/* CONTA */}
                            <Grid container spacing={2} mt={1} pl={2} pr={2}>

                                <Grid item xs={12} sm={12}> {/* TITULO*/}
                                    <MDTypography variant='subtitle2' fontWeight="bold">{'Informações do Lote'}</MDTypography>
                                    <Divider
                                        sx={{ bgcolor: (theme) => theme.palette.divider }}
                                        style={{
                                            marginTop: 5,
                                            marginBottom: 10,
                                            border: "none",
                                            height: 2,
                                            margin: 0,
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={2}>{/*Quantidade de Registros*/}
                                    <MDBox width='100%' display='flex' alignItems='flex-start' flexDirection='column'>
                                        <MDTypography variant='caption' fontWeight="bold" pb={1}>{'Quantidade de Registros'}</MDTypography>
                                        <MDTypography variant='caption' >
                                            {listaParcelas?.length}
                                        </MDTypography>
                                    </MDBox>
                                </Grid>

                                <Grid item xs={12} sm={2}> {/* Valor do Lote */}
                                    <MDBox width='100%' display='flex' alignItems='flex-start' flexDirection='column'>
                                        <MDTypography variant='caption' fontWeight="bold" pb={1}>{'Valor do Lote'}</MDTypography>
                                        <MDTypography variant='caption' >{formataValor(somaLote || 0)}</MDTypography>
                                    </MDBox>
                                </Grid>



                            </Grid>

                        </MDBox>

                        <MDBox ml={2} mr={2} mt={2} pb={2} bgColor={'#fff'} sx={{ borderRadius: '3px' }}> {/* PAGAMENTO */}
                            <Grid container spacing={2} mt={1} pl={2} pr={2}>

                                <Grid item xs={12} sm={10.8}> {/* TITULO*/}
                                    <MDBox width='100%' display='flex' alignItems='flex-start' flexDirection='column'>
                                        <MDTypography variant='subtitle2' pb={1} fontWeight="bold">Opções de lançamento</MDTypography>
                                        {/* <MDTypography variant='caption'>{tipo == 'D' ? 'Você pode fazer o pagamento total ou parcial do saldo da parcela. O valor restante ficará em aberto.' : 'Você pode fazer o recebimento total ou parcial do saldo da parcela. O valor restante ficará em aberto.'}</MDTypography> */}
                                        <RadioGroup
                                            row
                                            value={opcLancamento}
                                            onChange={handleChange}
                                            name="radio-buttons-group"
                                            aria-label="radio-buttons-group-opc-lancamentos"

                                        >
                                            <FormControlLabel value="quitacao" control={<Radio />} label="Quitação" />
                                            <FormControlLabel value="descontototal" control={<Radio />} label="Desconto" />
                                            <FormControlLabel value="exclusao" control={<Radio />} label="Exclusão" />
                                        </RadioGroup>
                                    </MDBox>
                                </Grid>

                                {
                                    opcLancamento === 'quitacao' &&
                                    <>
                                        <Grid item xs={12} sm={12}> {/* TITULO*/}
                                            <MDBox width='100%' display='flex' alignItems='flex-start' flexDirection='column'>
                                                <MDTypography variant='subtitle2' pb={1} fontWeight="bold">{tipo === 'D' ? 'Informações do pagamento' : 'Informações do recebimento'}</MDTypography>
                                                <MDTypography variant='caption'>{tipo === 'D' ? 'Você pode fazer o pagamento total ou parcial do saldo da parcela. O valor restante ficará em aberto.' : 'Você pode fazer o recebimento total ou parcial do saldo da parcela. O valor restante ficará em aberto.'}</MDTypography>
                                            </MDBox>
                                            <Divider
                                                sx={{ bgcolor: (theme) => theme.palette.divider }}
                                                style={{
                                                    marginTop: 5,
                                                    marginBottom: 10,
                                                    border: "none",
                                                    height: 2,
                                                    margin: 0,
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={3}> {/* DATA_PAGAMENTO */}
                                            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                                                <DesktopDatePicker
                                                    disabled={pago}
                                                    maxDate={new Date()}
                                                    label='Data do pagamento'
                                                    inputFormat="dd/MM/yyyy"
                                                    value={dataPagamento}
                                                    onChange={(e) => setDataPagamento(e)}
                                                    renderInput={(params) => <FormField required {...params} />}
                                                />
                                            </LocalizationProvider>
                                        </Grid>
                                        <Grid item xs={12} sm={3}> {/* FORMA_PAGAMENTO */}
                                            <AutoCompleteStyled
                                                disabled={pago}
                                                //defaultValue={{ value: -1, label: '<Escolha forma de pagamento>' }}
                                                options={optionFormaPagamento}
                                                getOptionLabel={(option) => option.label || ""}
                                                renderInput={(params) => <FormField {...params} label="Forma de pagamento" />}
                                                isOptionEqualToValue={(option, value) => option.value === value.value}
                                                value={forma_pagamento_id}
                                                onChange={(event, newValue) => {
                                                    setForma_Pagamento_id(newValue);
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={3}> {/* BANCO*/}
                                            <AutoCompleteStyled
                                                disabled={pago}
                                                //defaultValue={{ value: -1, label: '<Escolha banco/caixa>' }}
                                                disableClearable //Retira o X (clear)
                                                options={optionBanco}
                                                getOptionLabel={(option) => option.label || ""}
                                                renderInput={(params) => <FormField {...params} required label="Conta de Pagamento:" />}
                                                isOptionEqualToValue={(option, value) => option.value === value.value}
                                                value={banco_id}
                                                onChange={(event, newValue) => {
                                                    setBanco_id(newValue);
                                                }}
                                            />
                                        </Grid>
                                    </>
                                }
                                {
                                    opcLancamento === 'descontototal' &&
                                    <>
                                        <Grid item xs={12} sm={12}> {/* TITULO*/}
                                            <MDBox width='100%' display='flex' alignItems='flex-start' flexDirection='column'>
                                                <MDTypography variant='subtitle2' pb={1} fontWeight="bold">{'Lançamento avulso'}</MDTypography>
                                            </MDBox>
                                            <Divider
                                                sx={{ bgcolor: (theme) => theme.palette.divider }}
                                                style={{
                                                    marginTop: 5,
                                                    marginBottom: 10,
                                                    border: "none",
                                                    height: 2,
                                                    margin: 0,
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={3}> {/* DATA_PAGAMENTO */}
                                            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                                                <DesktopDatePicker
                                                    disabled={pago}
                                                    maxDate={new Date()}
                                                    label='Data do Lançamento Avulso'
                                                    inputFormat="dd/MM/yyyy"
                                                    value={dataPagamento}
                                                    onChange={(e) => setDataPagamento(e)}
                                                    renderInput={(params) => <FormField required {...params} />}
                                                />
                                            </LocalizationProvider>
                                        </Grid>
                                    </>
                                }


                            </Grid>

                        </MDBox>

                        {
                            opcLancamento === 'quitacao' &&
                            <>
                                <MDBox ml={2} mr={2} mt={2} pb={2} bgColor={'#fff'} sx={{ borderRadius: '3px' }}> {/* OBSERVAÇÕES */}
                                    <Grid container spacing={2} mt={1} pl={2} pr={2}>

                                        <Grid item xs={12} sm={12}> {/* OBSERVAÇÃO*/}
                                            <FormField
                                                disabled={pago}
                                                type="text"
                                                label="Observação do Lançamento"
                                                value={observacaoPagamento}
                                                onChange={(e) => setObservacaoPagamento(e.target.value)}
                                                rows={3}
                                                multiline={true}
                                                InputLabelProps={{ shrink: true }} //evita o problema da label sobrepor o valor do input
                                                inputProps={{
                                                    disabled: pago
                                                }}
                                            />
                                        </Grid>
                                    </Grid>

                                </MDBox>
                            </>
                        }

                    </DialogContentText>
                </DialogContent>

                <DialogActions sx={{ padding: '7px', margin: 0.5 }}> {/*footer*/}
                    <MDBox width="100%">
                        <MDBox width="100%" display="flex" justifyContent="space-between">
                            <MDButton onClick={() => {
                                handleCancel()
                                limpaImputs()
                            }} variant="gradient" type="button" color="secondary">
                                voltar
                            </MDButton>
                            <MDButton
                                disabled={pago}
                                onClick={() => {
                                    opcLancamento === 'quitacao' ?
                                        handleClickGravarQuitacao()
                                        :
                                        (
                                            opcLancamento === 'descontototal' ?
                                                handleClickAvulso()
                                                :
                                                handleClickExclusaoLote()

                                        )
                                }}
                                variant="gradient"
                                type="submit"
                                //type="button" 
                                color="info">

                                {

                                    opcLancamento === 'quitacao' ? (tipo === 'D' ? 'confirmar pagamento' : 'confirmar recebimento') :
                                        opcLancamento === 'descontototal' ? 'Lançamento avulso' : 'Exclusão em Lote'
                                }
                            </MDButton>
                        </MDBox>
                    </MDBox>
                </DialogActions>
            </Dialog >
        </>
    );
}