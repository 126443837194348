
import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";

// @mui material components
//import Grid from "@mui/material/Grid";
// import { Collapse, Grid, Icon, Tooltip } from "@mui/material";
import { Box, Card, Tab, Tabs, TextField, Typography, Collapse, Grid, IconButton, Menu, MenuItem } from "@mui/material";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import InfoIcon from '@mui/icons-material/Info';
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDButton from "components/MDButton";
import { rotafechada } from 'commons/urlconf';
import api from 'commons/api';
import { formatDate } from "commons/utils";
import { ptBR } from "date-fns/locale";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import MessagesContext from "hooks/MessagesContext";
import AuthContext from 'hooks/AuthContext';
import { endOfYear, startOfYear, startOfMonth, endOfMonth } from 'date-fns'
import CancelIcon from '@mui/icons-material/Cancel';
import SettingsIcon from '@mui/icons-material/Settings';
import TabelaPadrao from "componentes/TabelaPadrao";
import { formataValor } from "commons/utils";
import DialogoValidaModulo from "componentes/ValidaModulo";
import { useNavigate } from "react-router-dom";
import packageJson from '../../../../package.json';
import MenuConfiguracaoModulos from "componentes/MenuConfiguracaoModulos";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DFCRealizado from "./Realizado";
import DFCPrevisto from "./Previsto";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.any,
    index: PropTypes.number,
    value: PropTypes.number,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 'none',
        //backgroundColor: '#1A73E8',
        //color: 'rgb(255, 255, 255)',
        //boxShadow: theme.shadows[1],
        //fontSize: 12,
    },
}));

function DFC() {

    const navigate = useNavigate();

    const messageContext = useContext(MessagesContext);
    const { MensagemErro, MostraTelaEspera, FechaTelaEspera } = messageContext;

    const [aba, setAba] = React.useState(-1);
    const [openMensagemModuloAdicional, setOpenMensagemModuloAdicional] = useState(false);
    const [moduloValidado, setModuloValidado] = useState(false);

    const [openMenuConfiguracao, setOpenMenuConfiguracao] = useState(false);
    const [currentTargetBotaoMenu, setCurrentTargetBotaoMenu] = useState(null);

    const handleChangeAba = (event, novaAba) => {
        setAba(novaAba);
    };

    const validaModulo = async () => {

        MostraTelaEspera('Validando acesso ao módulo');
        try {
            let url = `${rotafechada()}assinatura_modulos/validar?nome=${'FINANCEIRO'}`;
            await api.get(url);

            setModuloValidado(true)
            setAba(0);

        }
        catch (error) {
            setOpenMensagemModuloAdicional(true)
        }
        FechaTelaEspera();
    }

    useEffect(async () => {
        await validaModulo()
    }, [])

    return (
        <>
            <DialogoValidaModulo
                open={openMensagemModuloAdicional}
                title={'Módulo adicional'}
                message={'Módulo Financeiro não contratado, para mais informações entre em contato com nossa equipe de suporte!'}
                handleCancel={() => {
                    setOpenMensagemModuloAdicional(false);
                    navigate('/desktop');
                }}
                handleConfirm={() => {
                    setOpenMensagemModuloAdicional(false);
                    navigate('/desktop');
                    window.open(packageJson?.urlsuporte.replace('text', 'text=Tenho interesse em contratar o módulo Financeiro para o VidrosWeb📌 '))
                }
                }
            />

            <MenuConfiguracaoModulos
                open={openMenuConfiguracao}
                setOpen={setOpenMenuConfiguracao}
                nomeModulo='FINANCEIRO'
                currentTarget={currentTargetBotaoMenu}
            />



            <DashboardLayout>
                <DashboardNavbar />
                <MDBox mt={5} mb={9}>
                    <Grid container justifyContent="center">
                        <Grid item xs={12} lg={12}>
                            <MDBox mt={0} mb={0} textAlign="center">
                                <MDBox mb={1}>
                                    <MDTypography variant="h3" fontWeight="bold">
                                        {`DFC`}
                                    </MDTypography>
                                </MDBox>
                                {/* Botao de Configuracoes (Parametros e Permissoes) */}
                                <MDBox width="100%" display="flex" justifyContent="flex-end">
                                    <Tooltip title="Configurações e Permissões" placement="top">
                                        <IconButton
                                            onClick={(event) => {
                                                setCurrentTargetBotaoMenu(event.currentTarget);
                                                setOpenMenuConfiguracao(true);
                                            }
                                            }
                                        >
                                            <SettingsIcon />
                                        </IconButton>
                                    </Tooltip>
                                </MDBox>
                            </MDBox>
                            <Card>
                                <MDBox mt={1} mb={0} mx={2}>
                                    <Tabs value={aba} onChange={handleChangeAba} aria-label={`Aba de DFC`}>
                                        <Tab label="DFC Realizado" {...a11yProps(0)} />
                                        <Tab label="DFC Previsto" {...a11yProps(1)} />

                                    </Tabs>

                                </MDBox>

                                <TabPanel value={aba} index={-1}>{/* Módulo não validado*/}
                                </TabPanel>



                                <TabPanel value={aba} index={0}>{/* DFC Realizado */}
                                    <DFCRealizado
                                        open={(aba==0)&&(moduloValidado)}
                                    />
                                </TabPanel>


                                <TabPanel value={aba} index={1}>{/* DFC Previsto */}
                                    <DFCPrevisto
                                        open={(aba==1)&&(moduloValidado)}
                                    />
                                </TabPanel>
                            </Card>

                        </Grid>
                    </Grid>
                </MDBox>
                <Footer />
            </DashboardLayout>
        </>
    );
}

export default DFC;
