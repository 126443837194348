import React, { useState, useEffect, useContext } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import MDBox from 'components/MDBox';
import api from "commons/api";
import { rotafechada } from "commons/urlconf";
import MessagesContext from "hooks/MessagesContext";
import { Grid, Icon, InputAdornment, Tooltip, Divider, FormControlLabel, Radio, Typography } from "@mui/material";
import FormField from "componentes/FormField";
import { AutoCompleteStyled } from "componentes/AutoCompleteStyled";
import AuthContext from 'hooks/AuthContext';
import { formataFloat, formatDate, formataValor } from "commons/utils";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Link } from "react-router-dom";
import HelpIcon from '@mui/icons-material/Help';
import RadioGroup from '@mui/material/RadioGroup';
import CancelIcon from '@mui/icons-material/Cancel';
import { ptBR } from "date-fns/locale";
import CurrencyInput from "componentes/MascaraMonetaria";

export default function DialogoNovaConta(props) {

    const { open, handleCancel, title, setCodigoAbrir, codigoAbrir, tipo, recarregaTabela, setRecarregaTabela } = props;

    const messageContext = useContext(MessagesContext);
    const { MensagemAviso, MensagemErro, MostraTelaEspera, FechaTelaEspera, MensagemAlerta } = messageContext;

    const authContext = useContext(AuthContext);
    const { getSessao } = authContext;
    const sessaolocal = getSessao();

    const [forma_pagamento_id, setForma_Pagamento_id] = useState({ value: -1, label: '<Escolha forma de pagamento>' });
    const [optionFormaPagamento, setOptionFormaPagamento] = useState([]);
    const [banco_id, setBanco_id] = useState({ value: -1, label: '<Escolha banco/caixa>' });
    const [optionBanco, setOptionBanco] = useState([
        { value: -1, label: "<Escolha uma conta de pagamento>" },
    ]);

    const [opcLancamento, setOpcLanacamento] = useState('quitacao');

    //Conta
    const [pessoa, setPessoa] = useState('');
    const [pessoa_id, setPessoa_id] = useState('');
    const [codigoReferencia, setCodigoReferencia] = useState('');
    const [dataCompetencia, setDataCompetencia] = useState('');
    const [categoria, setCategoria] = useState('');
    const [centro_custo, setCentro_custo] = useState('');
    const [descricaoConta, setDescricaoConta] = useState('');

    //Parcela
    const [saldo, setSaldo] = useState(0);
    const [vencimento, setVencimento] = useState('');
    const [parcela, setParcela] = useState(0);
    const [qtdParcela, setQtdParcela] = useState(0);
    const [descricaoParcela, setDescricaoParcela] = useState('');
    const [valorParcela, setValorParcela] = useState(0)
    const [observacaoParcela, setObservacaoParcela] = useState('');
    const [observacaoPagamento, setObservacaoPagamento] = useState('');
    const [situacao, setSituacao] = useState('');

    //Lancamento_parcelas
    const [dataPagamento, setDataPagamento] = useState(new Date());
    const [valorPago, setValorPago] = useState(0);
    const [juros, setJuros] = useState(0);
    const [multa, setMulta] = useState(0);
    const [desconto, setDesconto] = useState(0);
    const [tarifa, setTarifa] = useState(0);
    const [nsu, setNsu] = useState('');
    const [totalQuitado, setTotalQuitado] = useState(0);
    const [pago, setPago] = useState(false);



    const buscaDados = async () => {

        let opcoesBancoTemp = [{}];

        //busco o banco antes porque alguns bancos nao podem sofrer quitacao
        try {
            const url = `${rotafechada()}banco?order=nome&limit=-1&ativo=S`;
            
            let retorno = await api.get(encodeURI(url));
            const { data } = retorno.data;
            data.map((item) => {
                //nao adiciona os cartoes de credito
                if ((item.tipo)&&(item.tipo!=='R')){
                    opcoesBancoTemp.push({ value: item.id, label: item.nome });
                }
            });

            setOptionBanco(opcoesBancoTemp);
        } catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }

        try {
            MostraTelaEspera()
            //console.log(props)
            const retorno = await api.get(`${rotafechada()}parcelas_conta/parcelafull?id=${props.codigoAbrir}`);
            const { dataConta, dataParcela, dataCountParcelas, dataLanParcelas, dataSaldo, dataSituacao } = retorno.data;

            //Conta
            setPessoa(dataConta[0].Pessoa.razao_social)
            setPessoa_id(dataConta[0].Pessoa.id)
            setCodigoReferencia(dataConta[0].codigo_referencia)
            let data_competencia_temp = new Date(dataConta[0].data_competencia)
            setDataCompetencia(formatDate(data_competencia_temp))
            setCategoria(dataConta[0].Categorium.nome)
            setCentro_custo(dataConta[0].Centro_Custo?.nome)
            setDescricaoConta(dataConta[0].nome)

            //Parcela
            setSituacao(dataSituacao)
            let vencimento_temp = new Date(dataParcela[0].vencimento)
            setVencimento(formatDate(vencimento_temp, 'DD/MM/YYYY'))
            setParcela(dataParcela[0].numero_parcela || 0)
            setDescricaoParcela(dataParcela[0].nome)
            setValorParcela(parseFloat(dataParcela[0].valor))
            setObservacaoParcela(dataParcela[0].observacao)
            setSaldo(dataSaldo || 0)

            setForma_Pagamento_id({ value: dataParcela[0].forma_pagamento_id ? dataParcela[0].forma_pagamento_id : -1, label: dataParcela[0].Forma_Pagamento?.nome ? dataParcela[0].Forma_Pagamento.nome : '<Escolha forma de pagamento>' })
            //console.log('opções de banco',optionBanco)

            //o banco adicionado na criacao da conta pode ser um "cartao de credito", porem nao é permitido quitar direto em um "cartao"
            //por isso procuro se o banco escolhido na criacao esta na listagem, caso contrato seto -1

            let bancoSelecionado=[];
            if ((dataParcela[0].banco_id)&&(opcoesBancoTemp)&&(opcoesBancoTemp.length>0)){
                bancoSelecionado=opcoesBancoTemp.filter((item)=>item.value===dataParcela[0].banco_id);
            }

            if (bancoSelecionado.length>0){
                setBanco_id({
                    value: dataParcela[0].banco_id,
                    label: dataParcela[0].Banco?.nome
                })
            }
            else{
                setBanco_id({
                    value: -1,
                    label: '<Escolha um banco>'
                })
            }

            //Lancamento_parcelas
            setQtdParcela(dataCountParcelas || 0)
            setValorPago(parseFloat(dataLanParcelas.total_quitacao ? (dataParcela[0].valor - (dataLanParcelas.total_quitacao)) : dataParcela[0].valor))
            setTotalQuitado(dataLanParcelas.total_quitacao || 0)

            setPago((parseFloat(dataLanParcelas.total_quitacao ? (dataParcela[0].valor - dataLanParcelas.total_quitacao) : dataParcela[0].valor)) === 0 ? true : false)
            FechaTelaEspera()
        }
        catch (error) {
            FechaTelaEspera();
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    }

    const buscaFormaPagamento = async () => {
        try {
            const url = `${rotafechada()}forma_pagamento?order=nome&limit=-1`;
            //console.log("***URL forma de pagamento:", url);
            let retorno = await api.get(encodeURI(url));
            const { data } = retorno.data;
            let opcoesFormaPagamentoTemp = [{}];

            data.map((item) => {
                opcoesFormaPagamentoTemp.push({ value: item.id, label: item.nome });
            });
            setOptionFormaPagamento(opcoesFormaPagamentoTemp);

            return opcoesFormaPagamentoTemp;
        } catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    };

    const handleClickGravar = async (e) => {

        let msg = ''
        if (valorPago === '' || valorPago <= 0)
            msg = msg + 'Valor pago, '

        if (banco_id?.value === -1 || null)
            msg = msg + 'Conta de Pagamento, '

        if (juros === '')
            msg = msg + 'Juros, '

        if (multa === '')
            msg = msg + 'Multa, '

        if (desconto === '')
            msg = msg + 'desconto, '


        else
            if (msg.length > 0)
                MensagemAlerta(`Campo(s) obrigatório(s): ${msg}`)
            else
                if ((parseFloat(valorPago) + parseFloat(juros || 0) + parseFloat(multa || 0) - parseFloat(tarifa || 0) - parseFloat(desconto || 0)) <= 0)
                    MensagemAlerta('O total pago deve ser superior a 0')
                else {
                    if (parseFloat(parseFloat(valorPago).toFixed(2)) > parseFloat((parseFloat(saldo)).toFixed(2)))
                        MensagemAlerta('O valor nominal da baixa não pode ser superior ao saldo da parcela')
                    else {

                        try {
                            MostraTelaEspera('Gravando a conta...');

                            const dados = {
                                empresa_id: sessaolocal.empresa_id || null,
                                parcela_conta_id: codigoAbrir,
                                tipo,
                                data: dataPagamento,
                                forma_pagamento_id: forma_pagamento_id?.value ? forma_pagamento_id?.value !== -1 ? forma_pagamento_id.value : null : null,
                                banco_id: banco_id?.value ? banco_id.value : null,
                                valorPago: parseFloat(valorPago) + parseFloat(juros || 0) + parseFloat(multa || 0) - parseFloat(tarifa || 0) - parseFloat(desconto || 0),
                                juros: juros || 0,
                                multa: multa || 0,
                                desconto: desconto || 0,
                                tarifa: tarifa || 0,
                                nsu: nsu || '',
                                observacaoPagamento
                            }
                            //console.log(dados);

                            await api.post(`${rotafechada()}lancamento_parcelas/full`, dados); //rota full de lançar pagamento

                            MensagemAviso('Gravado com sucesso!');
                            setRecarregaTabela(!recarregaTabela);
                            FechaTelaEspera();
                            handleCancel()
                            limpaImputs()
                        }
                        catch (error) {
                            FechaTelaEspera();
                            const msg = error.response?.data?.msg || error;
                            MensagemErro(`Erro: ${msg}`);
                        }
                    }
                }

    }

    const handleClickAvulso = async (e) => {
        let msg = ''
        if (juros === '')
            msg = msg + 'Juros, '

        if (multa === '')
            msg = msg + 'Multa, '

        if (desconto === '')
            msg = msg + 'desconto, '

        else
            if (msg.length > 0)
                MensagemAlerta(`Campo(s) obrigatório(s): ${msg}`)
            else {
                if (parseFloat(desconto) > (parseFloat(saldo)))
                    MensagemAlerta('O desconto não pode ser superior ao saldo da parcela')
                else {

                    try {
                        MostraTelaEspera('Gravando o conta...');

                        const dados = {
                            empresa_id: sessaolocal.empresa_id || null,
                            parcela_conta_id: codigoAbrir,
                            data: dataPagamento,
                            juros,
                            multa,
                            desconto
                        }
                        //console.log(dados);

                        await api.post(`${rotafechada()}lancamento_parcelas/lancamento_avulso`, dados);

                        MensagemAviso('Gravado com sucesso!');
                        FechaTelaEspera();
                        handleCancel()
                        limpaImputs()
                    }
                    catch (error) {
                        FechaTelaEspera();
                        const msg = error.response?.data?.msg || error;
                        MensagemErro(`Erro: ${msg}`);
                    }
                }
            }
    }

    const handleChange = (event) => {
        setOpcLanacamento((event.target).value);
    };

    const limpaImputs = () => {

        setCodigoAbrir('');

        setJuros(0);
        setMulta(0);
        setDesconto(0);

    }


    useEffect(() => {
        if (open === true) {
            //buscaBanco();//movido para dentro da buscaDados
            buscaDados(); 
            buscaFormaPagamento();
        }

    }, [open])

    return (
        <>
            <Dialog
                open={open}
                onClose={() => {
                    handleCancel()
                    limpaImputs()
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullScreen
            >
                <DialogTitle id="alert-dialog-title" sx={{ padding: '5px' }}>
                    <MDBox width="100%">
                        <MDBox display="flex" justifyContent="space-between">
                            <MDTypography fontWeight='bold' ml={5} mt={1} variant='h4'>{title}</MDTypography>

                            <Tooltip title="Fechar (Esc)">
                                <MDButton size="large" sx={{ padding: 0 }} onClick={() => {
                                    handleCancel()
                                    limpaImputs()
                                }}>
                                    <Icon>close</Icon>
                                </MDButton>

                            </Tooltip>
                        </MDBox>
                    </MDBox>
                </DialogTitle>


                <DialogContent dividers sx={{ backgroundColor: "#f1f4f9", borderColor: '#7bb5e4' }}>
                    <DialogContentText id="alert-dialog-description">
                        {/* {message} */}

                        <MDBox ml={2} mr={2} pb={2} bgColor={'#fff'} sx={{ borderRadius: '3px' }}> {/* CONTA */}
                            <Grid container spacing={2} mt={1} pl={2} pr={2}>

                                <Grid item xs={12} sm={12}> {/* TITULO*/}
                                    <MDTypography variant='subtitle2' fontWeight="bold">{'Informações da conta'}</MDTypography>
                                    <Divider
                                        sx={{ bgcolor: (theme) => theme.palette.divider }}
                                        style={{
                                            marginTop: 5,
                                            marginBottom: 10,
                                            border: "none",
                                            height: 2,
                                            margin: 0,
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={2}>{/*PESSOA*/}
                                    <MDBox width='100%' display='flex' alignItems='flex-start' flexDirection='column'>
                                        <MDTypography variant='caption' fontWeight="bold" pb={1}>{tipo === 'D' ? 'Fornecedor' : 'Cliente'}</MDTypography>
                                        <MDTypography variant='caption' >
                                            <Link to={`/pessoas/pessoa/${pessoa_id}`} style={{ textDecoration: "none", color: 'info' }}>{pessoa}</Link>
                                        </MDTypography>
                                    </MDBox>
                                </Grid>

                                <Grid item xs={12} sm={2}> {/* CODIGO_REFERENCIA */}
                                    <MDBox width='100%' display='flex' alignItems='flex-start' flexDirection='column'>
                                        <MDTypography variant='caption' fontWeight="bold" pb={1}>{'Código de referência'}</MDTypography>
                                        <MDTypography variant='caption' >{codigoReferencia}</MDTypography>
                                    </MDBox>
                                </Grid>

                                <Grid item xs={12} sm={2}> {/* DATA_COMPETENCIA */}
                                    <MDBox width='100%' display='flex' alignItems='flex-start' flexDirection='column'>
                                        <MDTypography variant='caption' fontWeight="bold" pb={1}>{'Data de competência'}</MDTypography>
                                        <MDTypography variant='caption' >{dataCompetencia}</MDTypography>
                                    </MDBox>
                                </Grid>

                                <Grid item xs={12} sm={2} >{/*CATEGORIA*/}
                                    <MDBox width='100%' display='flex' alignItems='flex-start' flexDirection='column'>
                                        <MDTypography variant='caption' fontWeight="bold" pb={1}>{'Categoria'}</MDTypography>
                                        <MDTypography variant='caption' >{categoria}</MDTypography>
                                    </MDBox>
                                </Grid>

                                <Grid item xs={12} sm={2}> {/*CENTRO_CUSTO*/}
                                    <MDBox width='100%' display='flex' alignItems='flex-start' flexDirection='column'>
                                        <MDTypography variant='caption' fontWeight="bold" pb={1}>{'Centro de custo'}</MDTypography>
                                        <MDTypography variant='caption' >{centro_custo}</MDTypography>
                                    </MDBox>
                                </Grid>

                                <Grid item xs={12} sm={2}> {/*DESCRIÇÃO_CONTA*/}
                                    <MDBox width='100%' display='flex' alignItems='flex-start' flexDirection='column'>
                                        <MDTypography variant='caption' fontWeight="bold" pb={1}>{'Descrição da conta'}</MDTypography>
                                        <MDTypography variant='caption' >{descricaoConta}</MDTypography>
                                    </MDBox>
                                </Grid>

                            </Grid>

                        </MDBox>

                        <MDBox ml={2} mr={2} mt={2} pb={2} bgColor={'#fff'} sx={{ borderRadius: '3px' }}> {/* PARCELA */}
                            <Grid container spacing={2} mt={1} pl={2} pr={2}>

                                <Grid item xs={12} sm={12}> {/* TITULO*/}
                                    <MDTypography variant='subtitle2' fontWeight="bold">{`Informações da parcela ${parcela}/${qtdParcela}`}</MDTypography>
                                    <Divider
                                        sx={{ bgcolor: (theme) => theme.palette.divider }}
                                        style={{
                                            marginTop: 5,
                                            marginBottom: 10,
                                            border: "none",
                                            height: 2,
                                            margin: 0,
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={2}>{/*VENCIMENTO*/}
                                    <MDBox width='100%' display='flex' alignItems='flex-start' flexDirection='column'>
                                        <MDTypography variant='caption' fontWeight="bold" pb={1}>{'Vencimento'}</MDTypography>
                                        <MDTypography variant='caption' >{vencimento}</MDTypography>
                                    </MDBox>
                                </Grid>

                                <Grid item xs={12} sm={2}> {/* PARCELA */}
                                    <MDBox width='100%' display='flex' alignItems='flex-start' flexDirection='column'>
                                        <MDTypography variant='caption' fontWeight="bold" pb={1}>{'Parcela'}</MDTypography>
                                        <MDTypography variant='caption' >{`${parcela}/${qtdParcela}`}</MDTypography>
                                    </MDBox>
                                </Grid>

                                <Grid item xs={12} sm={2}> {/* DESCRIÇÃO */}
                                    <MDBox width='100%' display='flex' alignItems='flex-start' flexDirection='column'>
                                        <MDTypography variant='caption' fontWeight="bold" pb={1}>{'Descrição'}</MDTypography>
                                        <MDTypography variant='caption' >{descricaoParcela}</MDTypography>
                                    </MDBox>
                                </Grid>

                                <Grid item xs={12} sm={2}> {/* OBSERVAÇÃO_PARCELA */}
                                    <MDBox width='100%' display='flex' alignItems='flex-start' flexDirection='column'>
                                        <MDTypography variant='caption' fontWeight="bold" pb={1}>{'Observação'}</MDTypography>
                                        <MDTypography variant='caption' >{observacaoParcela}</MDTypography>
                                    </MDBox>
                                </Grid>

                                <Grid item xs={12} sm={2}> {/* VALOR_PARCELA */}
                                    <MDBox width='100%' display='flex' alignItems='flex-end' flexDirection='column'>
                                        <MDTypography variant='caption' fontWeight="bold">{'Valor'}</MDTypography>
                                        <MDTypography fontWeight="bold" >{formataValor(valorParcela)}</MDTypography> {/*Valor da parcela + juros + multa - desconto*/}
                                    </MDBox>
                                </Grid>

                                <Grid item xs={12} sm={2}> {/* SALDO */}
                                    <MDBox width='100%' display='flex' alignItems='flex-end' flexDirection='column'>
                                        <MDTypography variant='caption' fontWeight="bold">{'Saldo'}</MDTypography>
                                        <MDTypography fontWeight="bold" >{formataValor(saldo)}</MDTypography> {/*Valor da parcela + juros + multa - desconto*/}
                                    </MDBox>
                                </Grid>

                            </Grid>

                        </MDBox>

                        <MDBox ml={2} mr={2} mt={2} pb={2} bgColor={'#fff'} sx={{ borderRadius: '3px' }}> {/* PAGAMENTO */}
                            <Grid container spacing={2} mt={1} pl={2} pr={2}>

                                <Grid item xs={12} sm={10.8}> {/* TITULO*/}
                                    <MDBox width='100%' display='flex' alignItems='flex-start' flexDirection='column'>
                                        <MDTypography variant='subtitle2' pb={1} fontWeight="bold">Opções de lançamento</MDTypography>
                                        {/* <MDTypography variant='caption'>{tipo == 'D' ? 'Você pode fazer o pagamento total ou parcial do saldo da parcela. O valor restante ficará em aberto.' : 'Você pode fazer o recebimento total ou parcial do saldo da parcela. O valor restante ficará em aberto.'}</MDTypography> */}
                                        <RadioGroup
                                            row
                                            value={opcLancamento}
                                            onChange={handleChange}
                                            name="radio-buttons-group"
                                            aria-label="radio-buttons-group-opc-lancamentos"

                                        >
                                            <FormControlLabel value="quitacao" control={<Radio />} label="Quitação" />
                                            <FormControlLabel value="avulso" control={<Radio />} label="Juros/Multa/Desconto" />
                                        </RadioGroup>
                                    </MDBox>
                                </Grid>
                                <Grid item xs={12} sm={1.2}> {/* SITUAÇÃO */}
                                    <MDBox width='100%'
                                        justifyContent="flex-end" flexDirection='row'>
                                        <MDBox
                                            display="flex"
                                            alignText={'center'}
                                            width="120px"
                                            justifyContent={'center'}
                                            sx={{ background: `${situacao === 'PAGO' ? '#bce2be' : situacao === 'PAGO PARCIAL' ? '#aecef7' : '#ffd59f'}` }}

                                            borderRadius="sm"
                                            p={0.5}
                                        >
                                            <MDTypography fontWeight="medium"
                                                variant="caption"
                                                textTransform="capitalize"
                                            >
                                                {
                                                    situacao === 'PAGO' && tipo === 'R' ? 'RECEBIDO' :
                                                        situacao === 'PAGO' && tipo === 'D' ? 'PAGO' :
                                                            situacao === 'PAGO PARCIAL' && tipo === 'R' ? 'RECEBIDO PARCIAL' :
                                                                situacao === 'PAGO PARCIAL' && tipo === 'D' ? 'PAGO PARCIAL' :
                                                                    situacao
                                                }
                                            </MDTypography>
                                        </MDBox>
                                    </MDBox>
                                </Grid>
                                {
                                    opcLancamento === 'quitacao' &&
                                    <>
                                        <Grid item xs={12} sm={12}> {/* TITULO*/}
                                            <MDBox width='100%' display='flex' alignItems='flex-start' flexDirection='column'>
                                                <MDTypography variant='subtitle2' pb={1} fontWeight="bold">{tipo === 'D' ? 'Informações do pagamento' : 'Informações do recebimento'}</MDTypography>
                                                <MDTypography variant='caption'>{tipo === 'D' ? 'Você pode fazer o pagamento total ou parcial do saldo da parcela. O valor restante ficará em aberto.' : 'Você pode fazer o recebimento total ou parcial do saldo da parcela. O valor restante ficará em aberto.'}</MDTypography>
                                            </MDBox>
                                            <Divider
                                                sx={{ bgcolor: (theme) => theme.palette.divider }}
                                                style={{
                                                    marginTop: 5,
                                                    marginBottom: 10,
                                                    border: "none",
                                                    height: 2,
                                                    margin: 0,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={3}>{/*VALOR_PARCELA*/}
                                            <FormField
                                                disabled
                                                required={true}
                                                type="number"
                                                label="Valor da parcela"
                                                value={parseFloat(formataFloat(valorParcela)).toFixed(2)}
                                                //onChange={(e) => setValor(e.target.value.replace(/[^.,0-9]/g, ''))}
                                                InputProps={{
                                                    startAdornment: <InputAdornment sx={{ width: '5%' }} position="start">R$</InputAdornment>,
                                                    disabled: true
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={3}> {/* DATA_PAGAMENTO */}
                                            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                                                <DesktopDatePicker
                                                    disabled={pago}
                                                    maxDate={new Date()}
                                                    label='Data do pagamento'
                                                    inputFormat="dd/MM/yyyy"
                                                    value={dataPagamento}
                                                    onChange={(e) => setDataPagamento(e)}
                                                    renderInput={(params) => <FormField required {...params} />}
                                                />
                                            </LocalizationProvider>
                                        </Grid>
                                        <Grid item xs={12} sm={3}> {/* FORMA_PAGAMENTO */}
                                            <AutoCompleteStyled
                                                disabled={pago}
                                                //defaultValue={{ value: -1, label: '<Escolha forma de pagamento>' }}
                                                options={optionFormaPagamento}
                                                getOptionLabel={(option) => option.label || ""}
                                                renderInput={(params) => <FormField {...params} label="Forma de pagamento" />}
                                                isOptionEqualToValue={(option, value) => option.value === value.value}
                                                value={forma_pagamento_id}
                                                onChange={(event, newValue) => {
                                                    setForma_Pagamento_id(newValue);
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={3}> {/* BANCO*/}
                                            <AutoCompleteStyled
                                                disabled={pago}
                                                //defaultValue={{ value: -1, label: '<Escolha banco/caixa>' }}
                                                disableClearable //Retira o X (clear)
                                                options={optionBanco}
                                                getOptionLabel={(option) => option.label || ""}
                                                renderInput={(params) => <FormField {...params} required label="Conta de Pagamento:" />}
                                                isOptionEqualToValue={(option, value) => option.value === value.value}
                                                value={banco_id}
                                                onChange={(event, newValue) => {
                                                    setBanco_id(newValue);
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={3}>{/*VALOR_PAGO*/}
                                            <MDBox width='100%' display='flex' alignItems='flext-start' flexDirection='row'>
                                                <CurrencyInput
                                                    autoFocus
                                                    disabled={pago}
                                                    required={true}
                                                    label="Valor pago"
                                                    value={valorPago}
                                                    //onChange={(e) => setValorPago(e.target.value.replace(/[^.,0-9]/g, ''))}
                                                    onChange={(e) => {
                                                        const tempValor = parseFloat(e.target.value.replace(/\./g, '').replace(',', '.'))
                                                        setValorPago(tempValor)
                                                    }}
                                                    helperText={valorPago > 0 ? false :
                                                        <>
                                                            <CancelIcon color={'error'} />
                                                            <Typography pl={1} fontWeight="small" variant="caption" color={'error'}>Valor pago deve ser maior que 0</Typography>
                                                        </>
                                                    }
                                                    InputProps={{
                                                        startAdornment: <InputAdornment sx={{ width: '5%' }} position="start">R$</InputAdornment>,
                                                        disabled: pago
                                                    }}
                                                />
                                                <Tooltip
                                                    title="Você pode fazer o recebimento total ou parcial do saldo da parcela"
                                                    placement="top"
                                                >
                                                    <HelpIcon color="info" />
                                                </Tooltip>
                                            </MDBox>
                                        </Grid>
                                        <Grid item xs={12} sm={3}> {/* JUROS */}
                                            <FormField
                                                disabled={pago}
                                                required={true}
                                                type="number"
                                                label="Juros"
                                                value={juros}
                                                onChange={(e) => setJuros(e.target.value.replace(/[^.,0-9]/g, ''))}
                                                //onChange={(e) => setJuros(e.target.value)}
                                                InputProps={{
                                                    startAdornment: <InputAdornment sx={{ width: '5%' }} position="start">R$</InputAdornment>,
                                                    disabled: pago
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={3}> {/* MULTA */}
                                            <FormField
                                                disabled={pago}
                                                required={true}
                                                type="number"
                                                label="Multa"
                                                value={multa}
                                                onChange={(e) => setMulta(e.target.value.replace(/[^.,0-9]/g, ''))}
                                                InputProps={{
                                                    startAdornment: <InputAdornment sx={{ width: '5%' }} position="start">R$</InputAdornment>,
                                                    disabled: pago
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={3}> {/* DESCONTO */}
                                            <FormField
                                                disabled={pago}
                                                required={true}
                                                type="number"
                                                label="Desconto"
                                                value={desconto}
                                                onChange={(e) => setDesconto(e.target.value.replace(/[^.,0-9]/g, ''))}
                                                InputProps={{
                                                    startAdornment: <InputAdornment sx={{ width: '5%' }} position="start">R$</InputAdornment>,
                                                    disabled: pago
                                                }}
                                            />
                                        </Grid>
                                        {tipo === 'R' &&
                                            <>
                                                <Grid item xs={12} sm={3}> {/* TARIFA */}
                                                    <FormField
                                                        disabled={pago}
                                                        required={true}
                                                        type="number"
                                                        label="Tarifa"
                                                        value={tarifa}
                                                        onChange={(e) => setTarifa(e.target.value.replace(/[^.,0-9]/g, ''))}
                                                        InputProps={{
                                                            startAdornment: <InputAdornment sx={{ width: '5%' }} position="start">R$</InputAdornment>,
                                                            disabled: pago
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={3}> {/* NSU */}
                                                    <MDBox width='100%' display='flex' alignItems='flext-start' flexDirection='row'>
                                                        <FormField
                                                            disabled={pago}
                                                            type="text"
                                                            label="nsu"
                                                            value={nsu}
                                                            onChange={(e) => setNsu(e.target.value)}
                                                            InputLabelProps={{ shrink: true }} //evita o problema da label sobrepor o valor do input
                                                            inputProps={{
                                                                disabled: pago
                                                            }}
                                                        />
                                                        <Tooltip
                                                            title="Número Sequencial Único (NSU) Esse número existe para identificar uma transação
                                             crédido/débito"
                                                            placement="top"
                                                        >
                                                            <HelpIcon color="info" />
                                                        </Tooltip>
                                                    </MDBox>
                                                </Grid>

                                            </>
                                        }
                                        <Grid item xs={12} sm={12}> {/* VALOR_PAGO */}
                                            <MDBox width='100%' display='flex' justifyContent="flex-end" flexDirection='row'>
                                                {((totalQuitado ? valorParcela - totalQuitado : valorParcela) - valorPago) >= 0 ? <MDTypography variant='caption' pr={10}>{'Valor em Aberto'}</MDTypography> : ''}
                                                <MDTypography variant='caption'>{'Total Pago'}</MDTypography>
                                            </MDBox>
                                            <MDBox width='100%' display='flex' justifyContent="flex-end" flexDirection='row'>
                                                {((totalQuitado ? valorParcela - totalQuitado : valorParcela) - valorPago) >= 0 ? <MDTypography fontWeight="bold" pr={8}>{formataValor((totalQuitado ? valorParcela - totalQuitado : valorParcela) - valorPago)}</MDTypography> : ''}
                                                <MDTypography fontWeight="bold" >{formataValor(parseFloat(valorPago) + parseFloat(juros) + parseFloat(multa) - parseFloat(desconto) - parseFloat(tarifa))}</MDTypography>
                                            </MDBox>
                                        </Grid>
                                    </>
                                }
                                {
                                    opcLancamento === 'avulso' &&
                                    <>
                                        <Grid item xs={12} sm={12}> {/* TITULO*/}
                                            <MDBox width='100%' display='flex' alignItems='flex-start' flexDirection='column'>
                                                <MDTypography variant='subtitle2' pb={1} fontWeight="bold">{'Lançamento avulso'}</MDTypography>
                                                <MDTypography variant='caption'>{'Você pode lançar Juros, Multa e desconto avulso'}</MDTypography>
                                            </MDBox>
                                            <Divider
                                                sx={{ bgcolor: (theme) => theme.palette.divider }}
                                                style={{
                                                    marginTop: 5,
                                                    marginBottom: 10,
                                                    border: "none",
                                                    height: 2,
                                                    margin: 0,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={3}> {/* JUROS */}
                                            <FormField
                                                disabled={pago}
                                                required={true}
                                                type="number"
                                                label="Juros"
                                                value={juros}
                                                onChange={(e) => setJuros(e.target.value.replace(/[^.,0-9]/g, ''))}
                                                //onChange={(e) => setJuros(e.target.value)}
                                                InputProps={{
                                                    startAdornment: <InputAdornment sx={{ width: '5%' }} position="start">R$</InputAdornment>,
                                                    disabled: pago
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={3}> {/* MULTA */}
                                            <FormField
                                                disabled={pago}
                                                required={true}
                                                type="number"
                                                label="Multa"
                                                value={multa}
                                                onChange={(e) => setMulta(e.target.value.replace(/[^.,0-9]/g, ''))}
                                                InputProps={{
                                                    startAdornment: <InputAdornment sx={{ width: '5%' }} position="start">R$</InputAdornment>,
                                                    disabled: pago
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={3}> {/* DESCONTO */}
                                            <FormField
                                                disabled={pago}
                                                required={true}
                                                type="number"
                                                label="Desconto"
                                                value={desconto}
                                                onChange={(e) => setDesconto(e.target.value.replace(/[^.,0-9]/g, ''))}
                                                InputProps={{
                                                    startAdornment: <InputAdornment sx={{ width: '5%' }} position="start">R$</InputAdornment>,
                                                    disabled: pago
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={3}> {/* DATA_PAGAMENTO */}
                                            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                                                <DesktopDatePicker
                                                    disabled={pago}
                                                    maxDate={new Date()}
                                                    label='Data do Lançamento Avulso'
                                                    inputFormat="dd/MM/yyyy"
                                                    value={dataPagamento}
                                                    onChange={(e) => setDataPagamento(e)}
                                                    renderInput={(params) => <FormField required {...params} />}
                                                />
                                            </LocalizationProvider>
                                        </Grid>
                                        <Grid item xs={12} sm={12}> {/* VALOR_PAGO */}
                                            <MDBox width='100%' display='flex' justifyContent="flex-end" flexDirection='row'>
                                                <MDTypography variant='caption'>{'Total saldo'}</MDTypography>
                                            </MDBox>
                                            <MDBox width='100%' display='flex' justifyContent="flex-end" flexDirection='row'>
                                                <MDTypography fontWeight="bold" >{formataValor(parseFloat(saldo) + parseFloat(juros) + parseFloat(multa) - parseFloat(desconto) - parseFloat(tarifa))}</MDTypography>
                                            </MDBox>
                                        </Grid>
                                    </>
                                }


                            </Grid>

                        </MDBox>

                        {
                            opcLancamento === 'quitacao' &&
                            <>
                                <MDBox ml={2} mr={2} mt={2} pb={2} bgColor={'#fff'} sx={{ borderRadius: '3px' }}> {/* OBSERVAÇÕES */}
                                    <Grid container spacing={2} mt={1} pl={2} pr={2}>

                                        <Grid item xs={12} sm={12}> {/* OBSERVAÇÃO*/}
                                            <FormField
                                                disabled={pago}
                                                type="text"
                                                label="Observação do pagamento"
                                                value={observacaoPagamento}
                                                onChange={(e) => setObservacaoPagamento(e.target.value)}
                                                rows={3}
                                                multiline={true}
                                                InputLabelProps={{ shrink: true }} //evita o problema da label sobrepor o valor do input
                                                inputProps={{
                                                    disabled: pago
                                                }}
                                            />
                                        </Grid>
                                    </Grid>

                                </MDBox>
                            </>
                        }

                    </DialogContentText>
                </DialogContent>

                <DialogActions sx={{ padding: '7px', margin: 0.5 }}> {/*footer*/}
                    <MDBox width="100%">
                        <MDBox width="100%" display="flex" justifyContent="space-between">
                            <MDButton onClick={() => {
                                handleCancel()
                                limpaImputs()
                            }} variant="gradient" type="button" color="secondary">
                                voltar
                            </MDButton>
                            <MDButton
                                disabled={pago}
                                onClick={() => {
                                    opcLancamento === 'quitacao' ? handleClickGravar() : handleClickAvulso()
                                }}
                                variant="gradient"
                                type="submit"
                                //type="button" 
                                color="info">
                                {opcLancamento === 'quitacao' ? tipo === 'D' ? 'confirmar pagamento' : 'confirmar recebimento' : 'Lançamento avulso'}
                            </MDButton>
                        </MDBox>
                    </MDBox>
                </DialogActions>
            </Dialog>
        </>
    );
}