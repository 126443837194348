import React, { useState, useEffect, useContext } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import MDBox from 'components/MDBox';
import api from "commons/api";
import { rotafechada } from "commons/urlconf";
import MessagesContext from "hooks/MessagesContext";
import { Grid, Icon, Tooltip,  Menu, MenuItem, Box } from "@mui/material";
import { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import AuthContext from 'hooks/AuthContext';
import { formatDate, formataValor } from "commons/utils";
import TabelaPadrao from "componentes/TabelaPadrao";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { endOfMonth, format } from "date-fns";
import { strToDate } from "commons/utils";
import DialogoDetalheParcela from "pages/financeiro/contas/DetalheParcela";


const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 'none',
        //backgroundColor: '#1A73E8',
        //color: 'rgb(255, 255, 255)',
        //boxShadow: theme.shadows[1],
        //fontSize: 12,
    },
}));

export default function DialogoDetalheCategoria(props) {

    const { open, handleCancel, title, data_periodo, categoria_id, centro_custo_id } = props;

    const messageContext = useContext(MessagesContext);
    const { MensagemAviso, MensagemErro, MostraTelaEspera, FechaTelaEspera, MensagemAlerta } = messageContext;

    const authContext = useContext(AuthContext);
    const { getSessao } = authContext;
    const sessaolocal = getSessao();

    /*****************Menu Item*******************/
    const [anchorEl, setAnchorEl] = useState(null);
    const [dadosMenu, setDadosMenu] = useState({open:false,conta_id:'',parcela_conta_id:'',tipo:'',openDetalheConta:false,openDetalheParcela:false});
    

    //TABELA
    const [colunasTable, setColunasTable] = React.useState([]);
    const [linhas, setLinhas] = React.useState([]);
    const [countAll, setCountAll] = React.useState(0);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('data_competencia');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(1500);

    const buscaDados = async () => {
        try {
            MostraTelaEspera()

            /*DADOS TABELA*/
            let complementoUrl = '';

            if (page > 0) {
                complementoUrl = `${complementoUrl}&offset=${page * rowsPerPage}`;
            }

            if (orderBy) {
                complementoUrl = `${complementoUrl}&order=${orderBy}`;
                if (order) {
                    complementoUrl = `${complementoUrl}&direction=${order}`;
                }
            }

            if (centro_custo_id) {
                complementoUrl = `${complementoUrl}&centro_custo_id=${centro_custo_id}`
            }

            //data_periodo vem como 01/mm/yyyy
            let data1 = strToDate(`${data_periodo}`);
            let data2 = format(endOfMonth(data1), "yyyy/MM/dd 23:59:59") //data final
            data1 = format(data1, "yyyy/MM/dd 00:00:00") //data inicial
            const retornoDetalheParcela = await api.get(`${rotafechada()}imprimir/DFC/detalharcategoria?categoria_id=${categoria_id}&data1=${data1}&data2=${data2}${complementoUrl}`);
            const { data } = retornoDetalheParcela.data;
            //console.log(data);

            const dadostemp = [];


            const colunasTabletemp = [
                {
                    id: 'descricao',
                    numeric: false,
                    disablePadding: true,
                    label: 'Descrição',
                    disableOrder: true
                },
                {
                    id: 'vencimento',
                    numeric: false,
                    disablePadding: true,
                    label: 'Vencimento',
                    disableOrder: true
                },
                {
                    id: 'dataquitacao',
                    numeric: false,
                    disablePadding: true,
                    label: 'Data Quitação',
                    disableOrder: true
                },
                {
                    id: 'percentual',
                    numeric: true,
                    disablePadding: true,
                    label: 'Rateio',
                    disableOrder: true
                },
                {
                    id: 'valor',
                    numeric: true,
                    disablePadding: true,
                    label: 'Quitação',
                    disableOrder: true
                },
                {
                    id: 'banco',
                    numeric: false,
                    disablePadding: true,
                    label: 'Banco/Caixa Quitação',
                    disableOrder: true
                },
                {
                    id: 'acoes',
                    numeric: false,
                    disablePadding: true,
                    label: '',
                    disableOrder: true
                },


            ]

            let total = 0;

            data.map((item, index) => {
                dadostemp.push({
                    descricao: <>
                        <CustomWidthTooltip
                            title={<>
                                <p align='left'>
                                    Conta: {item.conta_id}-{item.conta_nome}<br></br>
                                    Parcela: {item.parcela_conta_id}-{item.parcela_nome}<br></br>
                                    Vencimento: {formatDate(new Date(item.parcela_vencimento), 'DD/MM/YY')}<br></br>
                                    Categoria: {item.categoria_nome}- Tipo:{item.categoria_tipo} <br></br>
                                    Pessoa: {`${item.pessoa_fantasia ? item.pessoa_fantasia : item.pessoa_razao_social} `}

                                </p>
                            </>}
                            placement="top"
                            disableInteractive
                        >
                            <Box>
                                <MDBox width='100%' display='flex' justifyContent="flex-start" flexDirection='row'>
                                    <MDTypography variant="body2" >{item.parcela_nome}</MDTypography>
                                </MDBox>
                                <MDBox width='100%' display='flex' justifyContent="flex-start" flexDirection='row'>
                                    <MDTypography variant="caption" >Pessoa: {item.pessoa_fantasia ? item.pessoa_fantasia : item.pessoa_razao_social}</MDTypography>
                                </MDBox>
                            </Box>
                        </CustomWidthTooltip>
                    </>,
                    vencimento: formatDate(new Date(item.parcela_vencimento), 'DD/MM/YY'),
                    dataquitacao: formatDate(new Date(item.lancamento_data), 'DD/MM/YY'),
                    percentual: parseFloat(item.percentual_rateio) < 100 ? parseFloat(item.percentual_rateio).toFixed(2) + '%' : '', //se for 100% nao exibe
                    banco: item.banco_nome,
                    valor: formataValor(item.lancamento_valor),
                    acoes: <>
                        <MDButton
                            variant="outlined"
                            color="info"
                            size="small"
                            borderRadius="sm"
                            onClick={(e) => {
                                //setSituacaoParcela(item.situacao)
                                setDadosMenu({...dadosMenu,
                                    conta_id:item.conta_id,
                                    parcela_conta_id:item.parcela_conta_id,
                                    tipo:item.categoria_tipo,
                                    openMenu:true});
                                setAnchorEl(e.currentTarget);
                            }}
                            sx={{ borderRadius: '4px', height: '25px', minHeight: '12px' }}
                            disableElevation
                            endIcon={<KeyboardArrowDownIcon />}
                        >
                            ações
                        </MDButton>
                    </>,
                })
                //somando as quitacoes para totalizar na ultima linha
                total += parseFloat(item.lancamento_valor);
            })

            //ultima linha
            dadostemp.push({
                descricao: <MDTypography variant="body2" fontWeight='bold'>TOTAL</MDTypography>,
                vencimento: '',
                dataquitacao: '',
                valor: <MDTypography variant="body2" fontWeight='bold'>{formataValor(total)}</MDTypography>,
                banco: ''
            })


            setColunasTable([...colunasTabletemp]);
            setLinhas(dadostemp);
            setCountAll(0);

            FechaTelaEspera();
        }
        catch (error) {
            FechaTelaEspera();
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    }

    const limpaImputs = () => {

    }

    useEffect(async () => {
        if (open == true) {
            await buscaDados();
        }

    }, [open])

    
    const handleCloseMenu = () => {
        setAnchorEl(null);
        setDadosMenu({...dadosMenu,openMenu:false})
    };


    return (
        <>

            <DialogoDetalheParcela
                open={dadosMenu.openDetalheParcela||false}
                title={'Detalhes da Parcela'}
                message={'Mensagem'}
                tipo={dadosMenu.tipo}
                setRecarregaTabela={()=>{}}
                recarregaTabela={()=>{}}
                setCodigoAbrir={()=>{}}
                codigoAbrir={dadosMenu.parcela_conta_id}
                handleCancel={() => {
                    setDadosMenu({...dadosMenu,openDetalheParcela:false})
                }}
            />

            <Menu

                anchorEl={anchorEl}
                open={dadosMenu.openMenu||false}
                onClose={handleCloseMenu}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >

                <MenuItem
                    dense={true}
                    onClick={() => {
                        setAnchorEl(null);
                        setDadosMenu({...dadosMenu,openMenu:false,openDetalheConta:false,openDetalheParcela:true})
                    }}>
                    Ver detalhes da parcela
                </MenuItem>

            </Menu>

            <Dialog
                open={open}
                onClose={() => {
                    handleCancel()
                    limpaImputs()
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullScreen
            >
                <DialogTitle id="alert-dialog-title" sx={{ padding: '5px' }}>{/*HEADER*/}
                    <MDBox width="100%">
                        <MDBox display="flex" justifyContent="space-between">
                            <MDBox display="flex" justifyContent="space-start" flexDirection='column'>
                                <MDTypography fontWeight='bold' ml={5} mt={1} variant='h4'>{`${title} - ${formatDate(strToDate(data_periodo), 'MM/YY')}`}</MDTypography>
                            </MDBox>

                            <Tooltip title="Fechar (Esc)">
                                <MDButton size="large" sx={{ padding: 0 }} onClick={() => {
                                    handleCancel()
                                    limpaImputs()
                                }}>
                                    <Icon>close</Icon>
                                </MDButton>

                            </Tooltip>
                        </MDBox>
                    </MDBox>
                </DialogTitle>

                <DialogContent dividers sx={{ backgroundColor: "#f1f4f9", borderColor: '#7bb5e4' }}>
                    <DialogContentText id="alert-dialog-description">

                        <MDBox ml={1} mr={1} mt={1} pb={1} bgColor={'#fff'} sx={{ borderRadius: '3px' }}>
                            <Grid container spacing={1} mt={1} pl={2} pr={2}>
                                <Grid item xs={12} sm={12}>
                                    <TabelaPadrao
                                        colunas={colunasTable}
                                        linhas={linhas}
                                        countAll={countAll}
                                        page={page}
                                        setPage={setPage}
                                        rowsPerPage={rowsPerPage}
                                        setRowsPerPage={setRowsPerPage}
                                        order={order}
                                        setOrder={setOrder}
                                        orderBy={orderBy}
                                        setOrderBy={setOrderBy}
                                        disablePagination={true}
                                    />
                                </Grid>

                            </Grid>
                        </MDBox>

                    </DialogContentText>
                </DialogContent>

                <DialogActions sx={{ padding: '7px', margin: 0.5 }}> {/*FOOTER*/}
                    {/* <MDBox width="100%"> */}
                    <MDBox width="100%" display="flex" justifyContent="flex-end">
                        <MDBox width="100%" display="flex" justifyContent="flex">
                            <MDButton onClick={() => {
                                handleCancel()
                                limpaImputs()
                            }} variant="gradient" type="button" color="secondary">
                                fechar
                            </MDButton>
                        </MDBox>
                    </MDBox>
                </DialogActions>
            </Dialog >
        </>
    );
}