import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import Grid from "@mui/material/Grid";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import MDTypography from "components/MDTypography";
import MDBox from 'components/MDBox';
import { Autocomplete, Icon, Tooltip } from '@mui/material';
import MDInput from 'components/MDInput';
import FormField from "componentes/FormField";
import MDButton from 'components/MDButton';
import TabelaPesquisaDinamica from './tabela';
import api from 'commons/api';
import MessagesContext from 'hooks/MessagesContext';
import { rotafechada } from 'commons/urlconf';
import { useState } from 'react';
import { useEffect } from 'react';
import { AutoCompleteStyled } from 'componentes/AutoCompleteStyled';
import DialogoPessoa from 'pages/pessoas/pessoas/Cadastro/dialogopessoa';



const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


//exemplos:
// const dadosColunas = [
//   {
//     id: 'nome',
//     numeric: false,
//     disablePadding: true,
//     label: 'Nome',
//   }...
// ];
// const dadosLinhas=[
//   {nome:'assss',valor:10,data:'01/01/2000'},
//   {nome:'bsss',valor:20,data:'01/02/2002'},
// ];

export default function PesquisaPessoas(props) {

  const { open, opcoesTiposPessoas, campoTipoPessoaIdFiltroPesquisa, setCampoTipoPessoaIdFiltroPesquisa } = props;

  const messageContext = React.useContext(MessagesContext);
  const { MensagemErro } = messageContext;



  const camposFiltro = [
    { id: 'razao_social', label: 'Razão Social', buscaParcial: true },
    { id: 'fantasia', label: 'Fantasia', buscaParcial: true },
    { id: 'id', label: 'id' }];
  const campoInicial = camposFiltro ? camposFiltro[0] : '';
  const [campoPesquisa, setCampoPesquisa] = React.useState(campoInicial);
  const [valorPesquisa, setValorPesquisa] = React.useState('');
  const [countAll, setCountAll] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState(camposFiltro ? camposFiltro[0].id : 'id');

  const [colunasTable, setColunasTable] = React.useState([]);
  const [linhas, setLinhas] = React.useState([]);
  const [recarregaDados, setRecarregaDados] = React.useState(false);

  const [openPessoa, setOpenPessoa] = useState({
    open: false
  })

  const handleClose = () => {
    if (props.handleClose) {
      props.handleClose();
    }
  };

  const handleClickPesquisa = () => {
    if (!campoPesquisa.id) {
      MensagemErro('Selecione o campo que deseja pesquisar');
      return;
    }

    setRecarregaDados(!recarregaDados);
  }

  const buscaParametros = async () => {


    try {
      let url = `${rotafechada()}vendas/parametros`;
      const retorno = await api.get(encodeURI(url));
      const data = retorno.data;

      return data
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const buscaDados = async () => {

    try {

      const urlBase = `${rotafechada()}pessoas?ativo=S`;
      const operadorUrl = urlBase.indexOf('?') >= 0 ? '&' : '?';
      let complementoUrl = '';

      if ((campoPesquisa.id) && (valorPesquisa)) {
        complementoUrl = `&${campoPesquisa.id}=${valorPesquisa}`;

        if ((valorPesquisa) && (valorPesquisa.length > 0) && (valorPesquisa[0] !== '%') && (valorPesquisa[valorPesquisa.length - 1] !== '%')
          && (camposFiltro) && (camposFiltro.length > 0)) {

          //se o texto a ser buscado nao terminar ou começar com %
          //e tiver uma configuracao no camposFiltro
          //entao localizo que o campo usado nesse momento é um campo que esteja configurado
          //o buscaParcial, se sim acrescento automaticamente o % no inicio e fim
          let encontrouCampo = false;
          for (let i = 0; i < camposFiltro.length; i++) {

            if ((camposFiltro[i].buscaParcial === true) && (camposFiltro[i].id === campoPesquisa.id)) {
              encontrouCampo = true;
            }
          }

          if (encontrouCampo === true) {
            complementoUrl = `&${campoPesquisa.id}=%${valorPesquisa}%`;
          }
        }
      }

      if (page > 0) {
        complementoUrl = `${complementoUrl}&offset=${page * rowsPerPage}`;
      }

      if (orderBy) {
        complementoUrl = `${complementoUrl}&order=${orderBy}`;
        if (order) {
          complementoUrl = `${complementoUrl}&direction=${order}`;
        }
      }

      if (parseInt(campoTipoPessoaIdFiltroPesquisa?.id, 10) > 0) {
        complementoUrl = `${complementoUrl}&tipo_pessoa_id=${campoTipoPessoaIdFiltroPesquisa.id}`;
      }

      const url = `${urlBase}${operadorUrl}limit=${rowsPerPage}${complementoUrl}`;
      const retorno = await api.get(encodeURI(url));
      const { data, count } = retorno.data;
      // console.log('url:', url);
      // console.log('data:');
      // console.log(data);

      if (!count > 0) {
        MensagemErro('Nenhuma informação foi selecionada');
        setLinhas([]);
        setCountAll(0)
        return;
      }
      const colunasadd = [
        {
          id: 'id',
          numeric: false,
          disablePadding: true,
          label: 'Id',
        },
        {
          id: 'razao_social',
          numeric: false,
          disablePadding: true,
          label: 'Razão Social',
        },
        {
          id: 'fantasia',
          numeric: false,
          disablePadding: true,
          label: 'Fantasia',
        },
      ];
      setColunasTable(colunasadd);

      const linhasadd = [];
      data.forEach((item) => {
        linhasadd.push({
          id: item.id,
          razao_social: item.razao_social,
          fantasia: item.fantasia,
        })
      });

      setLinhas(linhasadd);
      setCountAll(retorno.data.countAll || 0)
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }

  }

  const handleClickSelecionado = (...ref) => {
    if (props.handleClick) {
      props.handleClick(...ref);
      handleClose();
    }
  }

  React.useEffect(() => {

    if (open) {
      //só busca no Back se a tela estiver visivel
      buscaDados();
    }

  }, [recarregaDados, rowsPerPage, page, order, orderBy, open])

  const desenhaFiltro = () => {
    //só desenha a linha do filtro de veio campos de filtro na props
    if ((camposFiltro) && (camposFiltro.length > 0)) {
      return (
        //Grid do Filtro
        < Grid container spacing={2} justifyContent="flex-start" mb={2} >
          <Grid item xs={12} sm={0.5}>{/* recuo */}

          </Grid>
          <Grid item xs={12} sm={1.5}  >{/* Tipo */}
            <AutoCompleteStyled
              label='Tipo'
              options={opcoesTiposPessoas}
              renderInput={(params) => <FormField {...params} label='Tipo' />}
              value={campoTipoPessoaIdFiltroPesquisa}
              onChange={(event, newValue) => {
                setCampoTipoPessoaIdFiltroPesquisa(newValue);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={1.5}  > {/* Combo com os campos que podem ser pesquisados */}

            <AutoCompleteStyled

              options={camposFiltro}
              //getOptionLabel={(option) => option.label}
              renderInput={(params) => <FormField {...params} label="Coluna" />}
              value={campoPesquisa}
              onChange={(event, newValue) => {
                setCampoPesquisa(newValue);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={5}  > {/* Valor a ser pesquisado */}
            <FormField type="text" label="Valor a ser Pesquisado" value={valorPesquisa} onChange={(e) => setValorPesquisa(e.target.value)}

              inputProps={
                {
                  onKeyPress: (e) => {

                    if (e.key === 'Enter') {
                      handleClickPesquisa();
                    }
                  },
                }
              }
            />
          </Grid>
          <Grid item xs={12} sm={2} > {/*Botão do filtro */}
            <MDButton onClick={handleClickPesquisa} color='info'> Pesquisar</MDButton>
          </Grid>
          <Grid item xs={12} sm={1} container justifyContent="flex-end"> {/*Botão de Cadastrar nova pessoa */}
            <Tooltip title="Cadastrar uma nova pessoa" placement="top">
              <MDButton
                variant="gradient"
                circular
                color="info"
                type="button"
                onClick={() => {
                  setOpenPessoa({
                    ...openPessoa,
                    pessoa_id: ``,
                    open: true
                  })
                }} > <Icon>add</Icon> </MDButton>
            </Tooltip>
          </Grid>
        </Grid >
      )
    }
    else return false;
  }

  const handleVoltarCadastroPessoa = async () => {

    //quando volta da tela de cadastro 
    //recarrega a lista de pessoas e procura a pessoa que foi cadastrada
    //caso nenhuma tenha sido cadastrada ele vai procurar -1 que é o "Escolha uma Pessoa"
    console.log('retorno do cadastro de pessoa:', openPessoa);
    setOpenPessoa({
      ...openPessoa,
      open: false
    })
    setCampoTipoPessoaIdFiltroPesquisa({ id: -1, label: 'Todos' })
    setCampoPesquisa({ id: 'id', label: 'id' }); //escolhendo tipo id
    setValorPesquisa(openPessoa.pessoa_id || ''); // setando id da pessoa

    handleClickPesquisa(); // pesquiso a pessoaque acabamos de cadastrar

    //let parametrosVenda = await buscaParametros();
    //await buscaPessoa(parametrosVenda, openPessoa.pessoa_id || -1);


  }

  const setPessoaCadastrada = (value) => {
    setOpenPessoa({
      ...openPessoa,
      pessoa_id: value,
    })
  }

  return (
    <>

      <DialogoPessoa
        open={openPessoa.open || false}
        handleVoltar={handleVoltarCadastroPessoa}

        setPessoaAtual={setPessoaCadastrada}
        pessoaAtual={openPessoa.pessoa_id}
      />

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <MDTypography variant="h3" fontWeight="bold">
              {'Pessoas'}
            </MDTypography>

          </Toolbar>
        </AppBar>

        <MDBox>

          {
            desenhaFiltro()
          }

          {/* Grid da Tabela */}
          <Grid container>
            <Grid item xs={12}>
              <TabelaPesquisaDinamica
                colunas={colunasTable}
                linhas={linhas}
                campoRetorno={'id'}
                handleClick={handleClickSelecionado}
                countAll={countAll}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                order={order}
                setOrder={setOrder}
                orderBy={orderBy}
                setOrderBy={setOrderBy}
              //campoAuxiliar={}
              />
            </Grid>
          </Grid>
        </MDBox>

      </Dialog>

    </>
  );
}
