import { Box, Button, Dialog, Checkbox, DialogTitle, Tooltip, Typography } from '@mui/material';
import React, { Component, useState } from 'react';
import { Stage, Layer, Rect, Text, Shape, Circle, Line, Image, Transformer, Arrow } from 'react-konva';
import { CompactPicker } from 'react-color';
import MDButton from 'components/MDButton';
import MDInput from 'components/MDInput';


export const PropriedadesBandaCabecalhoRodape = (props) => {

    const { itensTela, setItensTela, selectedId, open, setOpen } = props;

    // const handleChangeDynamicHeight = (e) => {
    //     const temp = itensTela.slice();
    //     temp[selectedId].dynamicHeight = e.currentTarget.checked;
    //     setItensTela(temp);
    // }

    const handleChangeHideTitle = (e) => {
        const temp = itensTela.slice();
        temp[selectedId].hideTitle = e.currentTarget.checked;
        setItensTela(temp);
    }


    return (
        <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
            <DialogTitle>{itensTela[selectedId].tipo}</DialogTitle>
            <Box m={2}>
                {/* <Box>
                    <Typography variant='caption'>Altura Dinâmica</Typography>
                    <Checkbox
                        checked={itensTela[selectedId].dynamicHeight}
                        onChange={handleChangeDynamicHeight}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                </Box> */}
                <Box>
                    <Typography variant='caption'>Esconde o Título</Typography>
                    <Checkbox
                        checked={itensTela[selectedId].hideTitle}
                        onChange={handleChangeHideTitle}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                </Box>
            </Box>

        </Dialog>
    )

}


export const BandaCabecalhoRodape = ({ shapeProps, isSelected, onSelect, onChange }) => {
    const shapeRef = React.useRef();
    const trRef = React.useRef();

    React.useEffect(() => {
        if (isSelected) {
            trRef.current.nodes([shapeRef.current]);
            trRef.current.getLayer().batchDraw();
        }
    }, [isSelected]);

    return (
        <React.Fragment>
            {
                shapeProps.hideTitle ? null : (

                    <Text
                        {...shapeProps}
                        strokeWidth={1}

                        text={shapeProps.tipo}
                        y={shapeProps.y + shapeProps.height - 15}
                        x={shapeProps.width - 100}
                        rotation={0}

                    />
                )
            }
            <Rect
                onClick={onSelect}
                onTap={onSelect}
                ref={shapeRef}
                {...shapeProps}
                //draggable
                // onDragEnd={(e) => {
                //     onChange({
                //         ...shapeProps,
                //         x: parseInt(e.target.x(), 10),
                //         y: parseInt(e.target.y(), 10),
                //     });
                // }}
                onTransformEnd={(e) => {
                    // transformer is changing scale of the node
                    // and NOT its width or height
                    // but in the store we have only width and height
                    // to match the data better we will reset scale on transform end

                    const node = shapeRef.current;
                    const scaleX = node.scaleX();
                    const scaleY = node.scaleY();

                    // we will reset it back
                    node.scaleX(1);
                    node.scaleY(1);

                    onChange({
                        ...shapeProps,
                        x: parseInt(node.x(), 10),
                        y: parseInt(node.y(), 10),
                        //travando para nao mexer na largura da banda somente na altura
                        //width: parseInt(Math.max(5, node.width() * scaleX),10),
                        height: parseInt(Math.max(node.height() * scaleY), 10),
                        //rotation: node.rotation()
                    });
                }}
            />

            {isSelected && (
                <Transformer
                    ref={trRef}
                    boundBoxFunc={(oldBox, newBox) => {
                        // limit resize

                        if (newBox.width < 5 || newBox.height < 5) {
                            return oldBox;
                        }

                        return newBox;
                    }}
                    rotateEnabled={false} // Desativar a rotação
                    keepRatio={false} // Defina como true para manter a proporção nas dimensões inferiores
                    enabledAnchors={['bottom-center']}//só mostra opção de altura no centro
                />
            )}
        </React.Fragment>
    );
};

